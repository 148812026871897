import React from "react";
import { Button, Typography } from "@material-ui/core";
import { TextField } from "../../../../components/formikFormInputs";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useActions } from "../../../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import * as AgentActions from "../../../../actions/approvedAgents";

export default (props: {
    agentId: number,
    description: string,
    onClose: Function,
    removeCaseAlert: boolean,
    handleUpdateAlert: Function
}) => {
    const { agentId, description, removeCaseAlert } = props;
    const agentsActions = useActions(AgentActions);


    const detail: any = useSelector((state: RootState) => state.account);
    const agentCaseAlert: any = detail && detail.agent && detail.agent.agentCaseAlerts && detail.agent.agentCaseAlerts.length > 0 ? detail.agent.agentCaseAlerts[0].agentId : null;
    const onSuccess = (result: any) => {
        if (result && result.payload && result.payload.statusCode === 200) {
            props.onClose();
            props.handleUpdateAlert();
        }
    };
    const createCaseAlert = async (values) => {
        if (agentId) {
            let result = await agentsActions.createCaseAlert({
                description: values && values.description,
                agentId: agentId,
            });
            onSuccess(result);
        }
    };
    const updateCaseAlert = async (values) => {
        if (agentId) {
            let result = await agentsActions.updateCaseAlert({
                description: values && values.description,
                agentId: agentId,
            });
            onSuccess(result);
        }
    };
    const deleteCaseAlert = async () => {
        if (agentId) {
            let result = await agentsActions.deleteCaseAlert({
                agentId: agentId,
            });
            onSuccess(result);
        }
    };
    return (<>
                {agentId &&
                        <Formik
                                initialValues={{ description: description }}
                                onSubmit={removeCaseAlert ? deleteCaseAlert : agentCaseAlert ? updateCaseAlert : createCaseAlert}
                                validationSchema={Yup.object({
                                    description: Yup.string().required(),
                                })}
                                validateOnMount
                        >
                            {({
                                  values,
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  isSubmitting,
                                  isValid,
                              }) => (
                                    <form onSubmit={handleSubmit}>
                                        {removeCaseAlert ?
                                                <Typography variant="body1"> Are your sure want to delete it
                                                    ? </Typography> :
                                                <Field
                                                        name="description"
                                                        component={TextField}
                                                        placeholder="Write Case..."
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        multiline={true}
                                                        rows={3}
                                                />
                                        }
                                        <Button variant="contained" color="secondary"
                                                className="floatRight mt20 mb10 ml20"
                                                onClick={() => props.onClose()}>Cancel</Button>
                                        <Button variant="contained" color={removeCaseAlert ? "inherit" : "primary"}
                                                className={removeCaseAlert ? "bgRed floatRight mt20 mb10" : "floatRight mt20 mb10"}
                                                type="submit"> {removeCaseAlert ? "Delete Case Alert" : agentCaseAlert ? "Update Case Alert" : "Create Case Alert"} </Button>

                                    </form>
                            )}
                        </Formik>
                }
            </>
    );
}
