import React, {useEffect, useState} from "react";
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import {ClientServicingCss as Styles} from "../css/ClientServicingCss";
import ContributionHistory from "./ContributionHistory";
import PaymentsHistory from "./PaymentsHistory";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Dialog as GenericDialog, GenericDialogProps} from "../../dialog";
import ManualPaymentForm from "./ManualPaymentForm";
import {usePolicyContext} from "./PolicyContext";
import RequestDocuments from "./RequestDocuments";
import { useActions } from "../../../actions";
import * as ClientActions from "../../../actions/client";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface CAPH {
    onCaseDetails?: boolean,
    supportingDocuments?: boolean,
    clientId?: number
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel"
             hidden={value !== index}
             id={`scrollable-auto-tabpanel-${index}`}
             aria-labelledby={`scrollable-auto-tab-${index}`}
             {...other}
        >
            {value === index && (
                <div>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ContributionsAndPaymentHistory(props: CAPH) {
    const classes = Styles();
    const {policy} = usePolicyContext();
    const [showAddPayment, setShowAddPayment] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const { onCaseDetails = false, supportingDocuments, clientId } = props;
    const clientActions = useActions(ClientActions);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleHide = async () => {
        setShow(false);
        await clientActions.getClient(clientId);
    };

    function SupportingDocumentsContent(props: any) {
        if (supportingDocuments) {
            return (
                    <>
                        <Typography>The document request is already sent to client.</Typography>
                    </>
            );
        } else {
            return (
                    <>
                        <Typography>What would you like to tell the client? If there is a specific document needed,
                            state that here.</Typography>
                        <RequestDocuments onSubmit={handleHide} />
                        <Button color="secondary" className="floatRight textBlack" onClick={handleHide}>
                            Cancel
                        </Button>
                    </>
            );
        }
    }


    useEffect(() => {
        if (policy && policy.paymentSchedule) {
            setShowAddPayment(false);
        }
    }, [policy]);

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "Edit-Calculation-Inputs-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (<>
            <Typography>Add a payment for this year. select the option below and save when completed. The amount entered
                will affect the balance owed.</Typography>
            <ManualPaymentForm handleClose={handleClose}/>
            <Button color="secondary" className="floatRight textBlack" onClick={handleClose}>
                Cancel
            </Button>
        </>),
        title: "Add Payment",
        dividers: true,
        onClose: handleClose,
        open: open,
    };
    const documentsDialogProps: GenericDialogProps = {
        "aria-labelledby": "Edit-Calculation-Inputs-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (<SupportingDocumentsContent />),
        title: "Request Supporting Documents",
        dividers: true,
        onClose: handleHide,
        open: show,
    };

    const [tab, setTab] = React.useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    return (<>
            <Card className={classes.cardShadow + " " + classes.radius8 + " " + classes.mt40}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="subtitle1" className="mb0">Contributions Payment History </Typography>
                            <Typography variant="caption">Below is the history of payments made towards your policy with
                                calculated totals.</Typography>
                        </Grid>
                        {onCaseDetails && showAddPayment && <Grid item xs={12} md={5} className="textCenter">
                            <Button color="primary" className="mt10" onClick={handleClickOpen}>
                                <AddCircleIcon className="floatLeft mr15 " /> Add Payment
                            </Button>
                        </Grid>}

                        {onCaseDetails && <Grid item xs={12} md={5} className="textCenter">
                            <Button color="primary" className="mt10" onClick={handleClickShow}>
                                <AddCircleIcon className="floatLeft mr15 " /> Request Supporting Documents
                            </Button>
                        </Grid>}

                    </Grid>

                    <AppBar position="static" color="default" className="boxShadowNone borderTop1px mt15">
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Total Premium Payment History" {...a11yProps(0)} />
                            <Tab label="Payment History" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tab} index={0}>
                        <ContributionHistory/>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {policy && policy.clientPayments && <PaymentsHistory onCaseDetails={onCaseDetails}/> }
                    </TabPanel>
                </CardContent>
            </Card>
            {
                open &&
                <GenericDialog {...dialogProps} />
            }
            {
                show &&
                <GenericDialog {...documentsDialogProps} />
            }
        </>
    );
};
