import React from "react";
import { Button, Typography } from "@material-ui/core";
import configureStore from "../configureStore";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";

const { persistor } = configureStore();

export default function ClientDemoBar(props: any) {
    const {demoBarText, demoBarLink, fallbackLink} = props;
	const authActions = useActions(Actions);
	const exitDemoClient = async () => {
		await authActions.exitDemoClient();
        persistor.flush()
            .then(response => {
                authActions.toggleParentPortal(false);
                window.location.replace("/accounts");
            })
            .catch(err => {
                window.location.replace(fallbackLink);
            });
	};

	return (
		<div className="exitDemoClient">
            <Typography className="floatRight mb0">{demoBarText}</Typography>
            <Button color="secondary" className="floatRight" onClick={() => {
                exitDemoClient();
            }}>
                {demoBarLink}
            </Button>

		</div>);
}