import React from "react";
import { Button, CircularProgress, Dialog, DialogContent, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogTitleProps } from "./ViewDocument";
import { blue } from "@material-ui/core/colors";

const defaultContent = "";
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                position: 'relative',
            },
            buttonProgress: {
                color: blue[500],
                position: 'absolute',
                top: '10px',
                left: '50%',
                marginTop:'0px',
                width:'100%'
            },
        })
);
const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: '18px',
                color: theme.palette.grey[500],
            },
        });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon className="f20"/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});
export default function ResetProposalModal({
                                               open,
                                               handleClose,
                                               content = defaultContent,
                                               disableDelete = false,
                                               handleYes,
                                               buttonLoader = false,
                                               paymentStatus,
                                           }: any) {
    const classes = useStyles();
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {content}
                </DialogTitle>
                <DialogContent dividers>
                    <div id="alert-dialog-description">
                        <Typography variant="body1" className="mb0">
                            {
                                paymentStatus ?
                                        "Client has already submitted payment at this time. Do you want to reset payment?" :
                                        "Are you sure you want to reset the proposal? This will clear all existing illustration data. The enrollment process will revert to Step 3, and Admin will receive an Action Item to upload and send a new proposal to the Agent."
                            }
                        </Typography>
                    </div>

                    <Button  size="small"
                             className="floatLeft mt20 floatRightSM"
                             type="submit"
                             variant="contained"
                             color="secondary" onClick={handleClose}>
                        {
                            paymentStatus ?
                                    "No" :
                                    "Cancel"
                        }
                    </Button>
                    <span className="clearFix"/>
                    {!disableDelete && <Button  size="small"
                                                className="bgRed floatLeft  floatRightSM mt20 ml15 mlSm0 mr5"
                                                type="submit"
                                                variant="contained"
                                                disabled={buttonLoader}
                                                color="primary" onClick={handleYes} autoFocus>
                        {
                            paymentStatus ?
                                    "Yes, void/refund existing payment" :
                                    "Reset Proposal"
                        }
                        {buttonLoader &&
                                <CircularProgress className={classes.buttonProgress}
                                                  size={24}/> }
                    </Button>}
                    <span className="clearFix"/>
                </DialogContent>
            </Dialog>
    );
}