import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import LoaderGif from "../../styles/images/mainloader_ilia_new.gif";
import GroupRegister from "../../components/GroupRegister";
interface Props extends RouteComponentProps<void> {}


function GroupRegisterPage(props: any) {
    const [progress, setProgress] = React.useState<boolean>(false);

    const myStyle = {
        position: "absolute" as "absolute",
        "z-index": "999999",
        width: "100%",
        height: "100%",
        background: "#ffffff",
    };
    const imgStyle = {
        position: "absolute" as "absolute",
        left: "0",
        right: "0",
        margin:'0 auto',
        top:'30%'
    };
    return (
            <>
                {progress &&
                        <div style={myStyle}>
                            <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
                        </div>}
                <GroupRegister {...props} />
            </>
    );
}

export default GroupRegisterPage;
