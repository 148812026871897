import React from "react";
import { useActions } from "../../actions";
import * as clientActions from "../../actions/client";
import { RouteComponentProps } from "react-router-dom";
import { history } from "../../configureStore";
import LoaderGif from "../../styles/images/loader_nw.gif";

interface ParamsProps {
    match: {
        params: {
            token: string
        }
    };
}

interface Props extends RouteComponentProps<void> {
}

const imgStyle = {
    position: "absolute" as "absolute",
    left: "0",
    right: "0",
    margin: "0 auto",
    top: "30%",
};
const loadingProgressStyle: any = {
    position: "fixed" as "fixed",
    zIndex: "99",
    width: "100%",
    height: "100%",
    background: "#ffffff",
    top: 0,
};
export default function EmailVerification(props: Props & ParamsProps) {
    const { verificationEmailChange } = useActions(clientActions);

    const { token } = props.match.params;
    React.useEffect(() => {
        (async () => {
            await verificationEmailChange({ token: token });
            history.push("/accounts");
        })();
    }, []);
    return (

            <div style={loadingProgressStyle}>
                <img style={imgStyle} src={LoaderGif} alt="Loading..." />
            </div>
    );
}