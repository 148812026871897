import {isFunc} from "./commonUtils";
import numeral from "numeral";
import {NF_IUL_STRATEGY, StrategySlugEnum} from "../constants";
import {getEmployerAndParticipantCont} from "../pages/client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";
import {useSelector} from "react-redux";
import {RootState} from "../reducers";


export const Global = {
    data:{},
    set:function (key, val :any){
        this.data[key] = val
    },
    get:function (key){
        return this.data[key]
    },
    clear:function (){
        this.data= {}
    }
}
/**
 * Get strategy details for user
 * @param user
 */
export const getStrategyDetails = (user) => {
    const roleArr = [1, 2, 3, 4, 15];
    let registrationMark: boolean | undefined = true;
    let strategyName: any = "Kai-Zen";
    let strategySlug: string = "kaizen";
    const { client, accessType, role } = user;
    if (client) {
        strategyName = (client.strategy && client.strategy.name) || strategyName;
        strategySlug = (client.strategy && client.strategy.slug) || strategySlug;
        registrationMark = client.strategy && client.strategy.registrationMark;
    } else if (accessType === "client" && roleArr.indexOf(role) !== -1) {
        strategyName = (user.demoStrategy && user.demoStrategy.name) || strategyName;
        strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
        registrationMark = user.demoStrategy && user.demoStrategy.registrationMark;
    }
    return {strategyName, strategySlug, registrationMark};
}

export const getContributionFrequencyText = (frequency: string, capitalize: boolean = false) => {
    let frequencyText = (frequency === "Monthly" ? "monthly": "annual");
    return capitalize ? frequencyText[0].toUpperCase() + frequencyText.substring(1) : frequencyText;
}

/**
 * Repeated calls a function after given time for given number of times
 * @param func
 * @param time
 * @param repetitions
 * @param afterLastCall
 */
export function repeatCaller(func: Function, time: number, repetitions: number = 2, afterLastCall?: Function) {
    const callBack = () => {
        afterLastCall && isFunc(afterLastCall) && afterLastCall();
    };
    return () => {
        let called = 1;
        // first call
        func();
        // check min repetitions
        if (repetitions < 2) {
            callBack();
        }

        // setting interval
        let interval = setInterval(() => {
            // making repeat call
            func();
            // incrementing how many times we called
            called++;
            // check exit condition
            if (called === repetitions) {
                // clear
                clearInterval(interval);
                // callback
                callBack();
            }
        }, time);
    };
}


export function imoAgentClient(account:any)  {
    const user: any = account.user;
    let deathBenefit: string = user ? numeral(user && user.client && user.client.deathBenefit).format("$0,0") : numeral(account.deathBenefit).format("$0,0");
    let annualPremium: string = user ? numeral(user && user.client && user.client.annualPremium).format("$0,0") : "N/A";
    let contributionValue = user && user.client && user.client.contributionAmount;
    let contributionAmount: string = user ? numeral(contributionValue).format("$0,0") : "N/A";
    let contributionBreakUp: any = getEmployerAndParticipantCont(contributionValue, 50 , 15000);
    let employerContribution: string = user ? numeral(contributionBreakUp && contributionBreakUp.employer).format("$0,0") : "N/A";
    let participantContribution: string = user ? numeral(contributionBreakUp && contributionBreakUp.participant).format("$0,0") : "N/A";
    let trancheNumber: string = (user && user.client && user.client.tranche) ? user.client.tranche.trancheNumber : "N/A";
}


/**
 * Returns true for non-financed IUL
 * @param strategyId
 * @param strategySlug
 */
export const isNfIulCase = (strategyId: number, strategySlug: string) => {
    return strategyId === NF_IUL_STRATEGY && strategySlug === StrategySlugEnum.NfIUL;
}