import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography/Typography";
import { Link } from "@material-ui/core";
import { dateUtils } from "../../../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import { Pagination } from "../../../../../components/table";
import TableWrap from "../../../../../components/TableWrap/TableWrap";


type GNProps = {
    role: number | undefined,
    clientDetail: any,
    enrollmentClientNotes: any,
    page: any,
    rowsPerPage: number,
    setPage: Function,
    setRowsPerPage: Function
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
}));


const GeneralNotes = (props: GNProps) => {
    const classes = useStyles();
    let { enrollmentClientNotes, page, rowsPerPage, setPage, setRowsPerPage } = props;

    const getSubject = (message: string) => {
        let msg: string = "";
        for (let index = 0; index < 30; index++) {
            msg += message[index];
        }
        return msg + "...";
    };

    const getRole = (role: number) => {
        if (role == 1 || role === 2) {
            return "Admin";
        }
        if (role == 3) {
            return "Imo";
        }
        if (role == 4) {
            return "Agent";
        }
        if (role == 12) {
            return "Trustee";
        }
        return "Client";
    };

    return (
            <React.Fragment>
                <TableWrap>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell align="left">Message Subject</TableCell>
                            <TableCell align="left">Message Author</TableCell>
                            <TableCell align="left">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!enrollmentClientNotes.rows || enrollmentClientNotes.rows.length === 0 ?
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">
                                        No notes yet.
                                    </TableCell>
                                </TableRow>
                                :
                                (enrollmentClientNotes.rows && enrollmentClientNotes.rows.map((note: any, index: number) => {
                                            return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left" component="th" scope="row">
                                                            {note.message != "" ?
                                                                    <Tooltip
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <Typography variant="caption"
                                                                                                color="inherit"
                                                                                                className="mb0">{note.message}</Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                    >
                                                                        <Link
                                                                                style={{ "cursor": "pointer" }}
                                                                        >
                                                                            {note && note.message && note.message.length > 30 ?
                                                                                    getSubject(note.message)
                                                                                    : note.message
                                                                            }
                                                                        </Link>
                                                                    </Tooltip>
                                                                    :
                                                                    ""
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left" component="th" scope="row">
                                                            {note.author &&
                                                                    <span>
                                                {note.author.firstName + " " + note.author.lastName + " (" + getRole(note.author.role) + ")"}
                                                </span>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left" component="th" scope="row">
                                                            {dateUtils.asDbDateFormat(note.createdAt)}
                                                        </TableCell>
                                                    </TableRow>
                                            );
                                        },
                                ))
                        }
                    </TableBody>
                </TableWrap>
                <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            accountList={enrollmentClientNotes}/>
            </React.Fragment>
    );
};

export default GeneralNotes;