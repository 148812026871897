import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import BirthDate from "./BirthDate";
import LicenceExpiry from "./LicenceExpiry";

const Citizenship = (props: any) => {
    const {
        fieldObj,
        fieldObj4,
        fieldObj7,
        minAge,
        proofExpireRef,
        proofExpired,
        clientSigned,
        conditionalRequired,
        setProofExpired,
        role,
        client,
        CommonField,
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        isFromClientDetail,
        setAnticipatedAge
    } = props;
    return (
            <>
                <Grid item xs={12}>
                    <span className="hLine"/>
                    <Typography variant="h6" className="fontBold w100 mb20">Citizenship Details</Typography>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Typography variant="caption" className="floatLeft">
                        <Label label={"Are you a U.S. citizen?"}
                               required={conditionalRequired}/>
                    </Typography>
                    <ToggleButtonGroup id={"usCitizen"} className="toggleButtonGroup mb30" size="large" exclusive
                                       value={values["usCitizen"]}
                                       onChange={() => {
                                           setFieldValue("usCitizen", !values["usCitizen"]);
                                           setFieldValue("employeeSSN", "");

                                           setFieldValue("howLongInUS", "");
                                           setFieldValue("countryCitizenship", "");
                                           setFieldValue("immigrationStatus", "");
                                           setFieldValue("greenCardNo", "");
                                           setFieldValue("typeOfVisaPlanHeld", "");
                                           setFieldValue("typeOfVisaHeld", "");
                                           setFieldValue("immigrationStatusOther", "");
                                       }}>
                        <ToggleButton value={true} disabled={clientSigned ? true : false}
                                      className={values["usCitizen"] ? "active" : ""}>
                            Yes
                        </ToggleButton>
                        <ToggleButton value={false} disabled={clientSigned ? true : false}
                                      className={values["usCitizen"] ? "" : "active"}>
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Typography variant="caption" className="floatLeft">
                        <Label label={"How would you like to verify your identity?"}
                               required={conditionalRequired}/>
                    </Typography>
                    <ToggleButtonGroup
                            color={"primary"}
                            className="toggleButtonGroup"
                            size="large"
                            exclusive
                            id={"identityType"}
                            value={values["identityType"]}
                            onChange={(e: any, val: string) => {
                                setFieldValue("identityType", val);
                                setFieldValue("identityId", "");
                                setFieldValue("proofExpiryYear", 0);
                                setFieldValue("proofExpiryMonth", 0);
                                setProofExpired(false);
                            }}
                    >
                        <ToggleButton value={"drivingLicence"}
                                      disabled={clientSigned ? true : false}
                                      className={values["identityType"] === "drivingLicence" ? "active" : ""}>
                            US DRIVER'S LICENSE
                        </ToggleButton>
                        <ToggleButton value={"passport"}
                                      disabled={clientSigned ? true : false}
                                      className={values["identityType"] === "drivingLicence" ? "" : "active"}>
                            US PASSPORT
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {
                            values["identityType"] === "passport" && !isFromClientDetail &&
                            <span className="f12 colorRed floatLeft w100 mt15">
                                    Using a passport for ID will require utility docs for address verification.
                            </span>
                    }
                </Grid>

                {fieldObj.map((field, index) => {
                    if (role === 1 || role === 2 || role === 4) {
                        if (field["name"] === "blotSSN" && !client.blotSSN) {
                            return null;
                        } else {
                            if (field["name"] === "employeeSSN" && !(client && client.employeeSSN) && (client && client.blotSSN)) {
                                return null;
                            }
                        }
                    } else {
                        if (!values["usCitizen"] && field["name"] === "blotSSN") {
                            return null;
                        }
                        if (field["name"] === "blotSSN" && !clientSigned) {
                            return null;
                        }
                        if (values["usCitizen"] && field["name"] === "employeeSSN" && clientSigned) {
                            return null;
                        }
                    }

                    return (
                            <React.Fragment key={index}>
                                {field.name === "birthCountry" &&
                                <BirthDate
                                        fieldObj4={fieldObj4}
                                        minAge={minAge}
                                        CommonField={CommonField}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setAnticipatedAge={setAnticipatedAge}
                                        clientSigned={!!clientSigned}
                                />
                                }

                                <Grid item xs={12}
                                      md={6}
                                      xl={3}
                                      key={index + field["name"]}>
                                    <>
                                        {
                                            CommonField(
                                                    field,
                                                    {
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        setFieldValue,
                                                    },
                                            )
                                        }
                                    </>
                                </Grid>


                                {field.name === "identityId" && <>
                                    <Grid item xs={12} ref={proofExpireRef} id="proofExpireRef">
                                        <Typography variant="caption" className="floatRight-">
                                            Expiry Date (must
                                            match {values["identityType"] === "drivingLicence" ? "drivers license" : "passport"})
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={2} style={{marginLeft:2,marginBottom:20}}>
                                        <LicenceExpiry
                                                fieldObj7={fieldObj7}
                                                proofExpireRef={proofExpireRef}
                                                proofExpired={proofExpired}
                                                CommonField={CommonField}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                        />
                                    </Grid>
                                </>}
                            </React.Fragment>
                    );
                })
                }
            </>);
};
export default Citizenship;