import * as React from "react";
import qs from "query-string";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    Link,
    Typography,
} from "@material-ui/core";
import EstimationForm from "./EstimationForm";
import EstmateSectionThreeContant from "./EstmateSectionThreeContent";
import ReactPlayer from "react-player";
import laptopImg from "../../../../../components/client/landingPage/v2/images/img-ipad-dashboard.jpg";
import animation_Icon from "../../../../../components/client/landingPage/v2/images/animation_Icon.gif";
import RectangleShape from "../../../../../components/client/landingPage/v2/images/RectangleShape.svg";
import Disclaimer from "../../../../../components/client/kaizen/Disclaimer";
import DisclaimerTrizen from "../../../../../components/client/trizen/Disclaimer";
import { useActions } from "../../../../../actions";
import * as ClientActions from "../../../../../actions/client";
import { animateScroll as scroll } from "react-scroll/modules";
import { EstimationPayload } from "../../../../../model/estimation";
import numeral from "numeral";
import * as AccountActions from "../../../../../actions/account";
import * as EstimationActions from "../../../../../actions/estimation";
import { RootState } from "../../../../../reducers";
import { connect } from "react-redux";
import { fiveYearTrustFee, kaiZenPhysicianRebrand, kaiZenRebrand } from "../../../../../constants";

let defaultestimationData: any = {
    "def_comp_beginning_age": 0,
    "def_comp_ending_age": 0,
    "def_comp_total": 0,
    "def_comp_annual": 0,
    "def_comp_irr": "0",
    "kaizen_beginning_age": 0,
    "kaizen_ending_age": 0,
    "kaizen_annual_income": 0,
    "kaizen_total_income": 0,
    "kaizen_initial_death_benefit": 0,
    "kaizen_death_benefit_yr1": 0,
    "kaizen_death_benefit_yr90": 0,
    "kaizen_levd_irr": "0",
    "kaizen_unlevd_irr": "0",
    "kaizen_1980s_stress_test": 0,
    "kaizen_great_depression_test": 0,
    "lsw_beginning_age": 0,
    "lsw_ending_age": 0,
    "lsw_comparison_annual_income": 0,
    "lsw_comparison_total_income": 0,
    "lsw_comparison_initial_death_benefit": 0,
    "lsw_comparison_death_benefit_yr1": 0,
    "lsw_comparison_death_benefit_yr90": 0,
    "lsw_comparison_unlevd_irr": "0",
    "bonus_irr": "0",
    "bonus_beginning_age": 0,
    "bonus_ending_age": 0,
    "bonus_comp_total": 0,
    "bonus_comp_annual": 0,
};

const EstmateSectionThree = (props: any) => {
    const searchParams = qs.parse(props.location.search);
    const [estimatedData, setEstimatedData] = React.useState(defaultestimationData);
    const [estimatedDetail, setEstimatedDetail] = React.useState(defaultestimationData);

    let total_5yr_Contribution: number = 0;
    if( Object.keys(searchParams).length > 0 ){
        total_5yr_Contribution = (searchParams && searchParams.contribution) ? numeral((searchParams && searchParams.contribution) || 0).value() * 5 : 0;
    }
    if (total_5yr_Contribution > 0) {
        total_5yr_Contribution += fiveYearTrustFee;
    }

    const [totalContribution, setTotalContribution] = React.useState(props.totalContribution || total_5yr_Contribution);
    const [showEstimationForm, setShowEstimationForm] = React.useState(props.strategyData.slug === 'kaizen');
    const [loading, setLoading] = React.useState(false);

    const accountActions = useActions(AccountActions);
    const estimationActions = useActions(EstimationActions);

    const isAgreementAccepted = props.client && !props.client.isAgreementAccepted;
    const [openDialog, setOpenDialog] = React.useState(isAgreementAccepted);
    const clientActions = useActions(ClientActions);
    const [isUnderstandDisclaimer, SetUnderstandDisclaimer] = React.useState(false);

    const { client } = props;

    const strategySlug: string = props.strategy && props.strategy.slug ? props.strategy.slug : "kaizen";
    let carrierName: string = (props.carrier && props.carrier.carrierName) ? props.carrier.carrierName : "National Life - (LSW)";

    const strategSlugy: string = props.strategyData.slug;

    let videoUrl: string = "https://vimeo.com/370399450";

    if (props.strategyData.slug === "esd") {
        videoUrl = "https://vimeo.com/394503173";
    }

    if (props.strategyData.slug === "kaizen") {
        videoUrl = "https://vimeo.com/370399450";
    }

    if (props.strategyData.slug === "trizen") {
        videoUrl = "https://vimeo.com/388591717";
    }

    if (props.strategyData && props.strategyData.slug === "kaizen" && props.strategyData.id === kaiZenRebrand) { // kai-zen aon case
        videoUrl = "https://vimeo.com/731874233";
    }

    if (props.strategyData && props.strategyData.slug === "kaizen" && props.strategyData.id === kaiZenPhysicianRebrand) { // kai-zen phy aon case
        videoUrl = "https://vimeo.com/731861798";
    }

    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);


    React.useEffect(() => {

        window.addEventListener("resize", handleResize);
        return (() => {
            window.removeEventListener("scroll", handleResize);
        });
    }, []);

    const handleResize = (e: any) => {
        setIsMobile(window.innerWidth < 992);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getEstimates = async () => {
        if (Object.keys(searchParams).length > 0) {
            let urlPaylaod: EstimationPayload = {
                age: searchParams.age ? searchParams.age : client.age,
                gender: searchParams.gender as string === "1" ? "Male" : "Female",
                rating: searchParams.healthType as string || "Standard",
                contribution: numeral(searchParams.contribution).value() + "",
                strategySlug: strategySlug,
                carrierName: carrierName,
            };
            let data: any = await estimationActions.getInvitedClientEstimates(urlPaylaod, "");
            if (data && data.data && data.data.result) {
                setEstimatedDetail(data.data.result);
            }
        } else {
            let payload: EstimationPayload = {
                age: props.age ? props.age : client.age,
                gender: props.gender ? props.gender === 1 ? "Male" : "Female" : client.gender === 1 ? "Male" : "Female",
                rating: client.healthType || "Standard",
                contribution: props.contribution ? numeral(props.contribution).value() + "" : numeral(client.contributionAmount).value() + "",
                strategySlug: strategySlug,
                carrierName: carrierName,
            };
            let data: any = await estimationActions.getInvitedClientEstimates(payload, "");
            if (data && data.data && data.data.result) {
                setEstimatedDetail(data.data.result);
            }
        }
    };

    React.useEffect(() => {
        getEstimates();
    }, [estimatedDetail && estimatedDetail.kaizen_initial_death_benefit === 0]);

    React.useEffect(() => {
        if ((props.estimationData && props.gender && props.healthType) || Object.keys(searchParams).length > 0) {
            updateEstimatorPayload();
            setEstimatedDetail(props.estimationData ? props.estimationData : estimatedData);
        }

    }, [total_5yr_Contribution, props.estimationData]);

    const updateEstimatorPayload = async () => {
        if (Object.keys(searchParams).length > 0
                && searchParams.gender
                && searchParams.healthType
                && searchParams.age
        ) {
            let urlProfileData: any = {
                gender: searchParams.gender,
                healthType: searchParams.healthType,
                contributionAmount: numeral(searchParams.contribution).value() + "",
                age: searchParams.age,
            };
            await accountActions.updateInvitedUserClientDetails(urlProfileData);
        } else {
            if (props.gender &&
                    props.healthType &&
                    props.age) {
                let profileData: any = {
                    gender: props.gender,
                    healthType: props.healthType,
                    contributionAmount: numeral(props.contribution).value() + "",
                    age: props.age,
                };
                await accountActions.updateInvitedUserClientDetails(profileData);
            }
        }
    };

    const goToDashboard = () => {
        setLoading(true);
        window.location.href = "/accounts";
    };

    const handleDisclaim = async () => {
        try {
            await clientActions.acceptAgreement();
            setOpenDialog(false);
            const inviteToken: string = (props.match && props.match.params && props.match.params.inviteToken) || '';
            const isNewInviteLanding3 = props.history.location.pathname.search("/accept/new-invitation/") > -1;

            console.info({ isNewInviteLanding3, inviteToken, searchParams });
            if (isNewInviteLanding3 && !!inviteToken && searchParams && searchParams.landing === "3") {
                goToDashboard();
            }
        } catch (ex) {
            alert(ex.message);
        }
    };

    const getDisclaimerPopup = () => {
        return (
                <Dialog
                        maxWidth={"lg"}
                        open={openDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        disableEscapeKeyDown
                >
                    <Typography className="mt20 mb20 ml15"> DISCLAIMER</Typography>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            {
                                strategSlugy === "kaizen" ?
                                        <Disclaimer/> :
                                        <DisclaimerTrizen strategy={props.strategyData} />
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <FormControlLabel
                                className="formControlCheckboxClient"
                                control={<Checkbox
                                        style={{ height: 22 }}
                                        color="primary"
                                        onChange={() => SetUnderstandDisclaimer(!isUnderstandDisclaimer)}
                                />}
                                label="I Understand"/>
                        <Button
                                className="btnPrimary "
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleDisclaim}
                                disabled={!isUnderstandDisclaimer}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };


    return (
            <>
                <div className="innerSectionPadding">
                    {getDisclaimerPopup()}
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={11} lg={10} xl={9}>
                            <Grid container spacing={2} justify="center" className="first-step">
                                <Grid item xs={12} md={8} lg={8} className="textCenter mt30 forTourSection">
                                    <Typography variant="h1" className="mb10 forTourSection">View your results
                                        below.</Typography>
                                    <Typography variant="subtitle1" className="mb30 forTourSection" component="strong">Change the annual
                                        income amount or other fields to see new estimation results.</Typography>
                                    {!showEstimationForm &&
                                    <Typography variant="body1" className="mb30"> <Link
                                            onClick={() => setShowEstimationForm(true)}>Click here</Link> to enter a new
                                        estimation amount.</Typography>
                                    }
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="textCenter mt0">
                                    {showEstimationForm &&
                                    <EstimationForm
                                            setTotalContribution={(val) => setTotalContribution(val)}
                                            sendEstimationOnParent={(estimationData: any) => {
                                                setEstimatedData(estimationData);
                                                setEstimatedDetail(estimationData);
                                            }}   {...props} />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justify="center">
                                {estimatedData &&
                                <>
                                    <Grid item xs={12} md={12} className="textCenter">
                                        <img alt={"animationicon"} height="80px" src={animation_Icon} onClick={() => {
                                            scroll.scrollTo(isMobile ? 430 : 500);
                                        }}/>
                                    </Grid>
                                    <Grid id={"estmateSectionThreeContant"} item xs={12} md={12}>
                                        <EstmateSectionThreeContant estimatedDetail={estimatedDetail}
                                                                    totalContributionValue={totalContribution} {...props} />
                                    </Grid>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div id={"SectionThreeContantBellow"}>
                    <div className="sectionShape innerSectionPadding">
                        <img alt={"rectangleshape"} className="rectangleShapeBefore" src={RectangleShape}/>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={11} lg={10} xl={9}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} md={12} lg={12} className="textCenter">
                                        <Typography variant="h1" align="center"
                                                    className="colorWhite mt40 mb30 tittle tittleCenter ">Sound too
                                            good to be
                                            true?
                                        </Typography>
                                        <Typography variant="body1" align="center" className="colorWhite">Watch this
                                            short video
                                            to learn exactly how we make leverage work for you.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={10} md={10} lg={8} className="mt30 mb40">
                                        <ReactPlayer
                                                className="landingVideos"
                                                controls={true}
                                                url={videoUrl}
                                                width="100%"
                                                height="470px"
                                                style={{ margin: "0 auto" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="innerSectionPadding pd0">
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={10} lg={7} className="textCenter">
                                <Typography variant="h1" align="center" className="tittle tittleCenter mb20">Navigating
                                    the site is easy
                                    using your dashboard.
                                </Typography>
                                <Typography align="center" variant="body1">Learn more on your myilia.com secure personal
                                    dashboard.
                                </Typography>
                                <Button onClick={() => goToDashboard()} disabled={loading} variant="contained"
                                        color="primary" className="mt40 mb20">
                                    Go to Dashboard
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={11} md={10} lg={10} className="mt30">
                                <img alt={"imgresponsive"} className="imgResponsive" src={laptopImg}/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
    );
};


function mapStateToProps(state: RootState, ownProps: any) {
    if (state.preEstimationData) {
        return state.preEstimationData;
    }

    let verifyData: any = {};
    verifyData.healthType = "Standard";
    verifyData.contribution = "";
    verifyData.age = "";
    verifyData.gender = "";

    if (state.verify && state.verify.client) {
        verifyData.contribution = state.verify.client.contributionAmount || "";
        verifyData.age = state.verify.client.age || "";
        verifyData.gender = state.verify.client.gender || 0;
        verifyData.healthType = state.verify.client.healthType || "";
    }
    return verifyData;
}

export default connect(mapStateToProps)(EstmateSectionThree);
