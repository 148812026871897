import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { hidePartOfSSN } from "../../utils/commonUtils";

export default function BlotFieldShowHide(props:any) {
    const [showSSN, setShowSSN] = useState(true);

    const toggleSSNVisibility = () => {
        setShowSSN(!showSSN);
    };

    const ssn = props.value || "";
    const showHideSsn = showSSN ? hidePartOfSSN(ssn) : ssn;

    return (
            <>
                <TextField label={props.label} variant={props.variant} value={showHideSsn} disabled={props.disabled}/>
                <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={toggleSSNVisibility}
                        className="mt5"
                >
                    {showSSN ? "Show SSN " : "Hide SSN"}
                </Button>
            </>
    );
};

