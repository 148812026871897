import React, {useEffect} from "react";
import CaseDetails from "../forms/CaseDetails";
import TrustDetails from "../forms/TrustDetails";
import SettlorDetails from "../forms/SettlorDetails";
import BankDetails from "../forms/BankDetails";
import BenefitAmount from "../forms/BenefitAmount";
import Distributions from "../forms/Distributions";
import PolicyBreakDown1 from "../forms/PolicyBreakDown1";
import { Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import {Formik} from "formik";
import * as AccountActions from "../../../../../actions/account";
import * as Yup from "yup";
import {useActions} from "../../../../../actions";
import numeral from "numeral";
import {caseDetailsInitialValues} from "./CaseDetailsInitialValues";
import moment from "moment";
import CommonLoader from "../../../../../components/CommonLoader";
import FocusError from "../../../../../components/FocusError";
import {fullName} from "./ClientProfileTab";
import {can} from "../../../../../components/navigation/AdminNavigation";
import {PermissionTypes, PolicyStatusEnum} from "../../../../../constants";
import {RootState} from "../../../../../reducers";
import {useSelector} from "react-redux";
import AddCombineTrust from "../forms/AddCombineTrust";
import * as CarrierActions from "../../../../../actions/carrier";
import {asDbDateFormat, DATE_FORMAT_DOB_SAVE} from "../../../../../utils/dateUtils";
import ViewServicing from "../../../../../components/client/ViewServicing";
import {hidePartOfSSN, parseAndFormat, pez, va} from "../../../../../utils/commonUtils";
import CaseDetailsBeneficiaries from "../forms/CaseDetailsBeneficiaries";
import {
    getFormikField
} from "../../../Enrollment/EnrollmentHome/EnrollmentApplication/Beneficiaries/BeneficiariesList";

const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {};

    if (values) {
        Object.entries(values).forEach((entry) => {
            let key = entry[0];
            let value = entry[1];

            if (value !== initialValues[key]) {
                modifiedValues[key] = value;
            }
        });
    }

    return modifiedValues;
};

const CaseDetailTab = (props : {clientDetail: any , ssnFlag: boolean, setActionItem: Function, caseDetailButtonRef?: any}) => {
    const {clientDetail, ssnFlag, setActionItem, caseDetailButtonRef} = props;
    const processorList = useActions(CarrierActions);
    const managerList = useActions(AccountActions);
    const user = useSelector((state: RootState) => state.auth.user);
    const { saveCaseDetails, getCaseDetails, updateCaseDetails, createPolicyBreakDown, getCombinedClientDetails } = useActions(AccountActions);
    const [isPolicyDirty, setIsPolicyDirty] = React.useState(false);
    const [policyArr, setPolicyArr] = React.useState([]);
    const [caseDetails, setCaseDetails] = React.useState<any>({});
    const [policyBreakDown, setPolicyBreakDown] = React.useState<any>([]);
    const [initialValues, setInitialValues] = React.useState<any>(caseDetailsInitialValues);
    const [spousesSharesTrustFee, setSpousesSharesTrustFee] = React.useState(false);
    const [actionTypeRequest, setActionTypeRequest] = React.useState<any>({});
    const [formError, setFormError] = React.useState(false);
    const [distributionFormError, setDistributionFormError] = React.useState<boolean>(false);
    const [loanPayOffDateError, setLoanPayoffDateError] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<boolean>(true);
    const [policyBreakDownExpanded, setPolicyBreakDownExpanded] = React.useState<any>({year: "", panel: "", clientId: null});
    const [minAge, setMinAge] = React.useState<number>(25);
    const [combineClientObj, setCombineClientObj] = React.useState<any>("");
    const [policyStatusDisabled, setPolicyStatusDisabled] = React.useState(false);
    const setRefreshMain = React.useCallback((valueArr) => {
        setPolicyArr(valueArr);
    }, []);
    const setPolicyFormDirty = React.useCallback(() => {
        setIsPolicyDirty(true);
    }, []);
    const [caseProcessorList, setCaseProcessorList] = React.useState<object[]>([]);
    const [caseManagerList, setCaseManagerList] = React.useState<object[]>([]);

    const fetchCaseDetail = React.useCallback(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.id) {
            getClientCaseDetails(clientDetail.client.id).then(() => {
            });
        }
    }, []);

    const getClientCaseDetails = async (clientId: number) => {
        const details = await getCaseDetails(clientId);
        setCaseDetails(details);
        setProgress(false);
        setPolicyBreakDown(details ? details.policyBreakDowns : []);
    }
    const getCombineClientDetails = async (clientId) => {
       const result = await getCombinedClientDetails({clientId});
        setCombineClientObj({
            key: result && result.client.id,
            name: result && result.firstName + " " + result.lastName,
            title: result && result.firstName + " " + result.lastName,
        });
    }
    React.useEffect(()=>{
        if(caseDetails && caseDetails.id) {
            let issueDate:any = clientDetail && clientDetail.client && clientDetail.client.tranche && clientDetail.client.tranche && clientDetail.client.tranche.issueDate;
            let dob:any = caseDetails && caseDetails.dob;
            const {clientPolicies, trustDetails, bankDetails, benefitAmounts, clientPayments, distributions, user, employerPayments} = caseDetails
            const settlorDetails = va(clientPolicies) && clientPolicies[0].settlorDetails;
            let trustName:string = "";
            let policyStatus:string = "";
            if (trustDetails) {
                trustName = trustDetails.trustName;
            } else {
                if (caseDetails && caseDetails.noOfPolicies > 0) {
                    trustName = `The ${user && user.name + " - " + (numeral(caseDetails.noOfPolicies).value() + 1)}`;
                } else {
                    trustName = `The ${user && user.name}`;
                }
            }
            if (caseDetails && caseDetails.user) {  /* All the status fetch from the src/model/index.ts of AccountStatusArr */
                const inviteStatus: number = Number(caseDetails && caseDetails.user && caseDetails.user.inviteStatus);
                const override = caseDetails && caseDetails.user && caseDetails.user.override; // 1 means policy issued override
                if (caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.Deceased) {
                    policyStatus = "Deceased";
                } else if (caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.Surrendered) {
                    policyStatus = "Surrendered";
                } else if ([-2].indexOf(inviteStatus) > -1) {
                    policyStatus = "Disabled";
                } else if (caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForce) {
                    policyStatus = "Policy Issued";
                }  else if ([-1, 0, 1, 2].indexOf(inviteStatus) > -1) {
                    policyStatus = "Invited";
                } else if ([3, 4, 5, 6, 7, 8, 9, 10, 11].indexOf(inviteStatus) > -1) {
                    policyStatus = "Enrolling";
                } else if ([12, 13, 14, 15, 16].indexOf(inviteStatus) > -1) {
                    policyStatus = "Proposed";
                } else if ([17, 18, 19, 20].indexOf(inviteStatus) > -1) {
                    policyStatus = "Quoted";
                } else if ([21].indexOf(inviteStatus) > -1) {
                    policyStatus = "Payment Received";
                } else if ([22].indexOf(inviteStatus) > -1) {
                    policyStatus = "Case Submitted";
                    if (caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForce) {
                        policyStatus = "Policy Issued";
                    }
                } else if ([26].indexOf(inviteStatus) > -1) {
                    policyStatus = "Design";
                }  else if ([25].indexOf(inviteStatus) > -1 && override) {
                    policyStatus = "Policy Issued (Override)";
                }
                if(caseDetails && caseDetails.purpose === "ILIA" && (inviteStatus !== 22 && inviteStatus !== 27 )){
                    //setPolicyStatusDisabled(true); // Task Number NIWC-1922 and confirmed also
                }
            }


            let contributionAmount = caseDetails && caseDetails.contributionAmount || "";
            let deathBenefit = caseDetails && caseDetails.deathBenefit || "";
            deathBenefit = deathBenefit || benefitAmounts && benefitAmounts.initialTotalDeathBenefit || "";

            const livingBenefits = (caseDetails && caseDetails.livingBenefits) || [];
            let birthDate = caseDetails && caseDetails.dob || "";
            let birthMonth: number = 0;
            let birthDay: number = 0;
            let birthYear: number = 0;
            if (birthDate) {
                let birthDateArr = birthDate.split("-");
                birthYear = Number(birthDateArr[0]);
                birthMonth = Number(birthDateArr[1]);
                birthDay = Number(birthDateArr[2]);
            }

            let initVal = {
                //dob fields
                dob: !!(caseDetails.dob) ? moment(caseDetails.dob).format("MM/DD/yyyy") : null,
                age: moment().diff(caseDetails.dob, "years") || caseDetails.age,
                birthMonth: birthMonth,
                birthDay: birthDay,
                birthYear: birthYear,
                identityType: caseDetails && caseDetails.identityType || "drivingLicence",
                //Case Details initial values
                policyNumber: caseDetails && caseDetails.policyNumber || "",
                purpose: caseDetails && caseDetails.purpose || "ILIA",
                policyStatus: policyStatus || "",
                caseProcessorId: caseDetails && caseDetails.caseProcessorId || "",
                caseManagerId: caseDetails && caseDetails.caseManagerId || "",
                employeeSSN: caseDetails && caseDetails.employeeSSN && numeral((caseDetails.employeeSSN).replace(/\D+/g, "")).value() > 0 ? caseDetails.employeeSSN : "" || "",
                //issueAge: (caseDetails && numeral(caseDetails.issueAge).value() > 0 ? caseDetails && caseDetails.issueAge : "") || "",
                healthRating: (caseDetails && caseDetails.healthType) || "",
                strategyName: (clientDetail && clientDetail.client && clientDetail.client.strategy && clientDetail.client.strategy.displayName) || "",
                carrierId: (clientDetail && clientDetail.client && clientDetail.client.carrier && clientDetail.client.carrier.id) || "",
                productName: (caseDetails && caseDetails.productName) || "",
                offerType: (clientDetail && clientDetail.client && clientDetail.client.underWritingOfferType) || "",
                agent: (clientDetail && clientDetail.client && clientDetail.client.agent && clientDetail.client.agent && clientDetail.client.agent.user && fullName(clientDetail.client.agent.user)) || 0,
                imo: (clientDetail && clientDetail.client && clientDetail.client.imo && clientDetail.client.imo.groupName) || "",
                salesRegion: (clientDetail && clientDetail.client && clientDetail.client.imo && clientDetail.client.imo.region) || "",
                issueDate: issueDate || null,
                //dob: dob || null,
                trancheNo: (caseDetails && caseDetails.tranche && caseDetails && caseDetails.tranche.trancheNumber) || "",
                trancheStarted: (caseDetails && caseDetails.tranche && caseDetails && caseDetails.trancheStarted) || "",
                trancheClosed: (caseDetails && caseDetails.tranche && caseDetails && caseDetails.trancheClosed) || "",
                //livingBenefit: (caseDetails && caseDetails.livingBenefit) || "",
                livingBenefit: livingBenefits.map(p => p.livingBenefitId) || [],
                actualHealthRating: caseDetails && caseDetails.actualHealthRating || "",
                actualHealthRatingOther: caseDetails && caseDetails.actualHealthRatingOther || "",
                anniversaryDate: caseDetails && caseDetails.anniversaryDate || null,

                loanPayOffDate: issueDate ? moment(issueDate).add(14, "year").format("YYYY-MM-DD") : null,
                startYearOfDeathBenefitReductions: issueDate ? moment(issueDate).subtract(90, "days").add(11, "year").format("YYYY-MM-DD") : null,

                initialTotalDeathBenefit: (deathBenefit && numeral(deathBenefit).value() > 0 ? deathBenefit : "") || "",
                termRiderDeathBenefit: (benefitAmounts && numeral(benefitAmounts.termRiderDeathBenefit).value() >  0 ? benefitAmounts && benefitAmounts.termRiderDeathBenefit : "") || "",
                baseDeathBenefit: (deathBenefit && numeral(deathBenefit).value()  > 0 ? (numeral(deathBenefit).value())-(benefitAmounts && numeral(benefitAmounts.termRiderDeathBenefit).value()): "") || "",



                //Trust Details initial values
                einNumber: (trustDetails && trustDetails.einNumber) || "",
                trustName: (trustName || trustDetails && trustDetails.trustName) || "",
                providentAccountNo: (trustDetails && trustDetails.providentAccountNo) || "" ,
                revocability: (trustDetails && trustDetails.revocability) || "",
                trustAmendmentNumber: (trustDetails && trustDetails.trustAmendmentNumber) || "0",

                //Client Payments initial values
                /*thisYearContributionDueDate: issueDate || null,
                thisYearContributionAmountDue: thisYearContributionAmountDue || contributionAmount,
                thisYearBalanceReceived: (clientPayments && numeral(clientPayments.thisYearBalanceReceived).value() > 0 ? clientPayments && clientPayments.thisYearBalanceReceived : "") || "",
                nextYearContributionDueDate: issueDate ? moment(issueDate).subtract(30, 'days').add(1, 'year').format("MM/DD/YYYY") : null,
                nextYearContributionAmountDue: (clientPayments && numeral(clientPayments.nextYearContributionAmountDue).value() > 0 ? clientPayments && clientPayments.nextYearContributionAmountDue : "") || "",
                nextYearBalanceReceived: (clientPayments && numeral(clientPayments.nextYearBalanceReceived).value() > 0 ? clientPayments && clientPayments.nextYearBalanceReceived: "") || "",
                annualEmployerContribution: (numeral(caseDetails && caseDetails.employerContribution).value() > 0 ? caseDetails && caseDetails.employerContribution : clientPayments && numeral(clientPayments.annualEmployerContribution).value() > 0 ? clientPayments && clientPayments.annualEmployerContribution : "") || "",
                paymentMethod: clientPayments && clientPayments.paymentMethod || null,*/
                //thisYearContributionDueDate: issueDate || null,

                annualEmployerContribution: (numeral(caseDetails && caseDetails.employerContribution).value() > 0 ? caseDetails && caseDetails.employerContribution : clientPayments && numeral(clientPayments.annualEmployerContribution).value() > 0 ? clientPayments && clientPayments.annualEmployerContribution : "") || "",
                bankPremiumAmount: (bankDetails && numeral(bankDetails.bankPremiumAmount).value() > 0 ? bankDetails && bankDetails.bankPremiumAmount : "") || "",

                //Employer Payments initial values


                //combine trust initial values
                isCombineTrust: !!caseDetails.isCombineTrust,
                participantId: combineClientObj || {key: "", name: "", title: ""},
                issueAge: calculateIssueAge(caseDetails) || caseDetails.issueAge || ""
            }
                //Benefits initial values
                if (benefitAmounts) {

                    //Benefits initial values
                    Object.assign(initVal, {
                        // initialTotalDeathBenefit: parseAndFormat(benefitAmounts.initialTotalDeathBenefit),
                        // termRiderDeathBenefit: parseAndFormat(benefitAmounts.termRiderDeathBenefit),
                        // baseDeathBenefit: parseAndFormat(benefitAmounts.baseDeathBenefit),
                        incomeStartAge: (benefitAmounts && numeral(benefitAmounts.incomeStartAge).value() > 0 ? benefitAmounts && benefitAmounts.incomeStartAge : "") || 65,
                        incomeEndAge: (benefitAmounts && numeral(benefitAmounts.incomeEndAge).value() > 0 ? benefitAmounts && benefitAmounts.incomeEndAge : "") || 90,
                        estimatedAnnualIncome: parseAndFormat(benefitAmounts.estimatedAnnualIncome),
                        targetAmount: parseAndFormat(benefitAmounts.targetAmount)
                    });
                }

                //Distributions initial values
                if (distributions) {
                    Object.assign(initVal, {
                    incomeStartDate: (distributions.incomeStartDate) || null,
                    loanPayOffAmount: parseAndFormat(distributions.loanPayOffAmount),
                    });
                }


            if (caseDetails) {
                Object.assign(initVal, {
                    //Trust Details initial values
                    annualTrustFeeAmount: caseDetails.tranche && parseAndFormat(caseDetails.tranche.annualTrustFeeAmount) || "",
                    spouse: (caseDetails.beneficiary && caseDetails.beneficiary[0] && caseDetails.beneficiary[0].spouseName || trustDetails && trustDetails.spouse) || "",
                    trustFirstYearFee: caseDetails.tranche && parseAndFormat(caseDetails.tranche.trustFirstYearFee) || "",
                    trustAnnualFee: caseDetails.tranche && parseAndFormat(caseDetails.tranche.trustAnnualFee) || "",
                    policyNumber: caseDetails.policyNumber || "",
                });
                if (caseDetails.tranche) {
                    Object.assign(initVal, {
                        //Bank initial values
                        bankFinancedWith: (caseDetails.tranche.bankFinancedWith) || "",

                        arrangementFee:  parseAndFormat(caseDetails.tranche.arrangementFee),
                        bankMargin: (caseDetails.tranche.bankMargin) || "",
                        niwMarginOverride: (caseDetails.tranche.niwMarginOverride) || "",

                    });
                }
            }


            if (settlorDetails) {
                Object.assign(initVal, {
                    // Settlor Details initial values
                    clientPolicyId: pez(settlorDetails.clientPolicyId),
                    email: pez(settlorDetails.email),
                    firstName: pez(settlorDetails.firstName),
                    middleName: pez(settlorDetails.middleName),
                    lastName: pez(settlorDetails.lastName),
                    phoneNumber: pez(settlorDetails.phoneNumber),
                    relationshipToInsured: pez(settlorDetails.relationshipToInsured),
                    otherRelation: pez(settlorDetails.otherRelation),
                    ifTrustName: pez(settlorDetails.ifTrustName),
                    ssn: pez(settlorDetails.ssn),
                    streetAddress: pez(settlorDetails.streetAddress),
                    city: pez(settlorDetails.city),
                    state: pez(settlorDetails.state),
                    zip: pez(settlorDetails.zip)
                });
            }

            // console.info( benefitAmounts, numeral(deathBenefit).value(), numeral(benefitAmounts.termRiderDeathBenefit).value());

            setInitialValues(initVal);
            setSpousesSharesTrustFee(!!(trustDetails && numeral(trustDetails.spouseSharesTrustFees).value() === 1))
        }
    },[caseDetails, combineClientObj])

    React.useEffect(()=>{
        if(clientDetail && clientDetail.client && clientDetail.client.id) {
            getClientCaseDetails(clientDetail.client.id).then(()=>{});
        }
    },[clientDetail]);

    React.useEffect(() => {
        if (caseDetails && caseDetails.combinedClientId) {
            const clientId = caseDetails.combinedClientId; // combined client's Id
            getCombineClientDetails(clientId).then(() => {
            });
        } else {
            setCombineClientObj({
                key: "",
                name: "",
                title: "",
            });
        }
    }, [caseDetails]);

    React.useEffect(() => {
        if (policyBreakDownExpanded && policyBreakDownExpanded.isExpanded && policyBreakDownExpanded.clientId) {
            const individualPolicy = policyBreakDown.find((item) => item.policyYear === policyBreakDownExpanded.policyYear);
            const trancheNo = caseDetails && caseDetails.tranche && caseDetails && caseDetails.tranche.trancheNumber;
            if (!individualPolicy && trancheNo) {
                createNewPolicy({
                    policyYear: policyBreakDownExpanded.policyYear,
                    clientId: policyBreakDownExpanded.clientId
                }).then(() => {
                    getClientCaseDetails(clientDetail.client.id).then(()=>{});
                });
            }

        }
    }, [policyBreakDownExpanded]);

    useEffect(() => {
        processorList.getCaseProcessorList('Processing').then((res) => {
            const caseProcessorArr = res && res.length > 0 && res.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    name: `${item.firstName} ${item.lastName}`,
                };
            }) || [];
            if(Object.keys(caseProcessorArr).length > 0){
                setCaseProcessorList(caseProcessorArr);
            } else {
                let caseProcessorArray = [{key: 'empty', value: 'empty', name: 'No Case Processor Found'}];
                setCaseProcessorList(caseProcessorArray);
            }
        });
    }, [caseDetails]);

    useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.imo && clientDetail.client.imo.user && clientDetail.client.imo.user.id) {
            let queryObj: any = { isAccountCreated:true, page:0, rowsPerPage:25, searchText: "", filter: 2, sortBy: "createdAt", order: "desc" };
            managerList.listImoTeamMembers(queryObj,clientDetail.client.imo.user.id).then((res) => {
                const caseManagerArr = res && res.data && res.data.rows && res.data.rows.length > 0 && res.data.rows.map((item) => {
                    return {
                        key: item.ImoAdditionalMembers.userId,
                        value: item.ImoAdditionalMembers.userId,
                        name: `${item.memberFirstName} ${item.memberLastName}`,
                    };
                }) || [];
                if(Object.keys(caseManagerArr).length > 0){
                    setCaseManagerList(caseManagerArr);
                } else {
                    let caseManagerArray = [{key: 'empty', value: 'empty', name: 'No Case Manager Found'}];
                    setCaseManagerList(caseManagerArray);
                }
            });
        }
    }, [caseDetails]);

    const createNewPolicy = async (obj: any) => {
        await createPolicyBreakDown(clientDetail.client.id, obj);
    }
    const validateDataFields = [
        "policyNumber", "bankMargin", "issueAge", "spouse", "trustName", "bankFinancedWith", "annualEmployerContribution", "arrangementFee", "bankPremiumAmount", "initialTotalDeathBenefit",
            "termRiderDeathBenefit", "baseDeathBenefit", "targetAmount", "estimatedAnnualIncome", "loanPayOffAmount",
            "annualTrustFeeAmount" , "trustFirstYearFee", "trustAnnualFee", "einNumber", "niwMarginOverride", "dob"
    ];

    let keysValidation = {}
    validateDataFields.forEach((field:any) => {
        if(caseDetails && field === "issueAge"){ // Validation for positive integer value between 1 to 100
            //keysValidation[field] = Yup.number().integer('Enter valid number').min(1).max(100); // this is removed in task number NIWC-1928
        } else if(field === 'spouse' || field === 'trustName' || field === "bankFinancedWith"){ // Cant' allow blank space
            keysValidation[field] = Yup.string().matches(/^(?!\s+$).*/, '* This field cannot contain only blankspaces');
        } else if(field === "bankMargin" || field === "niwMarginOverride") {
            keysValidation[field] = Yup.number().min(0, 'Please enter valid number').max(100, 'Max allowed number is 100');
        } else if (field === "einNumber") {
            keysValidation[field] = Yup.string().transform((o, v) => v && String(v).replace(/\D+/g, "")).length(9, "Enter Valid Number");
        } else if (field === "annualEmployerContribution" || field === "arrangementFee"
            || field === "bankPremiumAmount" || field === "initialTotalDeathBenefit" || field === "termRiderDeathBenefit"
            || field === "baseDeathBenefit" || field === "targetAmount" || field === "estimatedAnnualIncome"
            || field === "loanPayOffAmount" || field === "trustFirstYearFee" || field === "trustAnnualFee" || field === "annualTrustFeeAmount"
        ) {
            keysValidation[field] = Yup.string().transform((o, v) => v && String(v).replace(/[.,\s]/g, ""))
                .test(
                    'Is positive?',
                    'ERROR: The number must be greater than $0',
                    (value) => {
                        return numeral(value).value() >= 0
                    }
                )
        } else if(field === "policyNumber") {
            keysValidation[field] = Yup.string().required("Please enter the required field").matches(/^[a-zA-Z0-9]*$/, "Special character's are not allowed").max(25, 'Must be at most 25 characters')
        } else if(field === "dob") {
            keysValidation[field] = Yup.string().nullable(true).test(
                    'Is Dob Valid?',
                    `Participants age must be between ${minAge}-65 - Contact your agent`,
                    (value) => {
                        let age:number = moment().diff(value, "years");
                        return (!((age || age === 0) && (age < minAge || age > 65)));
                    }
            );
        } else {
            keysValidation[field] = Yup.string().required("Required Field");
        }
    });

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const personalDetailSchema = Yup.object().shape(keysValidation);

    const canEdit = can(user, PermissionTypes.CASE_DETAILS);

    const calculateIssueAge = (caseDetails:any) => {
        if((caseDetails && caseDetails.carrierId) && (caseDetails && caseDetails.tranche && caseDetails.tranche.id) && (caseDetails && caseDetails.dob)) {
            let dob:string = caseDetails.dob;
            let fundingDate:string = caseDetails.tranche.projectFundingDate;
            let dobFormatted = moment(dob).format("MM-DD-YYYY");
            if(caseDetails && caseDetails.carriersDetails){
                if(caseDetails.carriersDetails && caseDetails.carriersDetails.allianzLife && caseDetails.carriersDetails.allianzLife.value === caseDetails.carrierId){
                    let updatedFundingDate = moment(fundingDate, "YYYY-MM-DD");
                    return updatedFundingDate.diff(dobFormatted, 'years');
                } else if(caseDetails.carriersDetails && caseDetails.carriersDetails.nationalLife && caseDetails.carriersDetails.nationalLife.value === caseDetails.carrierId){
                    let updatedFundingDate = moment(fundingDate, "YYYY-MM-DD").add(183, 'days');
                    return updatedFundingDate.diff(dobFormatted, 'years');
                } else {
                    return caseDetails.issueAge;
                }
            }
        }
    }

    return(

            <React.Fragment>
                {progress ?
                        <CommonLoader/>
                        :
                        <React.Fragment>
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                //validate={values => validate(values)}
                                validationSchema={personalDetailSchema}
                                validateOnChange={true}
                                onSubmit={async (values, {setSubmitting} ) => {
                                    const touchedVal = getModifiedValues(values, initialValues);
                                    //setInitialValues(values);
                                    let spouseSharesTrustFee = spousesSharesTrustFee ? 1 : 0;
                                    let finalValues: any = {...touchedVal}

                                    const settlorKeys = ["city", "email", "firstName", "ifTrustName", "lastName", "middleName", "phoneNumber", "otherRelation", "relationshipToInsured", "ssn", "state", "streetAddress", "zip"];

                                    Object.keys(finalValues).map((key: string) => {
                                        if (key === "policyStatus" || key === "strategyName" || key === "caseProcessorId" || key === "caseManagerId" ||
                                            key === "healthRating" || key === "spouse" || key === "trustName" ||
                                            key === "bankFinancedWith" || key === "incomeStartDate" ||
                                            key === "issueDate" || key === "loanPayOffDate" ||
                                            key === "dob" ||
                                            key === "startYearDeathBenefitReductions" ||
                                            //key === "thisYearContributionDueDate" ||
                                            settlorKeys.includes(key) ||
                                            key === "trancheClosed" ||
                                            key === "trancheStarted" || key === "startYearOfDeathBenefitReductions" ||
                                            key === "livingBenefit" ||
                                            key === "productName" ||
                                            key === "trancheStarted" ||
                                            key === "trancheClosed" ||
                                            key === "policyNumber" ||  key === "actualHealthRating" || key === "actualHealthRatingOther" ||  key === "anniversaryDate" || key === "purpose" ||
                                            key === "revocability" || key === "providentAccountNo"
                                        ) {
                                            finalValues[key] = values[key] ? values[key] + "" : null;
                                            if (key === "actualHealthRatingOther" && values["actualHealthRating"] !== "Other") {
                                                finalValues[key] = null;
                                            }
                                            if (key === 'purpose' && values[key] !== "ILIA") {
                                                setPolicyStatusDisabled(false);
                                            }
                                        } else {
                                            finalValues[key] = values[key] ? Math.abs(numeral(values[key]).value()) : 0;
                                            console.info("fv", finalValues[key], values[key]);
                                        }
                                        //update date field
                                        if(key === "birthDay" || key == "birthMonth" || key == "birthYear") {
                                            if ((values["age"] < minAge || values["age"] > 65) && values["birthDay"] && values["birthDay"] && values["birthYear"]) {
                                                finalValues['dob'] = initialValues.birthYear + '-' + values['birthMonth'] + '-' + values['birthDay'];
                                                finalValues['age'] = initialValues.age;
                                            } else if (!values["birthDay"] || !values["birthMonth"] || !values["birthYear"]) {
                                                finalValues['dob'] = null;
                                                finalValues['age'] = null;
                                            } else {
                                                finalValues['dob'] = values['birthYear'] + '-' + values['birthMonth'] + '-' + values['birthDay'];
                                            }
                                        }
                                        if (key === "combinedClientId") {
                                            finalValues['combinedClientId'] = values['participantId'] && values['participantId'] ? values['participantId'] : null;
                                        }
                                        return true;
                                    });
                                    finalValues = {
                                        ...finalValues,
                                        policyArr: policyArr.filter((item) => item),
                                        spouseSharesTrustFees: spouseSharesTrustFee
                                    }
                                    delete finalValues['imo'];
                                    delete finalValues['strategyId'];
                                    delete finalValues['carrierId'];
                                    delete finalValues['offerType'];
                                    delete finalValues['salesRegion'];
                                    delete finalValues['issueDate'];
                                    delete finalValues['policyStatus'];
                                    delete finalValues['strategyName'];
                                    delete finalValues[getFormikField("Primary")];
                                    delete finalValues[getFormikField("Contingent")];
                                    // delete finalValues['healthRating'];
                                    //delete finalValues['thisYearContributionDueDate'];
                                    delete finalValues['spouse'];
                                    delete finalValues['groupNumber'];
                                    // delete finalValues['livingBenefits'];  // remove temporarily


                                    if(values && values["dob"]){
                                        finalValues['dob'] = asDbDateFormat(values["dob"], DATE_FORMAT_DOB_SAVE);
                                        finalValues['age'] = moment().diff(values["dob"], "years");
                                    } else {
                                        finalValues['dob'] = null;
                                        finalValues['age'] = null;
                                    }
                                    finalValues['combinedClientId'] = finalValues['participantId'] ? finalValues['participantId'] : null;
                                    delete finalValues['participantId'];
                                    delete finalValues['birthYear'];
                                    delete finalValues['birthMonth'];
                                    delete finalValues['birthDay'];
                                    delete finalValues['identityType'];

                                    await updateCaseDetails(clientDetail.client.id, finalValues, {});

                                    setIsPolicyDirty(false);
                                    getClientCaseDetails(clientDetail.client.id).then(()=>{});
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 500);
                                }}

                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty
                              }) => (<form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                <FocusError offset={true}/>
                                <Typography variant="body1" className="floatLeft w100">
                                    <strong>Case Details&nbsp;{caseDetails && caseDetails.policyNumber || ""}</strong>
                                    {
                                            (values.policyStatus === "Policy Issued" || values.policyStatus === "Policy Issued (Override)") &&
                                            <Typography className="floatRight" variant="body1">
                                                <strong>
                                                    View Servicing
                                                    <ViewServicing
                                                            carrierId={caseDetails.carrierId}
                                                            clientId={caseDetails.id}
                                                            clientName={caseDetails.user.name}
                                                            stratergyId={caseDetails.strategyId}
                                                    />
                                                </strong>
                                            </Typography>
                                    }
                                </Typography>

                                <CaseDetails values={values} handleChange={handleChange} {...initialValues}
                                         errors={errors}
                                         disabled={!canEdit}
                                         touched={touched} clientId={caseDetails.id} caseDetails={caseDetails} caseProcessorList={caseProcessorList} caseManagerList={caseManagerList}
                                         fetchCaseDetail={fetchCaseDetail} ssnFlag={ssnFlag}
                                         setFieldValue={setFieldValue} handleBlur={handleBlur} setActionItem={setActionItem} setMinAge={setMinAge}
                                         minAge={minAge} policyStatusDisabled={policyStatusDisabled}
                                />
                                <CaseDetailsBeneficiaries />
                                <TrustDetails values={values} handleChange={handleChange} {...initialValues}
                                              errors={errors}
                                              disabled={!canEdit}
                                              touched={touched} spousesSharesTrustFee={spousesSharesTrustFee}
                                              setSpousesSharesTrustFee={setSpousesSharesTrustFee}
                                              setIsPolicyDirty={setIsPolicyDirty} marriageStatus={caseDetails.marriageStatus}/>
                                <AddCombineTrust caseDetails={caseDetails} userLoggedIn={user} values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                                                 handleBlur={handleBlur} errors={errors} touched={touched}
                                />
                                <SettlorDetails />
                                {/*<ClientPayments values={values} handleChange={handleChange} {...initialValues}
                                                errors={errors}
                                                disabled={!canEdit}
                                                touched={touched} caseDetails={caseDetails} />
                                <EmployerPayments values={values} handleChange={handleChange} {...initialValues}
                                                errors={errors}
                                                disabled={!canEdit}
                                                touched={touched} caseDetails={caseDetails} />*/}
                                <BankDetails values={values} handleChange={handleChange} {...initialValues}
                                             errors={errors}
                                             disabled={!canEdit}
                                             touched={touched}/>
                                <BenefitAmount values={values} handleChange={handleChange} {...initialValues}
                                               errors={errors}
                                               disabled={!canEdit}
                                               touched={touched} setFieldValue={setFieldValue}/>
                                <Distributions values={values} handleChange={handleChange} {...initialValues}
                                               errors={errors}
                                               disabled={!canEdit}
                                               touched={touched} setFormError={setFormError}
                                               setDistributionFormError={setDistributionFormError}
                                               setLoanPayoffDateError={setLoanPayoffDateError}
                                />
                                <Grid item xs={12}>
                                    <div className="stickyFooter" id={"stickyFooter"} style={{
                                        display: dirty || isPolicyDirty ? "block" : "none",
                                        transition: dirty || isPolicyDirty ? "height 2s ease-in-out 2s" : "none",
                                    }}>
                                        <Typography className="mb0">Make sure to save your changes.
                                            <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="ml20"
                                                    size="large"
                                                    disabled={(isSubmitting || formError || distributionFormError || loanPayOffDateError ? true : false)}
                                                    ref={( dirty || isPolicyDirty ? caseDetailButtonRef : null )}
                                            >
                                                Save Changes
                                            </Button>
                                        </Typography>
                                    </div>
                                </Grid>
                            </form>)}
                        </Formik>
                        <PolicyBreakDown1
                            policyBreakDown={policyBreakDown}
                            setPolicyFormDirty={setPolicyFormDirty}
                            setRefreshMain={setRefreshMain}
                            fetchCaseDetail={fetchCaseDetail}
                            caseDetails={caseDetails}
                            issueAge={caseDetails && caseDetails.tranche && caseDetails.tranche.id ? caseDetails && caseDetails.issueAge : 0}
                            setPolicyBreakDownExpanded={setPolicyBreakDownExpanded}
                            combineClientObj={combineClientObj}
                            supportingDocuments={clientDetail && clientDetail.client && clientDetail.client.supportingDocuments}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
    )

}
export default CaseDetailTab;