import React, { createContext, useContext } from "react";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../dialog";
import QCPopUpContent from "./Content";
import { Button } from "../../../../formInputs";
import { PolicyContextProvider, usePolicyContext } from "../../../../clientServicing/Payments/PolicyContext";
import EpayRejectPayment from "../../../../EpayRejectPayment";
import { useActions } from "../../../../../actions";
import * as EnrollmentActions from "../../../../../actions/enrollment";
import * as PaymentActions from "../../../../../actions/payment";
import { updateQCActionItem } from "../../../../../actions/payment";
import { epayPaymentRejected, PaymentProcess } from "../../../../../constants";
import {
    epayRejectedDropdown
} from "../../../../../pages/client/Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ActionType } from "../../../../../model";

export interface QCPopUpProps {
    setOpenPopup: Function,
    openPopup: boolean,
    enrollmentId?: string
    client?: any
    refreshList?:Function
}

export const QcPopUpContext = createContext<{
    client?:any,
    enrollmentId?: string,
    setOpenPopup?: Function
    refreshList?:Function,
    setSubmitting?: Function,
}>({})

export default function QCPopUp(props: QCPopUpProps) {
    const {openPopup, setOpenPopup, client, enrollmentId, refreshList} = props;
    const [epayShowRp, epaySetRp] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false)
    const handleClose = () => {
        setOpenPopup(false);
    };

    const useStyles = makeStyles(()=>
    createStyles(
        {
            buttonFormatting:{
                display:"flex",
                gap:"2px"
            }
        }
    )
    )

    const classes = useStyles();

    const dialogProps: GenericDialogProps = {
        actions: (
                <Grid className={classes.buttonFormatting}>

                    <Button
                            label={"Reject Payment"}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                // setOpenPopup(false);
                                epaySetRp(true)
                            }}
                            disabled={submitting}
                            className="btnContainedRed"
                    />
                    <Button
                            label={"Accept"}
                            type="submit"
                            variant="contained"
                            form={"QCEpayForm"}
                            disabled={submitting}
                            // onClick={() => setOpenPopup(false)}
                            color="primary"
                            className="floatLeft floatRightSM mb15"
                    />
                </Grid>
        ),
        "aria-labelledby": "upload_proposal-pop-up-form-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (<PolicyContextProvider clientData={{name: client.user.firstName + " " + client.user.lastName, email: client.user.email, ...client}}>
            <QcPopUpContext.Provider value={{client, enrollmentId, setOpenPopup, refreshList, setSubmitting}}>
                <QCPopUpContent>
                    <PopUp epaySetRp={epaySetRp} epayShowRp={epayShowRp} />
                </QCPopUpContent>
            </QcPopUpContext.Provider>
        </PolicyContextProvider>),
        dividers: true,
        onClose: handleClose,
        open: openPopup,
        title: "QC Payment Info",
    };

    return (
            <>
                {
                        openPopup &&
                        <GenericDialog {...dialogProps} />
                }
            </>
    );
};

const PopUp = (props:{epayShowRp: boolean, epaySetRp :Function }) =>{
    const { epayShowRp, epaySetRp } = props
    const { enrollmentId, setOpenPopup, refreshList, client, setSubmitting } = useContext(QcPopUpContext)
    const enrollmentActions = useActions(EnrollmentActions);
    const {policy, refetchPolicy}: any = usePolicyContext();
    const {removePaymentMethod} = useActions(PaymentActions);
    const {updateQCActionItem} = useActions(PaymentActions);
    const dispatch = useDispatch();
    const {processPayment} = useActions(PaymentActions);
    const updateEnrolmentStatus= async (enrollmentStatus, enrollmentSubStatus) =>{
        return  await enrollmentActions.updateEnrollment({ // need to update status and substatus, because of auto save functionality issue
            id: enrollmentId,
            enrollmentStatus,
            userId: client.userId,
            enrollmentSubStatus: enrollmentSubStatus,
            version: "v2",
        });
    }

    return <EpayRejectPayment
            epayShowRp={epayShowRp} epaySetRp={epaySetRp}
            onSubmit={async (values: any, action)=>{
                const submitting =(value)=>{
                    setSubmitting && setSubmitting(value)
                    action.setSubmitting(value);
                }
                submitting(true)
                    if(policy.paymentMethods && policy.paymentMethods[0].recordKey){
                        const removePaymentMethodPayload = {
                            policyNumber: policy.policyNumber,
                            recordKey: policy.paymentMethods && policy.paymentMethods[0].recordKey,
                        };
                       const response = await removePaymentMethod(removePaymentMethodPayload);
                        if(response.success) {
                            const updateActionItem = await updateQCActionItem({
                                status: 1,
                                type: "reject",
                                userId: client.userId,
                                message: values["message"],
                                reason: rejectIngReason(values["paymentRejectedReason"], values)
                            })
                            const updateE = await updateEnrolmentStatus(3, 2)
                            await processPayment({
                                type: PaymentProcess.epay,
                                userId: client.userId,
                                reason: rejectIngReason(values["paymentRejectedReason"], values)
                            })
                        }
                    }else{
                        const updateActionItem = await updateQCActionItem({
                            status: 1,
                            type: "reject",
                            userId: client.userId,
                            message: values["message"],
                            reason: rejectIngReason(values["paymentRejectedReason"], values)
                        })
                        const updateE = await updateEnrolmentStatus(3, 2)
                        await processPayment({
                            type: PaymentProcess.epay,
                            userId: client.userId,
                            reason: rejectIngReason(values["paymentRejectedReason"], values)
                        })
                    }
                    epaySetRp(false);
                    setOpenPopup && setOpenPopup(false);
                    refreshList && refreshList()
                    submitting(false)
            }}
    />
}

function rejectIngReason (reason: string, values ) {
    const selected = epayRejectedDropdown.find((value, index, obj)=>{
        return  reason == value.value
    });
    // create common for check is other selected or not
    if(selected && selected.value === "other"){
        return values["message"]
    }
    return selected && selected.name
}




