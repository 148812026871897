import React from "react";
import { Label } from "../../../../../components/formInputs";
import { ErrorCommon, TextField, TextFieldCommon, NumberTextField } from "../../../../../components/formikFormInputs";
import { AppBar, Card, CardContent, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Page from "../../../Enrollment/EnrollmentHome/EnrollmentApplication/Beneficiaries/Page";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import Box from "@material-ui/core/Box";
import { useActions } from "../../../../../actions";
import * as clientActions from "../../../../../actions/client";
import moment from "moment";
import { TableView } from "../../../../../components/Common";


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
            <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`scrollable-force-tabpanel-${index}`}
                    aria-labelledby={`scrollable-force-tab-${index}`}
                    {...other}
                    className="w100"
            >
                {value === index && (
                        <Box>
                            {children}
                        </Box>
                )}
            </div>
    );
}

const CaseDetailsBeneficiaries = (props: {}) => {
    let userData = useSelector((state: RootState) => state.clientAccount);
    const [tab, setTab] = React.useState(0)
    function a11yProps(index: any) {
        return {
            id: `scrollable-force-tab-${index}`,
            "aria-controls": `scrollable-force-tabpanel-${index}`,
        };
    }
    const onChange =  (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    }
    return (
            <React.Fragment>
                <Card><CardContent>
                    <AppBar position="static" color="secondary">
                    <Tabs
                            // className="participantsTabs w100"
                            variant="fullWidth"
                            scrollButtons="on"
                            value={tab}
                            onChange={onChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon label tabs example"
                    >
                        <Tab label="Change Made" {...a11yProps(0)} />
                        <Tab label="Change log" value={1} {...a11yProps(1)} />
                    </Tabs>
                    </AppBar>
                <Typography variant="subtitle1" component="strong" className="mb10 mt30 floatLeft w100">Beneficiaries
                    Details</Typography>
                    <Grid container spacing={3} className={"w100"}>
                        <TabPanel value={tab} index={0}>
                            {/* <Grid container spacing={2}> */}
                            {/* <Typography variant="h2" align="left">{"Beneficiaries"}</Typography> */}
                            <Page setNextStep={() => {
                            }} clientDetail={userData} hideUperInputs={true}
                                  showSubmitButton={false} callingFrom={"CaseDetails"} />
                            {/* </Grid> */}
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            {/* <Grid container spacing={2}> */}
                            {/* <Typography variant="h2" align="left">{"Beneficiaries"}</Typography> */}
                            <LogsTable />
                            {/* </Grid> */}
                        </TabPanel>
                    </Grid>
                </CardContent></Card>
            </React.Fragment>
    );

};
export default CaseDetailsBeneficiaries;

const LogsTable = () =>{
    let userData = useSelector((state: RootState) => state.clientAccount);
    const [logs, setLogs] = React.useState<any>([]);
    const { getBeneficiaryLogs } = useActions(clientActions);
    React.useEffect(() => {
        if (userData.client) {
            if(userData.client && userData.client.id){
                fetchLogs(userData.client.id);
            }
        }
    }, [userData.client]); // Dependency added to re-fetch if `userData.client` changes

    const fetchLogs = async (clientId: number) => {
        try {
            const response = await getBeneficiaryLogs({ clientId });
            console.log(response, "===== response")
            setLogs(response || []); // Ensure logs are always an array
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };


    const gridProps = {
        headers: ['Message', 'Date Modified'],
        list: Array.isArray(logs) ? logs.map(({ clientId, type, event, log:message, updatedAt }: any) => {
            return {
                message: <p dangerouslySetInnerHTML={{ __html: JSON.parse(message) }} />,
                updatedAt: moment(updatedAt).format('MM-DD-YYYY'), // Format the date
            };
        }) : [],
    };

    return (
        <TableView {...gridProps} />);
}