import React from "react";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import NumberFormat from "react-number-format";
import TableContainer from "@material-ui/core/TableContainer";
import {usePolicyContext} from "./PolicyContext";
import moment from "moment/moment";
export default function ContributionHistory(props: any) {
    const { policy }: any = usePolicyContext();
    const policyStartDate  = policy && policy.premium && policy.premium.policyStartDate || "";
    const policyStartYear = Number(moment(policyStartDate).format("YYYY"));
    const isEmpContribution =  policy && policy.contributions && policy.contributions.some(e => e.employerContribution);

    return (
            <>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table aria-label="caption table">
                            <TableHead style={{ backgroundColor: "#EDF3F8" }}>
                                <TableRow>
                                    <TableCell align="left">Years</TableCell>
                                    <TableCell align="center">Client Owed</TableCell>
                                    <TableCell align="center">Client Paid</TableCell>
                                    <TableCell align="center">Client Balance Due</TableCell>
                                    {
                                        isEmpContribution &&
                                            <TableCell align="center">Employer Contribution</TableCell>
                                    }
                                    <TableCell align="center">Bank Leverage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    policy &&
                                    policy.contributions &&
                                    policy.contributions.length > 0 ? policy.contributions.map((contribution: any, index: number) => {
                                                const policyYear = policyStartYear + index;
                                                return (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                    scope="row">Year {contribution.policyYear} ({policyYear} - {policyYear + 1})</TableCell>
                                                            <TableCell scope="row" align="center">
                                                                <NumberFormat
                                                                    value={contribution.clientOwed}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                                    decimalSeparator={"."}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <NumberFormat
                                                                    value={contribution.clientPaid}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                                    decimalSeparator={"."}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <NumberFormat
                                                                    value={contribution.clientDue}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                                    decimalSeparator={"."}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            {
                                                                isEmpContribution &&
                                                                    <TableCell align="center">
                                                                        <NumberFormat
                                                                                value={contribution.employerContribution}
                                                                                displayType={"text"}
                                                                                thousandSeparator={true}
                                                                                prefix={"$"}
                                                                                decimalSeparator={"."}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                        />
                                                                    </TableCell>
                                                            }
                                                            <TableCell align="center">
                                                                <NumberFormat
                                                                        value={contribution.bankContribution}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalSeparator={"."}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                );
                                            }) :
                                            <TableRow><TableCell scope="row" colSpan={6}>No Data</TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>
    );
}