import React, { createContext, useContext } from "react";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../dialog";
import { Button } from "../../../../formInputs";
import CheckWire from "./Content";
import { PolicyContextProvider, usePolicyContext } from "../../../../clientServicing/Payments/PolicyContext";
import RejectPayment from "../../../../RejectPayment";
import { QcPopUpContext } from "../QCPopUp/QCPopUp";
import { useActions } from "../../../../../actions";
import * as PaymentActions from "../../../../../actions/payment";
import * as EnrollmentActions from "../../../../../actions/enrollment";
import { processPayment } from "../../../../../actions/payment";
import { PaymentProcess } from "../../../../../constants";
import {
    epayRejectedDropdown, paymentRejectedDropdown,
} from "../../../../../pages/client/Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";
import { Grid } from "@material-ui/core";

export interface CheckWireProps {
    setOpenPopup: Function,
    openPopup: boolean,
    client?: any,
    refreshList?:Function
    enrollmentId?:string
}
export const CheckWireContext = createContext<{
    client?:any,
    enrollmentId?: string,
    setOpenPopup?: Function
    refreshList?:Function,
    setSubmitting?: Function
}>({})

export default function CheckWirePopup(props: CheckWireProps) {
    const {openPopup, setOpenPopup, client, refreshList, enrollmentId} = props;
    const [showRp, setRp] = React.useState(false);
    const {updateCheckWireActionItem} = useActions(PaymentActions);
    const enrollmentActions = useActions(EnrollmentActions);
    const {processPayment} = useActions(PaymentActions);
    const [submitting, setSubmitting] = React.useState(false)
    const handleClose = () => {
        setOpenPopup(false);
    };
    const updateEnrolmentStatus= async (enrollmentStatus, enrollmentSubStatus) =>{
        await enrollmentActions.updateEnrollment({ // need to update status and substatus, because of auto save functionality issue
            id: enrollmentId,
            enrollmentStatus,
            userId: client.userId,
            enrollmentSubStatus: enrollmentSubStatus,
            version: "v2",
        });
    }

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "upload_proposal-pop-up-form-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: ( <><PolicyContextProvider clientData={client}>
            <CheckWireContext.Provider value={{client, enrollmentId, setOpenPopup, refreshList, setSubmitting}} >
                <CheckWire >
                    {showRp && <RejectPopup
                            showRp={showRp}
                            setRp={setRp}
                    />}
                </CheckWire>
            </CheckWireContext.Provider>
        </PolicyContextProvider>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                            form={"CheckWireForm"}
                            label={"Save"}
                            type="submit"
                            disabled={submitting}
                            variant="contained"
                            color="primary"
                            className="floatRight mt20 mb20"
                    />
                    <Button
                            label={"Reject Payment"}
                            type="button"
                            variant="contained"
                            onClick={() => {
                                setRp(true);
                                // setOpenPopup(false);
                            }}
                            disabled={submitting}
                            color="primary"
                            className="btnContainedRed floatRight mr15 mt20 mb20"
                    />
                </Grid>
            </Grid>
            </>),
        dividers: true,
        onClose: handleClose,
        open: openPopup,
        title: "Add Payment",
    };

    return (
            <>
                {
                        openPopup &&
                        <GenericDialog {...dialogProps} />
                }
            </>
    );
};

function rejectIngReason (reason: string, values:any) {
    const selected = paymentRejectedDropdown.find((value, index, obj)=>{
        return  reason == value.value
    });
    if(selected && selected.value === "other"){
        return values["message"]
    }
    return selected && selected.name
}

function RejectPopup({setRp, showRp}:{setRp: Function, showRp :boolean } ){
    const {policy, refetchPolicy}: any = usePolicyContext();
    const enrollmentActions = useActions(EnrollmentActions);
    const {processPayment} = useActions(PaymentActions);
    const {removePaymentMethod} = useActions(PaymentActions);
    const { enrollmentId, setOpenPopup, refreshList, client, setSubmitting } = useContext(CheckWireContext)
    const {updateCheckWireActionItem} = useActions(PaymentActions);
    const updateEnrolmentStatus= async (enrollmentStatus, enrollmentSubStatus) =>{
        await enrollmentActions.updateEnrollment({ // need to update status and substatus, because of auto save functionality issue
            id: enrollmentId,
            enrollmentStatus,
            userId: client.userId,
            enrollmentSubStatus: enrollmentSubStatus,
            version: "v2",
        });
    }
    return <RejectPayment
            onSubmit={async (values: any, actions: any)=>{
                const submitting =(value)=>{
                    setSubmitting && setSubmitting(value)
                    actions.setSubmitting(value);
                }
                submitting(true)
                await updateEnrolmentStatus(3,0);
                await updateCheckWireActionItem({status:1, type:"reject", userId: client.userId, message: "", reason: rejectIngReason(values["paymentRejectedReason"], values)})
                processPayment({type: PaymentProcess.checkWire, userId: client.userId, reason: rejectIngReason(values["paymentRejectedReason"], values) })
                setRp(false);
                setOpenPopup && setOpenPopup(false);
                submitting(false)
                refreshList && refreshList()
            }}
            showRp={showRp}
            setRp={setRp}
    />
}



