
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Typography} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      typography: {
        padding: theme.spacing(2),
        marginBottom: 0,
      },
    }),
  );

export default function  VerticalDotsPopover(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose2 = () => {
          setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      return (
        <>
                <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                        <Popover
                        id={"menu"}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                    >
                            {props.children}
                    </Popover>
                    </>
      )
}