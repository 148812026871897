import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateIcon from "@material-ui/icons/Create";
import { IconButton, Link, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { AccountHeadRow, AccountOrder, AccountSortList } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils } from "../../utils";
import { agentTypeDropDown, PermissionTypes } from "../../constants";
import LinkIcon from "@material-ui/icons/Link";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import GetInviteStatus from "../GetInviteStatus";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { DeleteModelWithNotes, PageTitle } from "../formInputs";
import { Link as RouterLink } from "react-router-dom";
import * as Admin_Actions from "../../actions/admin";
import { can } from "../navigation/AdminNavigation";


const headRows: AccountHeadRow[] = [
  { id: 'sNo', label: 'S.No' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'agentType', label: 'Agent Type' },
  { id: 'agent.agentImos.imo.groupName', label: "IMO's" },
  { id: 'agent.agentImos.agentImoCarriers.carrier.carrierName', label: 'Primary Carrier' },
  { id: 'agent.participants', label: '# of Participants' },
  { id: 'inviteStatus', label: 'Status' },
  { id: 'createdAt', label: 'Date Created' },
  { id: 'lastLogin', label: 'Last Login' },
];
type Props = {
    accountList?: any,
    order: AccountOrder | any,
    orderBy: string | any,
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => void | any;
    handleClickOpen: Function | any,
    basePath?: string | any,
    handleRefresh?: Function | any,
    history?: any,
    handleClickReInvite?: Function,
    handleClickEdit?: Function,
    showList?: boolean,
    rowsPerPage: number,
    page: number,
    customPageTitle?: string
    participantsClickable?: boolean
}
function AgentTable(props: Props) {
  const { accountList, order, orderBy, handleRequestSort, basePath, handleRefresh, rowsPerPage, page, customPageTitle , participantsClickable = true } = props;
  const [inviteTokenId, setInviteTokenId] = React.useState<string>('');
  const accountActions = useActions(AccountActions);
  const auth = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.auth.user);

    const [isDeleteModel, setIsDeleteModel] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [notesValue, setNotesValue] = React.useState("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const adminActions = useActions(Admin_Actions);


    const userType = "USER";
    let sNo: number = page * rowsPerPage;
    const deleteParticipants = async () => {
        setButtonLoader(true);
        await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        refreshTable();
    };

    const setNotes = (value) => {
        setNotesValue(value)
    }

    const deletedUser = (id: string) => {
        let userDetails: any = accountList.rows.filter((val: any) => (id === val.id));
        if(userDetails.length > 0){
            setMessage("Are you sure you want to delete this agent permanently from ilia ?")
            setTitle(userDetails[0]["firstName"] + " " +userDetails[0]["lastName"])
        }
        setIsDeleteModel(id)
    };

    const refreshTable = () =>{
    handleRefresh();
  }

  function valHandler(accountId : string){
    setInviteTokenId(accountId);
    accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard")
  }

    function participantDiv(account: any) {
        if (!participantsClickable) {
            return account["agent.participants"] || 0
        }
        return (<Link
                variant="body2"
                className={'clickableLink'}
                onClick={() => props.history.push((auth.user && (auth.user.role === 3 || auth.user.role === 15))
                        ? `/${basePath}/agent/${account["agent.id"]}/participants/${account.firstName} ${account.lastName}`
                        : `/${basePath}/agent/${account["agent.id"]}/participants/list/${account.firstName} ${account.lastName}`)}
        >
            {account["agent.participants"] || 0}
        </Link>)
    }
  
  const getAgentTypeText = account => {
    const agentType = agentTypeDropDown.find(x => x.value === account["agent.agentType"]);
    return agentType && agentType.text
  }

  return (
    <React.Fragment>
        {
            customPageTitle &&
            <PageTitle title={`${customPageTitle}`}/>
        }
     <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headRows={headRows}
     />
    <TableBody>
    {
      (!accountList.rows || accountList.rows.length === 0)
      && <TableRow>
        <TableCell align="center" colSpan={10}>
          <Typography className="floatLeft">No Agent Accounts</Typography>
        </TableCell>
      </TableRow>
    }
    {accountList.rows && accountList.rows.map((account: any, index:number) =>  {
        return (
          <TableRow hover key={account.id}>
            <TableCell>{++sNo}</TableCell>
            <TableCell padding="default">
              <Link
                component={RouterLink}
                to={'/new-agent/detail/'+ account.id}
              >{account.firstName}</Link>
            </TableCell>
            <TableCell padding="default">{account.lastName}</TableCell>
            <TableCell padding="default">{account.email}</TableCell>
            <TableCell padding="default">{getAgentTypeText(account)}</TableCell>
            <TableCell padding="default">{account["agent.agentImos.imo.groupName"]}</TableCell>
            <TableCell padding="default">{account["agent.agentImos.agentImoCarriers.carrier.carrierName"]}</TableCell>

            <TableCell padding="default">
                {participantDiv(account)}
            </TableCell>
              <GetInviteStatus
                  role={user.role}
                  account={account}
                  refreshTable={()=>refreshTable()}
              />
            <TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
            <TableCellLastLogin lastLogin={account.lastLogin} />
            <TableCell padding="default" align="right" className="w230">
              <Tooltip  title="Edit">
                <IconButton
                  component={RouterLink}
                  color="default"
                  to={'/new-agent/detail/'+ account.id}>
                  <CreateIcon />
                </IconButton>
              </Tooltip >
              {user && user.role !== 3 && user.role !== 15 && account.inviteLink &&
                <Tooltip placement="left-start" title={ (account.id === inviteTokenId)
                    ?  <React.Fragment>Invite Link Has Been <br/>Copied to Your Clipboard</React.Fragment>
                    : "Copy Invite Link"}>
                  <CopyToClipboard text={account.inviteLink}
                                   onCopy={()=>valHandler(account.id)}>
                    <IconButton color="default">
                      <LinkIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              }
              {user && (user.role === 1 || can(user, PermissionTypes.DELETE_USER)) && account["agent.couldBeDelete"] == 1 &&
                <Tooltip title="Delete">
                    <IconButton
                            color="default"
                            onClick={() => deletedUser(account.id || 0)}
                    >
                        <DeleteOutlineIcon className="colorRed"/>
                    </IconButton>
                </Tooltip>
              }
            </TableCell>
          </TableRow>
        )
      })
    }
    </TableBody>
    {
    isDeleteModel ?
        (
            <DeleteModelWithNotes
                open={true}
                title={title}
                content={message}
                handleClose={() => {setIsDeleteModel(""); setNotesValue("")}}
                handleYes={deleteParticipants}
                setNotes={setNotes}
                buttonLoader={buttonLoader}
                notes={notesValue}
            />
        ):null
    }
    </React.Fragment>
  )
}

export default AgentTable;

export function TableCellLastLogin({ lastLogin }) {
  return (
    <TableCell padding="default">
      {lastLogin ? dateUtils.asDbDateFormat(lastLogin) : 'Never Logged In'}
      <br />
      {lastLogin ? dateUtils.timeCalculator(lastLogin) : ""}
    </TableCell>
  )
}

