import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { maskPhoneNumber } from "../../../../utils/commonUtils";

const SystemError = (props: { accountType: string, groupDetails?: any, accountExist?: boolean, callingFrom?:string }) => {
    const { accountType, groupDetails, accountExist, callingFrom } = props;
    const [isGroupLandingPage, setIsGroupLandingPage] = useState<boolean>(false);
    useEffect(() => {
        if (accountType === "GROUP") {
            setIsGroupLandingPage(true);
        }
    }, [accountType]);
    
    const getContent = () => {
        let content = " Your information isn’t showing in our system. Please contact your agent or info@niwcorp.com for assistance.";
        if (isGroupLandingPage && groupDetails && groupDetails.id && !accountExist) {
            content = `Your information is not showing in our system.  Please contact ${groupDetails.companyName} at ${maskPhoneNumber(groupDetails.phone)} or ${groupDetails.email}`;
            if(callingFrom == "landingPage"){
                content = "Your information is not showing in our system: Please reach out to your account manager Jon Ketola via email at jonathan.ketola@aon.com or call at (952)807-0759."
            }
        } else if (isGroupLandingPage && accountExist) {
            content = "This account/email is already registered";
        }
        return content;
    };
    return (
            <Grid item xs={12}>
                <Typography variant="caption" color="error" className="f12">
                    {getContent()}
                </Typography>
            </Grid>
    );
};

export default SystemError;