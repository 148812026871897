import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent/CardContent";
import adminActions from "../images/enrollment_admin_actions.svg";
import ResetEnrollmentModal from "./modals/ResetEnrollmentModal";
import RequestClientDocModal from "./modals/RequestClientDocModal";
import {useActions} from "../../../../actions";
import * as ApplicationAction from "../../../../actions/application";
import * as EnrolmentActions from "../../../../actions/enrollment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import * as CompanyActions from "../../../../actions/company";
import {DisableParticipantNoteModal} from "../../../../components/group";
import {CardWithImage} from "./ClientSnapshot";
import {CreateTicketDialog} from "../../../ticket/CreateTicket";
import * as TicketActions from "../../../../actions/ticket";
import * as ClientActions from "../../../../actions/client";
import {useSelector} from "react-redux";
import {RootState} from "../../../../reducers";
import {DeleteModelWithNotes} from "../../../../components/formInputs";
import {deleteUser} from "../../../../utils/deletePopUpUtils";
import * as AccountActions from "../../../../actions/account";
import {history} from "../../../../configureStore";
import {Grid} from "@material-ui/core";
import CaseAlert from "./CaseAlert/CaseAlert";
import ResetProposalModal from "./modals/ResetProposalModal";
import {isAllianzApp} from "../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));
const AdminActions = (props: { clientDetail: any, setActionItem: Function, role: number | undefined, adminPermission: boolean, onTicketCreated: Function, offerType: string }) => {
	const classes = useStyles();
	const loggedInUser: any = useSelector((state: RootState) => state.auth);
	let permissions: any = loggedInUser && loggedInUser.user && loggedInUser.user.permissions && loggedInUser.user.permissions.length > 0 ? loggedInUser.user.permissions : [];

	const applicationAction = useActions(ApplicationAction as any);
	const enrollmentActions = useActions(EnrolmentActions),
		companyActions = useActions(CompanyActions),
		clientActions = useActions(ClientActions),
		[openDialog, setOpenDialog] = React.useState(false);

	let adminEnrollmentDisabledPermission: boolean = false;
	const { onTicketCreated, offerType, clientDetail, setActionItem, role, adminPermission = false } = props;
	const user: any = clientDetail;
	const participant: any = clientDetail.participant;
    const policyNumber: number = clientDetail && clientDetail.client && clientDetail.client.policyNumber;
	const clientId = (clientDetail && clientDetail.client && clientDetail.client.id) || null;
    const enrollmentStatus = clientDetail && clientDetail.enrollment && Number(`${clientDetail.enrollment.enrollmentStatus}.${clientDetail.enrollment.enrollmentSubStatus}`) || "";
	const [title, setTitle] = React.useState<string>("");
	const [message, setMessage] = React.useState<string>("");
	const [notesValue, setNotesValue] = React.useState("");
	const [buttonLoader, setButtonLoader] = React.useState(false);
	const [modalBox, setModalBox] = React.useState(false);
    const [proposalModalBox, setProposalModalBox] = React.useState(false);
    const [checkPaymentStatus, setCheckPaymentStatus] = React.useState(false);
	const [requestDoc, setRequestDoc] = React.useState(false);
	const [action, setAction] = React.useState("");
	const [clientSigned, setClientSigned] = React.useState(false);
	const [disableParticipant, setDisableParticipant] = React.useState(true);
	const [isCreateTicketOpen, setIsCreateTicketOpen] = React.useState(false);
	const [isDeleteAccount, setIsDeleteAccount] = React.useState(false);
	const [isDeleteModel, setIsDeleteModel] = React.useState<string | number>("");
	const accountActions = useActions(AccountActions);
	const userType = "USER";
    const actionTypeFilter = (type: string, status: number) => {
        return clientDetail && clientDetail.actionItems && clientDetail.actionItems.length > 0 && clientDetail.actionItems.filter((actionItem) => {
            return actionItem.actionType === type;
        }).filter((actionItem) => {
            return actionItem.status === status;
        });
    };

    const checkUploadProposal = actionTypeFilter("UPLOAD_PROPOSAL", 1);
    const checkApproveDocusign = actionTypeFilter("APPROVE_DOCUSIGN", 1);
    const paymentStatus = actionTypeFilter("MAKE_PAYMENT_RECEIVED", 1);

	const setNotes = (value) => {
		setNotesValue(value);
	};

	const handleModal = (val: boolean) => {
		setTitle((user && user.firstName) + " " + (user && user.lastName));
		setMessage("Reset Client Enrollment");
		setModalBox(val);
	};

	const handleActivateDeactivateParticipant = async (val: any) => {
		if (val) {
			if (disableParticipant) {
				setOpenDialog(true);
			} else {
				await companyActions.updateGroupParticipant(val.id, undefined);
				setDisableParticipant(true);
				handleRefresh();
			}
		}
	};

	const handleParticipantNote = async (participant: any, note: string) => {
		if (participant) {
			setOpenDialog(false);
			await companyActions.updateGroupParticipant(participant.id, note);
			setDisableParticipant(false);
			handleRefresh();
		}
	};

	const handleResetEnrollment = async () => {
		const userId = (user && user.client && user.client.userId) || "";
		setButtonLoader(true);
        if (isAllianzApp(offerType)) {
            await applicationAction.resetEnrollmentApplication({desc: notesValue, userId});
        } else {
            await enrollmentActions.resetEnrollment({userId, notesValue});
        }
		if (clientId) {
			await clientActions.getClient(clientId);
		}
		setButtonLoader(false);
		setModalBox(false);
		setNotesValue("");
		handleRefresh();
	};

    const handleResetProposal = async () => {
        const userId = (user && user.client && user.client.userId) || "";
        setButtonLoader(true);
        await applicationAction.resetProposal({desc: notesValue, userId});
        if (clientId) {
            await clientActions.getClient(clientId);
        }
        setButtonLoader(false);
        setProposalModalBox(false);
        setNotesValue('');
        handleRefresh();
    };

    const handleResetPayment = async () => {
        setButtonLoader(true);
        const clientPolicyId = clientDetail && clientDetail.client && clientDetail.client.clientPolicies && clientDetail.client.clientPolicies[0] && clientDetail.client.clientPolicies[0].id;
        const resetPaymentPayload = { clientPolicyId, clientId };
        await applicationAction.resetPayment(resetPaymentPayload);
        if (clientId) {
            await clientActions.getClient(clientId);
        }
        setButtonLoader(false);
        setProposalModalBox(false);
        setNotesValue('');
        handleRefresh();
    };

    const handleUpdateAlert = async () => {
        if (clientId) {
            await clientActions.getClient(clientId);
        }
    };

	const handleRefresh = () => {
		setActionItem();
	};

	const deleteAdmin = () => {
		deleteUser(setButtonLoader, accountActions, isDeleteModel, userType, notesValue, setIsDeleteModel, setNotesValue, refreshTable).then(() => {
		});
	};

	const refreshTable = () => {
		history.push("/accounts");
	};

	const handleChange = (event: { target: { value } }) => {
		setAction(event.target.value);
		switch (event.target.value) {
			case 1:
				setMessage("Request Client Documents");
				setRequestDoc(true);
				break;
			case 2:
				handleModal(true);
				break;
			case 3:
				handleActivateDeactivateParticipant(participant);
				break;
			case 4:
				setIsCreateTicketOpen(true);
				break;
			case 5:
				setIsDeleteAccount(true);
				const userId = (user && user.client && user.client.userId) || "";
				setIsDeleteModel(userId);
				break;
            case 6:
                setTitle((user && user.firstName) + " " + (user && user.lastName));
                setMessage("Reset Client Application");
                setModalBox(true);
                break;
            case 7:
                setMessage("Reset Client Proposal");
                setProposalModalBox(true);
                break;
		}
	};

	React.useEffect(() => {
		if (clientDetail && clientDetail.enrollment) {
			setClientSigned(clientDetail.enrollment.clientSigned);
            setCheckPaymentStatus(enrollmentStatus >= 4.0)
		}
		if (clientDetail && clientDetail.participant) {
			setDisableParticipant(clientDetail.participant.status !== -2);
		}
	}, [clientDetail]);

	const isIliaAdmin = role === 2;
	const hasMoreOptions = role === 1 && user && user.inviteStatus > 2;
	const ticketActions = useActions(TicketActions);
	const createTicket = async ({ detail, files }) => {
		const clientId = user && user.client && user.client.id;

		await ticketActions.createTicket({
			clientId,
			detail,
			files,
		});
		setIsCreateTicketOpen(false);
		onTicketCreated();
	};
	if (permissions && permissions.length > 0) {
		for (let i = 0; i <= permissions.length; i++) {
			if ((permissions[i] && permissions[i].permissionType && permissions[i].permissionType.name) == "ilia Account management") {
				adminEnrollmentDisabledPermission = permissions[i].isEnabled;
			}
		}
	}

	return (
		<>
			{(user && user.enrollment) &&
				<CardAdminActions>
					<CardContent className="floatLeft w100">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								{user &&
									<><FormControl variant="outlined" className={classes.formControl}>
										<InputLabel id="demo-simple-select-outlined-label">Select an
											Action </InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={action}
											onChange={handleChange}
											label="Select an Action"
										>

											{hasMoreOptions && clientSigned &&
												<MenuItem value={1}>Request Client Documents</MenuItem>}
                                            {
                                                    (hasMoreOptions || (adminPermission && isIliaAdmin && adminEnrollmentDisabledPermission)) && !isAllianzApp(offerType) &&
                                                    <MenuItem value={2}>Reset Enrollment</MenuItem>
                                            }
                                            {
                                                    (hasMoreOptions || (adminPermission && isIliaAdmin && adminEnrollmentDisabledPermission)) && isAllianzApp(offerType) && !(checkApproveDocusign && checkApproveDocusign.length > 0) &&
                                                    <MenuItem value={6}>Reset Application</MenuItem>
                                            }
                                            {
                                                    (hasMoreOptions || (adminPermission && isIliaAdmin && adminEnrollmentDisabledPermission)) && isAllianzApp(offerType) && (checkUploadProposal && checkUploadProposal.length > 0) && !(paymentStatus && paymentStatus.length > 0) &&
                                                    <MenuItem value={7}>Reset Proposal</MenuItem>
                                            }
											<MenuItem value={3}>{disableParticipant ? "Disable" : "Activate"}</MenuItem>
											<MenuItem value={4}>Create ticket</MenuItem>
											{(role === 1) && <MenuItem value={5}>Delete Account</MenuItem>}
										</Select>
									</FormControl>
										<span className="hLine mb0" />
									</>
								}
							</Grid>
							<CaseAlert policyNumber={policyNumber} handleUpdateAlert={handleUpdateAlert}/>
						</Grid>
					</CardContent>
				</CardAdminActions>
			}
			{
				modalBox && <ResetEnrollmentModal
					open={true}
					title={title}
					content={message}
					handleClose={() => {
						handleModal(false);
						setNotesValue("");
						setAction("");
					}}
					handleYes={handleResetEnrollment}
					setNotes={setNotes}
					buttonLoader={buttonLoader}
					notes={notesValue}
				/>
			}
            {
                proposalModalBox && <ResetProposalModal
                        open={true}
                        content={message}
                        handleClose={() => {
                            setProposalModalBox(false);
                            setAction("");
                        }}
                        handleYes={checkPaymentStatus ? handleResetPayment : handleResetProposal}
                        buttonLoader={buttonLoader}
                        paymentStatus={checkPaymentStatus}
                />
            }
			{
				requestDoc && <RequestClientDocModal
					user={user}
					open={true}
					content={message}
					clientDetail={clientDetail}
					handleClose={() => {
						setRequestDoc(false);
						setAction("");
					}}
				/>
			}
			{
				openDialog &&
				<DisableParticipantNoteModal
					isDisableParticipant={disableParticipant} s
					onClose={() => setOpenDialog(false)}
					onSave={(values: any) => {
						handleParticipantNote(participant, values.note);
					}}
				/>
			}
			<CreateTicketDialog
				isDialogOpen={isCreateTicketOpen}
				onCancel={() => {
					setIsCreateTicketOpen(false);
				}}
				onOk={createTicket}
			/>
			<DeleteModelWithNotes
				open={isDeleteAccount}
				title={(user && user.firstName) + " " + (user && user.lastName)}
				content={"Are you sure you want to delete this user permanently from ilia ?"}
				handleClose={() => {
					setIsDeleteModel("");
					setIsDeleteAccount(false);
					setNotesValue("");
				}}
				handleYes={deleteAdmin}
				setNotes={setNotes}
				notes={notesValue}
				buttonLoader={buttonLoader}
			/>
		</>
	);
};


export const CardAdminActions = ({ children }) => (
	<CardWithImage src={adminActions} title="Admin Actions">
		{children}
	</CardWithImage>
);

export default AdminActions;