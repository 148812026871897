import React from "react";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../components/dialog";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";

/**
 * Confirmation Proposal Popup for Agent/Client
 */
type Props = {
    setPopupStatus: Function,
    setModification: Function,
    setModificationVal: any,
    setApprove: any,
    setApproveVal: any,
    popupStatus: boolean,
    text1: string,
    text2: string,
    changeModification: string,
    proceed: string
}
const ConfirmationProposalPopUp = (props: Props) => {
    const {
        setPopupStatus,
        setModification,
        setModificationVal,
        setApprove,
        setApproveVal,
        popupStatus,
        text1,
        text2,
        changeModification,
        proceed
    } = props;
    const buttonOneAlign = proceed === "Approve" ? "floatRight" : "floatLeft";
    const buttonTwoAlign = proceed === "Approve" ? "floatLeft" : "floatRight";
    const popupProps: GenericDialogProps = {
        "aria-labelledby": "dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <IconButton aria-label="close" style={{ position: "absolute", top: 3, right: 0 }}
                                onClick={() => setPopupStatus(false)}>
                        <Close />
                    </IconButton>
                    <Typography variant="body1" className="mt15 mb15">{text1}</Typography>
                    <Typography variant="body1" className="mb15">{text2}</Typography>
                    <Button size="small" variant="outlined" color="primary" className={`${buttonOneAlign} mt20 mb15`}
                            onClick={() => setModification(setModificationVal)}>{changeModification}</Button>
                    <Button size="small" variant="contained" color="primary" className={`${buttonTwoAlign} mt20 mb15`}
                            onClick={() => setApprove(setApproveVal)}>{proceed}</Button>
                </>
        ),
        dividers: false,
        onClose: () => setPopupStatus(false),
        open: popupStatus,
        title: "",
    };

    return (
            <>
                <GenericDialog {...popupProps} />
            </>
    );
};
export default ConfirmationProposalPopUp;