import React, { useState } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
    ErrorCommon, TextField,
    TextFieldCommon
} from "../components/formikFormInputs";
import { Card, CardContent, Grid, Typography, Button, Link } from "@material-ui/core";
import { useActions } from "../actions";
import * as AccountActions from "../actions/account";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Label, PageTitle } from "./formInputs";
import logo from "../styles/images/logo-ilia.svg";
import logo2 from "../styles/images/login.png";
import * as VerifyActions from "../actions/verify";
import SystemError from "./client/landingPage/v2/SystemError";
const useStyles = makeStyles((theme) =>
        createStyles({
            regWrap: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle",
                height: "100%",
                width: "100%",
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                        width: "auto",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            regCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                padding: "1%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    background: "#ffffff !important",
                    width: "100%",
                    margin: "0 15px",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 16px 0",
                },
            },
            regForm: {
                padding: "20px 15px 30px",
                float: "left",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    padding: "30px 0 15px",
                },
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },
            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
            mt15: {
                marginTop: 15,
            },
        }),
);

const GroupRegister = (props:any) => {

    const [activeStep, setActiveStep] = useState(0);
    const accountActions = useActions(AccountActions);
    const classes = useStyles();
    let searchParams: string = props && props.location.search;
    const UrlClass = new URLSearchParams(searchParams);
    const GroupId = UrlClass.get("groupLink");
    const [groupDetail, setGroupDetail] = React.useState<any>({})
    const verifyActions = useActions(VerifyActions);
    const [accountExist, setAccountExist] = React.useState<boolean>(false);
    const [systemError, setSystemError] = React.useState<boolean>(false);


    // Form Submission
    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        if (activeStep == 0) {
            // Verify user info
            let result: any = await verifyActions.checkNewInvitationAuth({
                authType: "Email",
                accountType: "GROUP",
                lastName :values.lastName,
                email: values.email,
                companyId: (groupDetail && groupDetail.id) || 0,
                clientListId: (groupDetail && groupDetail.id) || 0,
            });
            if (result && result.payload && result.payload.statusCode >= 400) {
                if (result.payload.message === "Account is already register") {
                    setAccountExist(true);
                } else {
                    setAccountExist(false);
                }
                setSystemError(true);
                return false;
            }
            if (result && result.payload) {
                setSystemError(false);
                setAccountExist(false);
                setActiveStep(1);
            }
        } else {
            // Final registration
          let  result = await verifyActions.checkAuthAndCreatePassword({
                password: values.password,
                confirmPassword: values.confirmPassword,
                authType: "Email",
                accountType: "GROUP",
                lastName: values.lastName,
                email: values.email,
                companyId: (groupDetail && groupDetail.id) || 0,
                groupDetail: groupDetail,
                fromLandingPage: 1
            });
            if (result && result.payload && result.payload.statusCode >= 400) {
                setSubmitting(false)
                return false;
            }
            if (result && result.payload) {
                setTimeout(()=>{
                    window.location.href = '/dashboard';
                },500);

            }
        }
        setSubmitting(false);
    };

    React.useEffect( () => {
        const getGroup = async () => {
            const response = await accountActions.getGroup(GroupId);
            const resp:any = response.data;
            setGroupDetail(resp)
            // setLoading(false);
            // setLinkedExpired(!!(resp && resp.linkExpired))
        }
        getGroup();
    }, []);
    
    // Validation Schemas
    const validationSchemas = [
        Yup.object({
            lastName: Yup.string().required("Last Name is required"),
            email: Yup.string().email("Invalid email").required("Email is required"),
        }),
        Yup.object({
            password: Yup.string()
                    .min(8, "Must be at least 8 characters")
                    .matches(/[a-z]/, "Must contain at least one lowercase letter")
                    .matches(/[A-Z]/, "Must contain at least one uppercase letter")
                    .matches(/[0-9]/, "Must contain at least one number")
                    .matches(/[@$!%*?&#]/, "Must contain at least one special character")
                    .required("Password is required"),
            confirmPassword: Yup.string()
                    .oneOf([Yup.ref("password")], "Passwords must match")
                    .required("Confirm Password is required"),
        }),
    ];

    return (
            <React.Fragment>
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/layout.css`} />
                <div className={classes.regWrap}>
                    <PageTitle title="Create Your Account" />
                    <Card className={classes.regCard}>
                        <CardContent className={classes.muiCardContent}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                    <img width="260" alt="logo" src={logo} />
                                    {
                                            systemError && <SystemError accountType={"GROUP"} groupDetails={groupDetail} accountExist={accountExist} callingFrom="landingPage"/>
                                    }
                                    <Formik
                                            initialValues={{
                                                lastName: "",
                                                email: "",
                                                password: "",
                                                confirmPassword: "",
                                            }}
                                            validationSchema={validationSchemas[activeStep]}
                                            onSubmit={handleSubmit}
                                    >
                                        {({ errors, values, touched, isSubmitting, handleChange }) => (
                                                <Form activeStep={activeStep} setActiveStep={setActiveStep} />
                                        )}
                                    </Formik>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                    <Typography variant="h3" align="right"
                                                className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}> Register </Typography>
                                    <img className={classes.floatRight + " " + classes.imgResponsive} src={logo2}
                                         alt="register" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </React.Fragment>
    );
};

export default GroupRegister;


const Form = ({activeStep, setActiveStep}: {activeStep:number, setActiveStep:Function}) => {

    const [loading, setLoading] = useState(false);
    // const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const { errors, values, touched, isSubmitting, handleChange, handleSubmit } = useFormikContext();

    // Form Steps
    const steps = [
        {
            buttonText: "CONTINUE",
            fields: [
                {
                    label: <Label label="Last Name" required={true} />,
                    name: "lastName",
                    id: "lastName",
                    component: TextField,
                    type: "text",
                    autoComplete: "off",
                },
                {
                    label: <Label label="Email" required={true} />,
                    name: "email",
                    id: "email",
                    component: TextField,
                    type: "text",
                    autoComplete: "off",
                },
            ],
            titleText: "Create Your Account",
            subTitleText: "",
        },
        {
            buttonText: "Submit",
            fields: [
                {
                    label: <Label label="Password" required={true} />,
                    name: "password",
                    placeholder: "Password",
                    id: "password",
                    component: TextField,
                    type: "password",
                    autoComplete: "new-password",
                }, {
                    label: <Label label="Confirm Password" required={true} />,
                    name: "confirmPassword",
                    placeholder: "confirmPassword",
                    id: "confirmPassword",
                    component: TextField,
                    type: "password",
                    autoComplete: "off",
                },
            ],
            titleText: "Set Your Password",
            subTitleText: "Create a strong password for security.",
        },
    ];


    return (
            <form onSubmit={handleSubmit} className={classes.regForm + " regFormcss " + classes.margin0} autoComplete="off">
                <Typography variant="subtitle1" color="primary" className="f22 mb15">
                    {steps[activeStep].titleText}
                </Typography>

                <Grid container spacing={2}>
                    {(steps[activeStep].fields as any).map((field, index) => (
                            <Grid item xs={12} key={index}>
                                <TextFieldCommon
                                        {...field}
                                        values={values}
                                        onChange={handleChange}
                                />
                                <ErrorCommon name={field.name} errors={errors} touched={touched} />
                            </Grid>
                    ))}
                    
                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="submit-button"
                                fullWidth
                                disabled={isSubmitting}
                        >
                            {loading ? "Processing..." : steps[activeStep].buttonText}
                        </Button>
                    </Grid>
                    {activeStep === 1 && (
                            <Grid item xs={12} style={{ position:"relative"}}>
                                <Link className="float-left" onClick={() => setActiveStep(0)}>
                                    Back
                                </Link>
                            </Grid>
                    )}
                    {/* Login & Contact */}
                    <Grid item xs={12} className="text-center">
                        <Typography variant="body2">
                            Already have an account?{" "}
                            <Link color="primary" href="/login">
                                Log in
                            </Link>
                            
                        </Typography>
                    </Grid>
                </Grid>
            </form>

    );
};