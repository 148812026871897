import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableWrap from "../../../../../components/TableWrap/TableWrap";
import {usePolicyContext} from "../../../../../components/clientServicing/Payments/PolicyContext";
import ViewDocument from "../modals/ViewDocument";
import {useActions} from "../../../../../actions";
import * as PaymentActions from "../../../../../actions/payment";
import { ClientDocumentType } from "../../../../../constants";
import * as AccountActions from "../../../../../actions/account";
import { dateUtils } from "../../../../../utils";
import * as Aws from "../../../../../actions/aws";


const useStyles = makeStyles({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    pd10: {
        padding: 10
    },
    mb20: {
        marginBottom: 20
    },
    mt20: {
        marginTop: 20
    }
});

export default function PaymentDocuments(props: any) {
    const EPAY_FRONT: string = "Epay Check Front";
    // const EPAY_BACK: string = "Epay Check Back";
    const {getViewSignedUrl} = useActions(PaymentActions);
    const classes = useStyles();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const loading = false;
    const {policy}: any = usePolicyContext();
    const [policyNumber, setPolicyNumber] = React.useState("");
    const [documentTitle, setDocumentTitle] = React.useState("");
    const [openDocument, setOpenDocument] = React.useState(false);
    const [documentUrl, setDocumentUrl] = React.useState("");
    const [fileType, setFileType] = React.useState("");
    const [documentToView, setDocumentToView] = React.useState<object>({});

    const handleClose = () => {
        setOpenDocument(false);
    };
    const handleDocument = async (fileName: string, title: string) => {
        const file = fileName.split(".");
        const fileType = file[file.length - 1].toUpperCase();
        const payload = {
            fileName: fileName,
            policyNumber: policyNumber,
        };
        const response: any = await getViewSignedUrl(payload);
        const signedUrl = atob(response.preSignedURL);
        setDocumentTitle(title);
        setDocumentUrl(signedUrl);
        setFileType(fileType);
        setOpenDocument(true);
    };

    const downloadDocument = async (fileName: string) => {
        fetch(documentUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };


    useEffect(() => {
        if (policy && policy.policyNumber) {
            console.log("Payment Methods", policy.paymentMethods);
            setPolicyNumber(policy.policyNumber)
            setPaymentMethods(policy.paymentMethods);
        }
    }, [policy]);
    return (
        <React.Fragment>
            <Typography variant="subtitle1" component="strong" className="mb20 mt30 floatLeft w100">
                Payment Documents
            </Typography>
            <TableWrap>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Bank Name</TableCell>
                        <TableCell align="left" className={classes.tableHead}>{EPAY_FRONT}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        !loading && paymentMethods && paymentMethods.length > 0 &&
                        paymentMethods.map((method: any) => {
                            return (
                                <TableRow key={Math.random()}>
                                    <TableCell component="td" scope="row">{method.bankName}</TableCell>
                                    <TableCell component="td" scope="row">
                                        <a className="colorBlue" onClick={() => {
                                            handleDocument(method.files[0], EPAY_FRONT).then(() => {});
                                            setDocumentToView(method.files[0]);
                                        }}>Epay Check Front</a></TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </TableWrap>
            <ViewDocument handleNotes={handleDocument}
                          handleClose={handleClose}
                          openDocument={openDocument}
                          documentUrl={documentUrl}
                          fileType={fileType}
                          title={documentTitle}
                          documentToView={documentToView}
                          downloadDocument={downloadDocument}
            />

        </React.Fragment>);
}


export const SupportingDocumentTable = ({clientDetail, onView} : {clientDetail?:any, onView?:Function}) =>{
    const accountActions = useActions(AccountActions);
    const [secureFilePath, setSecureFilePath] = React.useState();
    const client = clientDetail && clientDetail.client
    const awsActions = useActions(Aws);
    const [fileExt, setFileExt ] =  React.useState();
    const [popUpImage, setPopUpImage] = React.useState(false);
    const [sDocs, setSDocs] = React.useState<any>([])
    const classes = useStyles();

    React.useEffect(()=>{
        (async ()=>{
            if(client.userId) {
                const docs = await accountActions.getClientDocumentByTypes(client.userId, [ClientDocumentType.SUPPORTING_DOCUMENTS], {

                });
                setSDocs(docs && docs.rows)
            }
        })()
    },[client.userId])

   return  (<TableWrap>
        <TableHead>
            <TableRow>
                <TableCell align="left" className={classes.tableHead}>Doc Type</TableCell>
                <TableCell align="left" className={classes.tableHead}>Date Modified</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {sDocs && sDocs.map((doc: any) => {
                return (
                        <TableRow key={Math.random()}>
                            <TableCell component="td" scope="row">
                                <a className="colorBlue" onClick={() => {
                                    if(doc.path){
                                        let path = doc.path.split(".");
                                        doc.fileType = path[path.length - 1].toUpperCase();
                                    }
                                    onView && onView(doc)
                                }}>{doc.name}</a></TableCell>
                            <TableCell component="td" scope="row">
                                {dateUtils.asDbDateFormat(doc.updatedAt)}
                            </TableCell>

                        </TableRow>
                );
            })}
        </TableBody>
    </TableWrap>)
}