import * as React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import {
    AccountBox as AccountBoxIcon,
    DateRange as DateRangeIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Notifications,
    People as PeopleIcon,
    Restore,
} from "@material-ui/icons";

import LinkIcon from "@material-ui/icons/Link";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {history} from "../../configureStore";
import {AccountTypeByName, PermissionTypes} from "../../constants";
import BadgeOverlap from "../BadgeOverlap";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import CommentIcon from "@material-ui/icons/Comment";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PlacesIcon from '@material-ui/icons/Place';

export const isAdmin = user => user.role === 1 || user.role === 2;

export const can = (user, permissionTypeId) => {
	if(!isAdmin(user))
		return true;

	if(user.role === 1)
		return true;

	const permissions = user.permissions || [];
	const permission = permissions.find(x => x.permissionType.id === permissionTypeId);
	return user.role === 2 && permission && permission.isEnabled;
}

function AdminNavigation(props: any) {
	const { handleNavClick } = props;
	const [active, setActive] = React.useState<string>(
		history.location.pathname
	);
	const { user } = useSelector((state: RootState) => state.auth);
	
	let isShowNotifyTab = false;
	if (
		user &&
		user.role &&
		(user.role === AccountTypeByName.master_admin ||
			user.role === AccountTypeByName.admin)
	) {
		isShowNotifyTab = true;
	}
	let isPendingItemsCount: boolean = !!(user && user.pendingItemsCount && user.pendingItemsCount > 0);
	let isLeadsCount: boolean = !!(user && user.leadsCount && user.leadsCount > 0);
    let isAgentApproval: boolean = !!(user && user.agentApproval && user.agentApproval > 0);
    let isCurrentTicketCount:boolean = !!(user && user.currentTicketCount && user.currentTicketCount > 0);
	return (
		<React.Fragment>
			<List className="mt20 mb20">
				<ListItem
					className={
						active === "/accounts" || active === "accounts"
							? "active"
							: ""
					}
					button
					onClick={() => {
						setActive("/accounts");
						history.push("/accounts");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary={
						user.role === 2 && !can(user, PermissionTypes.ACCOUNT_MANAGEMENT)? 
							"ilia Account Management": "Account Management"} />
				</ListItem>
				{
					can(user, PermissionTypes.CARRIER_REQUESTS) &&
					<ListItem
						className={active === "/carrierRequests" ? "active" : ""}
						button
						onClick={() => {
							setActive("/carrierRequests");
							history.push("/carrierRequests");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<PlaylistAddCheckIcon />
						</ListItemIcon>
						<ListItemText primary="Carrier Requests" />
					</ListItem>
				}
				{isShowNotifyTab && (
					<ListItem
						className={
							active === "/admin/notifications" ? "active" : ""
						}
						button
						onClick={() => {
							setActive("/admin/notifications");
							history.push("/admin/notifications");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<Notifications />
						</ListItemIcon>
						<ListItemText primary="Notifications" />
					</ListItem>
				)}
				{
					can(user, PermissionTypes.TRANCHE_MANAGEMENT) &&
					<ListItem
						className={active === "/tranche" ? "active" : ""}
						button
						onClick={() => {
							setActive("/tranche");
							history.push("/tranche");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<LocalAtmIcon />
						</ListItemIcon>
						<ListItemText primary="Tranche Management" />
					</ListItem>
				}
				<ListItem
					className={active === "/document" ? "active" : ""}
					button
					onClick={() => {
						setActive("/document");
						history.push("/document");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<AddToQueueIcon />
					</ListItemIcon>
					<ListItemText primary="Document Management" />
				</ListItem>
				{
					can(user, PermissionTypes.ESTIMATOR_MANAGEMENT) &&
					<ListItem
						className={active === "/estimator" ? "active" : ""}
						button
						onClick={() => {
							setActive("/estimator");
							history.push("/estimator");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<AddToQueueIcon />
						</ListItemIcon>
						<ListItemText primary="Estimator Management" />
					</ListItem>
				}
				{
					can(user, PermissionTypes.STRATEGY_MANAGEMENT) &&
					<ListItem
						className={active === "/strategies" ? "active" : ""}
						button
						onClick={() => {
							setActive("/strategies");
							history.push("/strategies");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<FormatListBulletedIcon />
						</ListItemIcon>
						<ListItemText primary="ilia Product Type Management" />
					</ListItem>
				}
				{can(user, PermissionTypes.NIW_ACTION_ITEMS) && (
					<ListItem
						className={active === "/action-items" ? "active" : ""}
						button
						onClick={() => {
							setActive("/action-items");
							history.push("/action-items");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<ReceiptIcon />
						</ListItemIcon>
						<ListItemText primary="NIW Action Items" />
						{isPendingItemsCount && <BadgeOverlap />}
					</ListItem>
				)}
                <ListItem
                        className={
                            ""
                        }
                        button
                        onClick={() => {
                            window.open("https://www.niwmarketing.com/", "_blank")
                            return;
                        }}
                >
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Events & Webinars" />
                </ListItem>
				<ListItem
					className={
						active === "/admins/ILIATrainingPage" ? "active" : ""
					}
					button
					onClick={() => {
						setActive("/admins/ILIATrainingPage");
						history.push("/admins/ILIATrainingPage");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<AccountBoxIcon />
					</ListItemIcon>
					<ListItemText primary="ilia Training" />
				</ListItem>
				<ListItem
					className={
						active === "/admins/marketing-material" ? "active" : ""
					}
					button
					onClick={() => {
						setActive("/admins/marketing-material");
						history.push("/admins/marketing-material");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<OndemandVideoIcon />
					</ListItemIcon>
					<ListItemText primary="Marketing Materials" />
				</ListItem>
				<ListItem
					className={active === "/admins/news-social" ? "active" : ""}
					button
					onClick={() => {
						setActive("/admins/news-social");
						history.push("/admins/news-social");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<CommentIcon />
					</ListItemIcon>
					<ListItemText primary="News & Social Posts" />
				</ListItem>
				{
					can(user, PermissionTypes.ADMIN_ACTIVITY_LOG) &&
					<ListItem
						className={
							active === "/admins/activitylogs" ? "active" : ""
						}
						button
						onClick={() => {
							setActive("/admins/activitylogs");
							history.push("/admins/activitylogs");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<Restore />
						</ListItemIcon>
						<ListItemText primary="Admin Activity logs" />
					</ListItem>
				}
				{
					can(user, PermissionTypes.LEAD_MANAGEMENT) &&
					<ListItem
						className={active === "/lead-management" ? "active" : ""}
						button
						onClick={() => {
							setActive("/lead-management");
							history.push("/lead-management");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<ContactPhoneIcon />
						</ListItemIcon>
						<ListItemText primary="Lead Management" />
						{isLeadsCount && <BadgeOverlap />}
					</ListItem>
				}
				{
					can(user, PermissionTypes.LINK_REPORTING) &&
					<ListItem
						className={
							active === "/agent-link-reporting" ? "active" : ""
						}
						button
						onClick={() => {
							setActive("/agent-link-reporting");
							history.push("/agent-link-reporting");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<LinkIcon />
						</ListItemIcon>
						<ListItemText primary="Link Reporting" />
					</ListItem>
				}

				<ListItem
					className={active === "/marketing-emails" ? "active" : ""}
					button
					onClick={() => {
						setActive("/marketing-emails");
						history.push("/marketing-emails");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<ContactPhoneIcon />
					</ListItemIcon>
					<ListItemText primary="Marketing Emails" />
				</ListItem>
				{
					can(user, PermissionTypes.TICKETS) &&
					<ListItem
						className={active === "/ilia-tickets" ? "active" : ""}
						button
						onClick={() => {
							setActive("/ilia-tickets");
							history.push("/ilia-tickets");
							handleNavClick();
						}}
					>
						<ListItemIcon>
							<ConfirmationNumberIcon />
						</ListItemIcon>
						<ListItemText primary="ilia Tickets" />
                        {isCurrentTicketCount && <BadgeOverlap />}
					</ListItem>
				}
				<ListItem
					className={active === "/approved-agents" ? "active" : ""}
					button
					onClick={() => {
						setActive("/approved-agents");
						history.push("/approved-agents");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<LockOpenIcon />
					</ListItemIcon>
					<ListItemText primary="Agent Approvals" />
                    {isAgentApproval && <BadgeOverlap />}
				</ListItem>
				
			     {can(user, PermissionTypes.POWER_BI_REPORTS) &&
		        	<ListItem
					className={active === "/reports" ? "active" : ""}
					button
					onClick={() => {
						setActive("/reports");
						history.push("/reports");
						handleNavClick();
					}}
                >
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reporting" />
                </ListItem>
                }
                <ListItem
                        className={active === "/tranche-dates" ? "active" : ""}
                        button
                        onClick={() => {
                            setActive("/tranche-dates");
                            history.push("/tranche-dates");
                            handleNavClick();
                        }}
                >
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tranche Dates" />
                </ListItem>
                <ListItem
                        className={active === "/import-ghost-client" ? "active" : ""}
                        button
                        onClick={() => {
                            setActive("/import-ghost-client");
                            history.push("/import-ghost-client");
                            handleNavClick();
                        }}
                >
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary="Import Ghost Client" />
                </ListItem>
			</List>
		</React.Fragment>
	);
}

export default AdminNavigation;
