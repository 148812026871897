import React from "react";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import {
    DatePicker,
    ErrorCommon,
    NumberTextField,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../../../../components/formikFormInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Yup from "yup";
import Physician from "./Physician";
import { PhysicianAndMedicalDetailsStyle } from "./medicalStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";
import { useActions } from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";
import moment from "moment";
import PhysicianAndMedicalDetailsV1Form from "./PhysicianAndMedicalDetailsV1Form";
import numeral from "numeral";
import { isLongForm } from "../Utilities";
import { isFunc } from "../../../../../../utils/commonUtils";

let heightInches: number[] = [];

for (let inch = 0; inch <= 11; inch++) {
    heightInches.push(inch);
}

const fatherOptionArr = [{
    label: "Father Alive",
    value: "fatherAlive",
}, {
    label: "Father Deceased ",
    value: "fatherDeceased",
}, {
    label: "Unknown",
    value: "unknown",
}]

const motherOptionArr = [{
    label: "Mother Alive",
    value: "motherAlive",
}, {
    label: "Mother Deceased",
    value: "motherDeceased",
}, {
    label: "Unknown",
    value: "unknown",
}]

const  PhysicianAndMedicalDetailsV1 = (props: any) => {
    const { updateClientFields, getNationalLifeDetails } = useActions(AccountActions);
    const {
        isFromClientDetail,
        clientDetailData,
        setIsError,
        offerType,
        setIsSubmittedQuestions,
        setErrorInPhysicianAndMedicalDetails,
    } = props;
    let userData = useSelector((state: RootState) => state.profile);
    let user = clientDetailData || userData;
    const classes = PhysicianAndMedicalDetailsStyle();

    const [initiateSaveMe, setInitiateSaveMe] = React.useState(Math.random());
    const [physiciansCount, setPhysiciansCount] = React.useState(0);
    const [nationalLifeId, setNationalLifeId] = React.useState(0);
    const [fatherArr, setFatherArr] = React.useState<any[]>(fatherOptionArr);
    const [motherArr, setMotherArr] = React.useState<any[]>(motherOptionArr);
    const physicalAndMedicalDetails: any = React.useRef<any>(null);
    let clientDetail = (user && user.client && user.client) || undefined;

    const isClientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) || false;
    const loggedInUser: any = useSelector((state: RootState) => state.auth);
    let role: number | null = (loggedInUser && loggedInUser.user && loggedInUser.user.role) || null;
    let initialValues = {
        weight: clientDetail.weight || "",
        heightFt: clientDetail.heightFt || "",
        heightInc: clientDetail.heightInc >= 0 ? clientDetail.heightInc : "",
        isDrugTakenLast5Yr: clientDetail && clientDetail.isDrugTakenLast5Yr === null ? "" : clientDetail.isDrugTakenLast5Yr === 1 ? "Yes" : "No",
        productType: clientDetail.productType || "",
        frequency: clientDetail.frequency || "",
        dateOfLastVisit: clientDetail.dateOfLastVisit ? moment(clientDetail.dateOfLastVisit).format("MM/DD/yyyy") : moment().format("MM/DD/yyyy"),
        father: clientDetail.father || "",
        fatherAge: clientDetail.fatherAge || "",
        fatherAgeAtDeath: clientDetail.fatherAgeAtDeath || "",
        fatherCauseOfDeath: clientDetail.fatherCauseOfDeath || "",
        mother: clientDetail.mother || "",
        motherAge: clientDetail.motherAge || "",
        motherAgeAtDeath: clientDetail.motherAgeAtDeath || "",
        motherCauseOfDeath: clientDetail.motherCauseOfDeath || "",
        noOfSiblings: (clientDetail.siblings && clientDetail.siblings.length) || 0,
        sibling: clientDetail.siblings && clientDetail.siblings.length > 0 ?
                clientDetail.siblings.map(sibling => ({
                    isAlive: sibling.isAlive,
                    age: sibling.age,
                    ageAtDeath: sibling.ageAtDeath,
                    causeOfDeath: sibling.causeOfDeath,
                })) : [],

        weightLoss: clientDetail && clientDetail.weightLoss === null ? null : clientDetail && clientDetail.weightLoss ? 1 : 0,
        healthcare: clientDetail && clientDetail.healthcare === null ? null : clientDetail && clientDetail.healthcare ? 1 : 0,
        heartDisease: clientDetail && clientDetail.heartDisease === null ? null : clientDetail && clientDetail.heartDisease ? 1 : 0,
        weightChangeQuantity: (clientDetail && clientDetail.weightChangeQuantity) || null,
        changedWeightAmount: (clientDetail && clientDetail.changedWeightAmount) || "",
        reasonWeightChange: (clientDetail && clientDetail.reasonWeightChange) || null,
        reasonWeightChangeOther: (clientDetail && clientDetail.reasonWeightChangeOther) || "",
        doctorKnowWeightChange: (clientDetail && clientDetail.doctorKnowWeightChange) || null,
    };

    React.useEffect(() => {
        if (physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.dirty &&
                Object.keys(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.errors).length === 0
        ) {
            saveMe(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.values);
        }
    }, [
        physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.values,
        physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.errors,
        initiateSaveMe
    ]);

    React.useEffect(() => {
        const getNatioanlLifeCarrierDetails = async () => {
            const nationalLifeDate = await getNationalLifeDetails();
            if (nationalLifeDate && nationalLifeDate.nationalLife) {
                setNationalLifeId(nationalLifeDate.nationalLife.id);
            }
        };
        getNatioanlLifeCarrierDetails().then(() => {
        });
    }, []);

    React.useEffect(() => {
        if (nationalLifeId && clientDetail && clientDetail.carrier && (clientDetail.carrier.id === nationalLifeId)) {
            const fatherLifeArr = fatherOptionArr.filter((item: { label: string, value: string }) => {
                if (item.value !== "unknown")
                    return item;
            });
            const motherLifeArr = motherOptionArr.filter((item: { label: string, value: string }) => {
                if (item.value !== "unknown")
                    return item;
            });
            setFatherArr(fatherLifeArr);
            setMotherArr(motherLifeArr);
        }
    }, [nationalLifeId, clientDetail && clientDetail.carrier]);

    /*React.useEffect(()=>{ // to do
        let deBounceFn;
        if(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.dirty) {
            const errArray: any = Object.values(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.errors);
            const errKeyVal = errArray.find((err, key) => err.toLowerCase() !== "required field");
            if (!(!!errKeyVal)) {
                if(typeof setIsSubmittedQuestions === 'function'){
                    setIsSubmittedQuestions(false); // bcz autosave, is submitting should be false
                }
                deBounceFn = setTimeout(() => {
                    if (typeof saveMe === 'function') {
                        saveMe(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.values)
                    }
                }, 1000);
            }
        }
        return () => {
            if(deBounceFn) {
                clearTimeout(deBounceFn)
            }
        };

    }, [
            physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.values
        ]
    )*/

    React.useEffect(() => {
        if(Object.keys(physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.errors).length > 0){
            if(typeof setErrorInPhysicianAndMedicalDetails === 'function'){
                setErrorInPhysicianAndMedicalDetails(true);
            }
        } else {
            if(typeof setErrorInPhysicianAndMedicalDetails === 'function') {
                setErrorInPhysicianAndMedicalDetails(false);
            }
        }
    }, [physicalAndMedicalDetails && physicalAndMedicalDetails.current && physicalAndMedicalDetails.current.errors])
    const fieldObj: any = [
        {
            id: "weight",
            name: "weight",
            helperFieldText: "Numbers only in pounds.",
            label: <Label label={"Weight"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            filedType: "number",
            component: TextField,
            variant: "outlined",
        },
        {
            id: "heightFt",
            name: "heightFt",
            helperFieldText: "",
            label: <Label label={"Height (feet)"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            component: SelectField,
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(height => ({
                        id: height,
                        key: height,
                        value: height,
                        name: height,
                    }),
            ),
            onSelectChange: (val: number) => {
                let stickyFooter: any = document.getElementById("stickyFooter");
                if (stickyFooter) {
                    stickyFooter.style.display = "block";
                }
                if (setInitiateSaveMe && isFunc(setInitiateSaveMe)) {
                    setInitiateSaveMe(Math.random());
                }
            },
            variant: "outlined",
        },
        {
            id: "heightInc",
            name: "heightInc",
            helperFieldText: "",
            label: <Label label={"Height (inches)"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            component: SelectField,
            options: heightInches.map(height => ({
                        id: height,
                        key: height,
                        value: height,
                        name: height,
                    }),
            ),
            onSelectChange: (val: number) => {
                let stickyFooter: any = document.getElementById("stickyFooter");
                if (stickyFooter) {
                    stickyFooter.style.display = "block";
                }
                if (setInitiateSaveMe && isFunc(setInitiateSaveMe)) {
                    setInitiateSaveMe(Math.random());
                }
            },
            variant: "outlined",
        },
    ];

    const fieldObj1: any = [
        {
            id: "isDrugTakenLast5Yr",
            name: "isDrugTakenLast5Yr",
            disabled: isClientSigned,
        },
    ];

    const fieldObj2: any = [
        {
            id: "productType",
            name: "productType",
            helperFieldText: "",
            label: <Label label={"Product Type"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
        },
        {
            id: "frequency",
            name: "frequency",
            helperFieldText: "",
            label: <Label label={"Frequency"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
        },
        {
            id: "dateOfLastVisit",
            name: "dateOfLastVisit",
            helperFieldText: "",
            label: <Label label={"Date Last Used"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: DatePicker,
            disableFuture: true,
            cb: (date: string) => {
                let stickyFooter: any = document.getElementById("stickyFooter");
                if (stickyFooter) {
                    stickyFooter.style.display = "block";
                }
            },
            variant: "outlined",
        },
    ];

    const fieldObj3: any = [
        {
            id: "father",
            name: "father",
            helperFieldText: "",
            isRadio: true,
            disabled: isClientSigned || (role && role <= 4),
            isRequired: !isFromClientDetail,
            options: fatherArr,
        },
    ];

    const fieldObj4: any = [
        {
            id: "fatherAge",
            name: "fatherAge",
            helperFieldText: "",
            label: <Label label={"Father Age"} required={!isFromClientDetail} />,
            placeholder: "Father Age",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            className: "",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
    ];

    const fieldObj5: any = [
        {
            id: "fatherAgeAtDeath",
            name: "fatherAgeAtDeath",
            helperFieldText: "",
            label: <Label label={"Father Age at Death"} required={!isFromClientDetail} />,
            placeholder: "Father Age at Death",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            className: "",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
        {
            id: "fatherCauseOfDeath",
            name: "fatherCauseOfDeath",
            helperFieldText: "",
            label: <Label label={"Father Cause Of Death"} required={!isFromClientDetail} />,
            placeholder: "Father Cause Of Death",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            className: "",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (val: string) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error;
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
    ];

    const fieldObj6: any = [
        {
            id: "mother",
            name: "mother",
            helperFieldText: "",
            isRadio: true,
            disabled: isClientSigned || (role && role <= 4),
            isRequired: !isFromClientDetail,
            options: motherArr,
        },
    ];

    const fieldObj7: any = [
        {
            id: "motherAge",
            name: "motherAge",
            helperFieldText: "",
            label: <Label label={"Mother Age"} required={!isFromClientDetail} />,
            placeholder: "Mother Age",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (value && val > 130) {
                    error = "Age should not greater than 130";
                    return error;
                }
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
    ];

    const fieldObj8: any = [
        {
            id: "motherAgeAtDeath",
            name: "motherAgeAtDeath",
            helperFieldText: "",
            label: <Label label={"Mother Age at Death"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            placeholder: "Mother Age at Death",
            disabled: isClientSigned || (role && role <= 4),
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (value && val > 130) {
                    error = "Age should not greater than 130";
                    return error;
                }
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
        {
            id: "motherCauseOfDeath",
            name: "motherCauseOfDeath",
            helperFieldText: "",
            label: <Label label={"Mother Cause Of Death"} required={!isFromClientDetail} />,
            placeholder: "Mother Cause Of Death",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned || (role && role <= 4),
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (val: string) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error;
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
    ];

    const fieldObj9: any = [
        {
            id: "weightLoss",
            name: "weightLoss",
            disabled: isClientSigned,
            isRequired: !isFromClientDetail,
        },
    ];

    const fieldObj10: any = [
        {
            id: "weightChangeQuantity",
            name: "weightChangeQuantity",
            disabled: isClientSigned,
            isRequired: !isFromClientDetail,
        },
    ];

    const fieldObj11: any = [
        {
            id: "changedWeightAmount",
            name: "changedWeightAmount",
            helperFieldText: "",
            label: <Label label={"Amount"} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: NumberTextField,
            variant: "outlined",
            fullWidth: false,
            thousandSeparator: false,
        },
    ];

    const fieldObj12: any = [
        {
            id: "reasonWeightChange",
            name: "reasonWeightChange",
            disabled: isClientSigned,
            isRequired: !isFromClientDetail,
        },
    ];
    const fieldObj13: any = [
        {
            id: "reasonWeightChangeOther",
            name: "reasonWeightChangeOther",
            helperFieldText: "",
            label: <Label label={"Please explain"} required={!isFromClientDetail} />,
            placeholder: "Please explain",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
        },
    ];
    const fieldObj14: any = [
        {
            id: "doctorKnowWeightChange",
            name: "doctorKnowWeightChange",
            disabled: isClientSigned,
            isRequired: !isFromClientDetail,
        },
    ];

    const fieldObj15: any = [
        {
            id: "noOfSiblings",
            name: "noOfSiblings",
            helperFieldText: "",
            label: <Label label={"Number of Siblings"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            component: SelectField,
            options: (isLongForm(offerType) ? [0, 1, 2, 3] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).map(height => ({
                        id: height,
                        key: height,
                        value: height + " Siblings",
                        name: height,
                    }),
            ),
            onSelectChange: (val: number) => {
            },
            getValuesOnChange: (val: number, setFieldValue: Function, values: any) => {
                let siblingDetail: any = [];
                for (let i = 1; i <= Number(val); i++) {
                    siblingDetail.push({
                        isAlive: (values["sibling"].length > 0 && values["sibling"][i - 1] && values["sibling"][i - 1].isAlive) || "1",
                        age: (values["sibling"].length > 0 && values["sibling"][i - 1] && values["sibling"][i - 1].age) || "",
                        ageAtDeath: (values["sibling"].length > 0 && values["sibling"][i - 1] && values["sibling"][i - 1].ageAtDeath) || "",
                        causeOfDeath: (values["sibling"].length > 0 && values["sibling"][i - 1] && values["sibling"][i - 1].causeOfDeath) || "",
                    });
                }
                setFieldValue("sibling", siblingDetail);
            },
            variant: "outlined",
        },
    ];

    const fieldObj16: any = [
        {
            id: "isAlive",
            name: "isAlive",
            helperFieldText: "",
            isRadio: true,
            disabled: isClientSigned,
            isRequired: !isFromClientDetail,
            options: [{
                label: "Sibling Alive",
                value: "1",
            },
                {
                    label: "Sibling Deceased",
                    value: "2",
                },
                {
                    label: "Unknown",
                    value: "3",
                }],
        },
    ];

    const fieldObj17: any = [
        {
            id: "age",
            name: "age",
            helperFieldText: "",
            label: <Label label={"Sibling Age"} required={!isFromClientDetail} />,
            placeholder: "Sibling Age",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (value && val > 130) {
                    error = "Age should not greater than 130";
                    return error;
                }
            },
        },
    ];

    const fieldObj18: any = [
        {
            id: "ageAtDeath",
            name: "ageAtDeath",
            helperFieldText: "",
            label: <Label label={"Sibling Age at Death"} required={!isFromClientDetail} />,
            placeholder: "Sibling Age at Death",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (value: number) => {
                if (isFromClientDetail) {
                    return "";
                }
                let val: number = Number(value) || 0;
                let error;
                if (value && val === 0) {
                    error = "Age should be a number and greater than 0";
                    return error;
                }
                if (value && val > 130) {
                    error = "Age should not greater than 130";
                    return error;
                }
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
        {
            id: "causeOfDeath",
            name: "causeOfDeath",
            helperFieldText: "",
            label: <Label label={"Sibling Cause Of Death"} required={!isFromClientDetail} />,
            placeholder: "Sibling Cause Of Death",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            fullWidth: false,
            validate: (val: string) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error;
                if (!val) {
                    error = "Required Field";
                }
                return error;
            },
        },
    ];

    /* Validations of fields set */
    let keysValidation = {};

    let allFields: any = fieldObj;
    allFields = allFields.concat(fieldObj1).concat(fieldObj2).concat(fieldObj3).concat(fieldObj4).concat(fieldObj5).concat(fieldObj9);
    allFields = allFields.concat(fieldObj6).concat(fieldObj10).concat(fieldObj11).concat(fieldObj12).concat(fieldObj13);
    // .concat(fieldObj7).concat(fieldObj8).concat(fieldObj9); Due to financial Section disabled
    if (offerType !== "full") {
        allFields = allFields.concat(fieldObj15);
    }
    if (offerType === "gi") {
        allFields = [...fieldObj1, ...fieldObj2];
    }
    if (isFromClientDetail) {
        allFields = [];
    }

    const fmErrMessage:string = "Age should be a number and greater than 0";
    allFields.forEach((field) => {
        if (field.name === "productType" || field.name === "frequency" || field.name === "dateOfLastVisit") {
            keysValidation[field.name] = Yup.string().when("isDrugTakenLast5Yr", {
                is: "Yes",
                then: Yup.string().trim().required("Required Field"),
            });
        } else if (field.name === "fatherAge") {
            keysValidation[field.name] = Yup.number().when("father", {
                is: "fatherAlive",
                then: Yup.number().typeError(fmErrMessage).min(1).max(130, "Age should not greater than 130").required("Required Field"),
            });
        } else if (field.name === "fatherAgeAtDeath" || field.name === "fatherCauseOfDeath") {
            keysValidation[field.name] = Yup.string().when("father", {
                is: "fatherDeceased",
                then: Yup.string().trim().required("Required Field"),
            });
            if (field.name === "fatherAgeAtDeath") {
                keysValidation[field.name] = Yup.number().when("father", {
                    is: "fatherDeceased",
                    then: Yup.number().typeError(fmErrMessage).min(1).max(130, "Age should not greater than 130").required("Required Field"),
                });
            }
        } else if (field.name === "motherAge") {
            keysValidation[field.name] = Yup.number().when("mother", {
                is: "motherAlive",
                then: Yup.number().typeError(fmErrMessage).min(1).max(130, "Age should not greater than 130").required("Required Field"),
            });
        } else if (field.name === "motherAgeAtDeath" || field.name === "motherCauseOfDeath") {
            keysValidation[field.name] = Yup.string().when("mother", {
                is: "motherDeceased",
                then: Yup.string().required("Required Field"),
            });
            if (field.name === "motherAgeAtDeath") {
                keysValidation[field.name] = Yup.number().when("mother", {
                    is: "motherDeceased",
                    then: Yup.number().typeError(fmErrMessage).min(1).max(130, "Age Should be bellow 130").required("Required Field"),
                });
            }
        } else if (
                field.name === "changedWeightAmount" ||
                field.name === "weightChangeQuantity" ||
                field.name === "reasonWeightChange" ||
                field.name === "reasonWeightChangeOther" ||
                field.name === "doctorKnowWeightChange"
        ) {
            if (field.name === "reasonWeightChangeOther") {
                keysValidation[field.name] = Yup.string().when("reasonWeightChange", {
                    is: "other",
                    then: Yup.string().required("Required Field"),
                }).nullable();
            }
            /*if(field.name === "weightChangeQuantity" && isLongForm(offerType)) {
                keysValidation[field.name] = Yup.string().required("Required Field").nullable();
            }*/
            if(field.name === "weightChangeQuantity" && isLongForm(offerType)) {
                keysValidation[field.name] = Yup.string().when("weightLoss", {
                    is: (wl: number) => {
                        if ((wl && Number(wl)) === 1){
                            return true;
                        }
                        return false;
                    },
                    then: Yup.string().required("Required Field").nullable(),
                    otherwise: Yup.string().nullable(),
                })
            }

            if ((field.name === "changedWeightAmount") && isLongForm(offerType)) {
                keysValidation[field.name] = Yup.number().when(["weightLoss", "weightChangeQuantity"], {
                    is: (wl: number, wcq: string) => {
                        if ((wl && Number(wl)) === 1 && (wcq && wcq.trim()) === "INCREASE_20") {
                            return true
                        }
                        return false
                    },
                    then: Yup.number().typeError("Amount should be valid number and more than 20").required("Required Field").min(21, "Amount should be more than 20"),
                    otherwise: Yup.number().when(["weightLoss", "weightChangeQuantity"], {
                        is: (wl: number, wcq: string) => {
                            if ((wl && Number(wl)) === 1 && (wcq && wcq.trim()) === "DECREASE_20") {
                                return true
                            }
                            return false
                        },
                        then: Yup.number().typeError("Amount should be valid number and more than 20").required("Required Field").min(21, "Amount should be more than 20"),
                        otherwise: Yup.number().when(["weightLoss", "weightChangeQuantity"], {
                            is: (wl: number, wcq: string) => {
                                if ((wl && Number(wl)) === 1 && (wcq && wcq.trim()) === "INCREASE_10") {
                                    return true
                                }
                                return false
                            },
                            then: Yup.number().nullable(),
                            otherwise: Yup.number().when(["weightLoss", "weightChangeQuantity"], {
                                is: (wl: number, wcq: string) => {
                                    if ((wl && Number(wl)) === 1 && (wcq && wcq.trim()) === "DECREASE_10") {
                                        return true
                                    }
                                    return false
                                },
                                then: Yup.number().nullable(),
                                otherwise: Yup.number().when(["weightLoss", "weightChangeQuantity"], {
                                    is: (wl: number, wcq: string) => {
                                        if ((wl && Number(wl)) === 1 && (wcq && wcq.trim()) === "NONE") {
                                            return true
                                        }
                                        return false
                                    },
                                    then: Yup.number().nullable(),
                                    otherwise: Yup.number().nullable()
                                })
                            })
                        })
                    })
                })
            }
        } else if(field.name === "heightInc"){
            if (field.isRequired) {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().typeError("Must be a number").min(0, "Please select min 0").required("Required Field") : Yup.string().trim().typeError("Please select min 0").required("Required Field");
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().typeError("Must be a number").min(0, "Please select min 0") : Yup.string().trim().typeError("Please select min 0");
            }
        } else if (field.name === "weight") {
            if (field.isRequired) {
                keysValidation[field.name] = Yup.string().matches(/^(?!0+$)\d{1,4}(\.\d{1,2})?$/, 'Must be a valid number').required("Required Field").test(field.name, "Required Field" ,(value)=>{
                    return Number(value) > 0
                });
            } else {
                keysValidation[field.name] = Yup.string().matches(/^(?!0+$)\d{1,4}(\.\d{1,2})?$/, 'Must be a valid number').min(1);
            }
        }
        else if (field.name === "weightLoss") {
            if (field.isRequired) {
                keysValidation[field.name] = Yup.string().required("Required Field").nullable();
            } else {
                keysValidation[field.name] = Yup.string().nullable();
            }
        } else {
            if (field.isRequired) {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().typeError("Must be a number").min(1, "Please enter min 1").required("Required Field") : Yup.string().trim().required("Required Field");
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().typeError("Must be a number").min(1, "Please enter min 1") : Yup.string().trim();
            }
        }
    });


    const validationSchema = Yup.object().shape(keysValidation);

    const CommonField = (
            filedProps,
            action: {
                handleChange: Function,
                handleBlur: Function,
                values?: any,
                errors?: any,
                touched?: any,
                dirty?: boolean
            },
    ) => {
        const { handleChange, handleBlur, values, errors, touched, dirty } = action;
        const { name, helperFieldText, label, dollarSign } = filedProps;
        let fieldLabel = label;

        if (name === "changedWeightAmount") {
            fieldLabel = <Label label={`Amount of ${values.weightChangeQuantity.split("_")[0].toLowerCase()}`}
                                required={!isFromClientDetail} />;
        }

        const getHelperText = () => {
            return <span className="f12 textLight">{helperFieldText}</span>;
        };

        return (
                <>
                    <TextFieldCommon
                            {...filedProps}
                            fullWidth={true}
                            label={fieldLabel || ""}
                            name={name}
                            error={errors[name] && touched[name] && errors[name]}
                            values={values}
                            onChange={handleChange}
                            onBlur={(e: any) => {
                                if (setInitiateSaveMe && isFunc(setInitiateSaveMe)) {
                                    setInitiateSaveMe(Math.random());
                                }
                                enableSaveButton({ ...values, [name]: e.target.value });
                                return handleBlur(e);
                            }}
                            disabled={filedProps.disabled ? true : false}
                            InputProps={{
                                startAdornment: dollarSign ? <InputAdornment className="margin0 dollerIcon cursor"
                                                                             position="start">$</InputAdornment> : <></>,
                            }}
                    />
                    {getHelperText()}
                    <ErrorCommon errors={errors} name={name} touched={touched} />

                </>
        );
    };

    const enableSaveButton = (values) => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "block";
        }
    }
    const disableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "none";
        }
    }

    const getSiblingDetail = (noOfSiblings: number, values: any, handleChange: Function, handleBlur: Function, touched: any, errors: any, dirty: any) => {
        let noOfSiblingsArr: number[] = [];
        for (let i = 1; i <= noOfSiblings; i++) {
            noOfSiblingsArr.push(i);
        }
        return noOfSiblingsArr.map((val, index) => (
                <React.Fragment>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="strong"
                                    className="floatLeft mt20 mb10">
                            <Label label={`Sibling ${val}`}
                                   required={!isFromClientDetail} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {fieldObj16.map((field) => {
                            return (
                                    <>
                                        <RadioGroup color="primary"
                                                    className="checkGroup checkGroupEnrollment"
                                                    name={`sibling[${index}].${field.name}`}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        enableSaveButton({
                                                            ...values,
                                                            [`sibling[${index}].${field.name}`]: e.target.value,
                                                        });
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);
                                                    }}
                                                    value={(values.sibling.length > 0 && values.sibling[index] && values.sibling[index][field.name] + "") || "1"}
                                        >
                                            {field.options.map((option: any) => (
                                                    <FormControlLabel
                                                            value={option.value}
                                                            disabled={field.disabled || false}
                                                            control={<Radio
                                                                    color="primary" />}
                                                            label={option.label} />
                                            ))}
                                        </RadioGroup>

                                        {values.sibling.length < 1 || (values.sibling.length > 0 && (!values.sibling[index] || !values.sibling[index][field.name])) && (
                                                <ErrorCommon isMultiLevel={true} errors={"Required Field"}
                                                             name={`sibling[${index}].${field.name}`} />
                                        )}
                                    </>

                            );
                        })
                        }

                    </Grid>

                    <Grid item xs={12} md={8} className="mt5">
                        <Grid container spacing={2}>
                            {(
                                    (values.sibling.length < 1 || (values.sibling.length > 0 && (!values.sibling[index] || !values.sibling[index]["isAlive"])))
                                    ||
                                    (values.sibling.length > 0 && values.sibling[index] && values.sibling[index].isAlive == "1")
                            ) ?
                                    fieldObj17.map((field) => {
                                        return (
                                                <Grid item xs={12} xl={6}>
                                                    {CommonField(
                                                            {
                                                                ...field,
                                                                id: `${field.name}-${index}`,
                                                                name: `sibling[${index}].${field.name}`,
                                                                value: (values.sibling.length > 0 && values.sibling[index] && values.sibling[index][field.name]) || "",
                                                            }, {
                                                                values,
                                                                errors,
                                                                touched,
                                                                handleChange,
                                                                handleBlur,
                                                                dirty,
                                                            },
                                                    )
                                                    }
                                                    {errors.sibling && errors.sibling[index] && errors.sibling[index][field.name] && (
                                                            <ErrorCommon isMultiLevel={true}
                                                                         errors={errors.sibling[index][field.name]}
                                                                         name={`sibling[${index}].${field.name}`} />
                                                    )}
                                                </Grid>
                                        );
                                    })
                                    :
                                    (values.sibling.length > 0 && values.sibling[index] && values.sibling[index].isAlive == "2" && fieldObj18.map((field) => {
                                                return (
                                                        <Grid item xs={12} xl={6}>
                                                            {CommonField(
                                                                    {
                                                                        ...field,
                                                                        id: `${field.name}-${index}`,
                                                                        name: `sibling[${index}].${field.name}`,
                                                                        value: (values.sibling.length > 0 && values.sibling[index] && values.sibling[index][field.name]) || "",
                                                                    }, {
                                                                        values,
                                                                        errors,
                                                                        touched,
                                                                        handleChange,
                                                                        handleBlur,
                                                                        dirty,
                                                                    },
                                                            )
                                                            }
                                                            {errors.sibling && errors.sibling[index] && errors.sibling[index][field.name] && (
                                                                    <ErrorCommon isMultiLevel={true}
                                                                                 errors={errors.sibling[index][field.name]}
                                                                                 name={`sibling[${index}].${field.name}`} />
                                                            )}
                                                        </Grid>
                                                );
                                            })
                                    )
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
        ));
    };

    const physicalAndMedicalPayload = (values: any) => {
        try {
            if (!isFromClientDetail) {
                setIsError(false);
            }
            let { ...payload } = values;
            let updatedData: any = {
                ...payload,
                weight: payload.weight || 0,
                heightInc: payload.heightInc || 0,
                heightFt: payload.heightFt || 0,
                dateOfLastVisit: moment(payload.dateOfLastVisit).format("YYYY-MM-DD"),
                isDrugTakenLast5Yr: payload.isDrugTakenLast5Yr === "Yes" ? 1 : 0,
                fatherAge: payload.fatherAge || 0,
                fatherAgeAtDeath: payload.fatherAgeAtDeath || 0,
                motherAge: payload.motherAge || 0,
                motherAgeAtDeath: payload.motherAgeAtDeath || 0,
                sibling: payload.sibling && payload.sibling.length > 0 ?
                        payload.sibling.map(sibling => ({
                            isAlive: Number(sibling.isAlive) || 0,
                            age: sibling.age || 0,
                            ageAtDeath: sibling.ageAtDeath || 0,
                            causeOfDeath: sibling.causeOfDeath || "",
                        })) : [],
                formType: "MEDICAL_FAMILY_DETAIL",
                weightChangeQuantity: payload.weightChangeQuantity || null,
                reasonWeightChange: payload.reasonWeightChange || null,
                changedWeightAmount: payload.changedWeightAmount ? numeral(payload.changedWeightAmount).value() : null,
            };
            return updatedData;
        } catch (e) {
            return false;
        }
    }

    const saveMe = async (values: any) => {
        let payload: any = {};
        payload = physicalAndMedicalPayload(values);
        setIsSubmittedQuestions(false);
        await updateClientFields(payload, ((user && user.client && user.client.id) || ""), isFromClientDetail);
    }

    return (
            <>
                <PhysicianAndMedicalDetailsV1Form
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        physiciansCount={physiciansCount}
                        isFromClientDetail={isFromClientDetail}
                        offerType={offerType}
                        updateClientFields={updateClientFields}
                        user={user}
                        disableSaveButton={disableSaveButton}
                        setIsError={setIsError}
                        fieldObj={fieldObj}
                        CommonField={CommonField}
                        enableSaveButton={enableSaveButton}
                        fieldObj10={fieldObj10}
                        classes={classes} fieldObj4={fieldObj4} fieldObj5={fieldObj5} fieldObj6={fieldObj6}
                        fieldObj7={fieldObj7} fieldObj8={fieldObj8}
                        fieldObj11={fieldObj11} fieldObj1={fieldObj1} fieldObj2={fieldObj2} fieldObj3={fieldObj3}
                        fieldObj12={fieldObj12}
                        fieldObj13={fieldObj13}
                        fieldObj15={fieldObj15} fieldObj14={fieldObj14}
                        setPhysiciansCount={setPhysiciansCount}
                        getSiblingDetail={getSiblingDetail}
                        nationalLifeId={nationalLifeId}
                        isClientSigned={isClientSigned}
                        physicalAndMedicalDetails={physicalAndMedicalDetails}
                        physicalAndMedicalPayload={physicalAndMedicalPayload}
                        setErrorInPhysicianAndMedicalDetails={setErrorInPhysicianAndMedicalDetails}
                        setInitiateSaveMe={setInitiateSaveMe}
                />
                {
                        isFromClientDetail &&
                        <Physician user={user} nationalLifeId={nationalLifeId}
                                   isFromClientDetail={isFromClientDetail} />
                }
            </>
    );
};

export default PhysicianAndMedicalDetailsV1;