import {useActions} from "./actions";
import * as Actions from "./actions/auth";
import React, {lazy, Suspense} from "react";
import axios, {AxiosError, AxiosResponse} from "axios";
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute";
import {SimpleRoute} from "./components/SimpleRoute";
import {LandingRoute} from "./components/LandingRoute";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import TermofUse from "./pages/TermofUse";
import NotFound from "./pages/NotFound";
import SessionExpireAlertPopup from "./components/formInputs/SessionExpireAlertPopup";
import {LinearProgress} from "@material-ui/core";
import LoginPage from "./pages/LoginPage";
import SkipLoginLanding from "./pages/agent/SkipLoginLanding";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import InvitationPage from "./pages/InvitationPage";
import LandingPage from "./pages/LandingPage";
import EmailUnSubscribe from "./pages/EmailUnSubscribe";
import InvitationLandingPagev2 from "./pages/client/Invitation/v2/LandingEntryPage";
import GroupInvitationPage from "./pages/client/Invitation/Group/GroupInvitationPage";
import ClientListInvitationPage from "./pages/client/Invitation/ClientList/ClientListInvitationPage";
import {default as NewLeadClient} from "./pages/NewLeadClientPage";
import GroupLinkPage from "./pages/GroupLinkPage";
import GroupPage from "./pages/GroupPage";
import {RootState} from "./reducers";
import withRoot from "./withRoot";
import VerifyOtp from "./pages/VerifyOtp";
import {AccountType, PARENT_POSTAL, PermissionTypes, ROLES} from "./constants";
import AgentApplicationThanks from "./pages/client/ClientProfile/AgentApplicationThanks";
import LeadPage from "./pages/lead/LeadPage";
import moment from "moment";
import VerifyGroupAccount from "./pages/VerifyGroupAccount";
import AfterCompleteDocSign from "./pages/AfterCompleteDocuSIgn";
import RegisterPage from "./pages/RegisterPage";
import DocusignComplete from "./pages/clientServicing/DocusignComplete";
import GroupRegisterPage from "./pages/group/GroupRegisterPage";
import EmailVerification from "./pages/clientServicing/EmailVerification";
import ServicingInvitationPage from "./pages/ServicingInvitationPage";


let allRoles: number[] = Object.keys(ROLES).map((role: string) => parseInt(role));

function Routes(props: { leftPanelOpen: boolean, isEstimation: boolean, setProgress: Function }) {
    const authActions = useActions(Actions);
    let isRefreshToken: boolean = false;
    const navigateTo = useHistory();
    let [openRefreshPopup, setOpenRefreshPopup] = React.useState(false);

    React.useEffect(() => {
        axios.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, (error: AxiosError) => {
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                authActions.LogOut();
            }
            return Promise.reject(error);
        });
    }, []);
    const {parentPortal, accessToken, user  } = useSelector((state: RootState) => state.auth);
    const isAuthenticated = !!accessToken;
    let previousURL: string = navigateTo && navigateTo.location && navigateTo.location.pathname;
    let previousURLProps = navigateTo && navigateTo.location && navigateTo.location.search;
    let reUrl: string = "";
    let url: string = "";
    if (previousURL !== "/accounts") {
        reUrl = previousURL
    }
    if (previousURLProps) {
        reUrl = (previousURL + previousURLProps);
    }
    if (previousURL) {
        url = reUrl ? `/login?re=${reUrl}` : `/login`;
    }
    const { leftPanelOpen, isEstimation, setProgress } = props;
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: isAuthenticated ? "/login" : url,
        allowedRoles: [-1],
        user,
        parentPortal
    };

    React.useEffect(() => {
        const checkExpireTime = () => {
            let currentInterval = moment().format("HH:mm");
            let lastLogin = moment(moment(user.lastLogin));
            let toTime = lastLogin.add(85, "minutes");
            if (currentInterval === toTime.format("HH:mm") && !isRefreshToken) {
                isRefreshToken = true;
                setOpenRefreshPopup(true);
            }
            if (currentInterval >= toTime.format("HH:mm")) {
                let expireTime = moment(moment(user.lastLogin)).add(5, "minutes");
                if (moment().format("HH:mm:ss") > expireTime.format("HH:mm:ss")) {
                    setOpenRefreshPopup(false);
                }
            }
        };
        if (user && user.lastLogin) {
            let currentInterval = moment().format("HH:mm");
            let lastLogin = moment(moment(user.lastLogin));
            let toTime = lastLogin.add(85, "minutes");
            if (currentInterval <= toTime.format("HH:mm")) {
                let intervalId: any = setInterval(() => checkExpireTime(), 20000);
                return (() => {
                    clearInterval(intervalId);
                });
            }
        }
    }, [user]);


    const renderPreferredAgent = (_props: any) => {
        return (isAuthenticated ? <Redirect to="/" /> : <SkipLoginLanding {..._props} />);
    };

    let slug: string | undefined = "kaizen";
    if (isAuthenticated && user.client && user.client.strategy) {
        slug = user.client.strategy.slug;
    } else if (isAuthenticated && user.accessType === "client" && user.demoStrategy) {
        slug = user.demoStrategy.slug;
    }

    if (isAuthenticated && (user.role === 3 || user.role === 4)) {
        if (user.touDuration === null || Number(user.touDuration) >= 3) {
            return (
                    <React.Fragment>
                        <Route exact={true} path="/*" component={TermofUse} />
                    </React.Fragment>
            );
        }
    }


    return (
            <Suspense fallback={<LinearProgress />}>
                {openRefreshPopup && <SessionExpireAlertPopup
                        setProgress={setProgress}
                        openRefreshPopup={openRefreshPopup}
                        setOpenRefreshPopup={setOpenRefreshPopup}
                />}
                <Switch>
                    <SimpleRoute
                            /*exact={true}*/
                            path={[
                                "/login",
                                "/login:re",
                            ]}
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <LoginPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path={[
                                "/register"
                            ]}
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <RegisterPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path={[
                                "/register/group"
                            ]}
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <GroupRegisterPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/forgot-password"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <ForgotPasswordPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/servicing/docusign-complete"
                            render={props => <DocusignComplete {...props} />}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/reset-password/:resetPasswordToken"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <ResetPasswordPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/email-unsubscribe/:userId"
                            render={props => <EmailUnSubscribe {...props} />}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/accept/servicing-invitation/:inviteToken"
                            render={props => (<ServicingInvitationPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/accept/invitation/:inviteToken"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <InvitationPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/accept/new-lead-client-invitation/:inviteToken"
                            render={_props => (isAuthenticated ? <Redirect to="/accounts" /> : <NewLeadClient {..._props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <LandingPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/grouplink/:id"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <GroupLinkPage {...props} />)}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/groupwm/:id"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <GroupPage {...props} />)}
                    />

                    <SimpleRoute
                            exact={true}
                            path="/group/:id/:token"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <VerifyOtp {...props} />)}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/verify/account/:token"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <VerifyOtp {...props} />)}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/verify/group/account/:token"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> : <VerifyGroupAccount {...props} />)}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/new-client-detail/thanks"
                            render={props => <AgentApplicationThanks {...props} />}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/after-complete-docsign"
                            render={props => <AfterCompleteDocSign {...props} />}
                    />
                    <SimpleRoute
                            exact={true}
                            path="/email-verification/:token"
                            render={props => <EmailVerification {...props} />}
                    />
                    <LandingRoute
                            exact={true}
                            path="/accept/new-invitation/:inviteToken"
                            render={props => (<InvitationLandingPagev2 {...props} />)}
                    />




                    <LandingRoute
                            exact={true}
                            path="/group/:id"
                            render={props => (isAuthenticated ? <Redirect to="/accounts" /> :
                                    <GroupInvitationPage {...props} />)}
                    />

                    <LandingRoute
                            exact={true} path="/agent/:id" render={props => (<ClientListInvitationPage {...props} />)}
                    />

                    <LandingRoute
                            exact={true}
                            path={["/kaizen", "/agent-link/:guid"]}
                            render={props => (<LeadPage {...props} />)}
                    />

                    <LandingRoute exact={true} path="/agent/preferred/:landingToken" render={renderPreferredAgent} />

                    <main
                            id={"mainWrapDiv"}
                            className={isAuthenticated ? `mainWrap ${isEstimation ? "WrapPD0 onlyEstimation " : ""} ${leftPanelOpen ? "mainWrapResponsive " : ""} ${user && user.accessType === "client" ? "ClientDemo " : ""}` : ""}
                    >
                        <Switch>
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path={["/accounts", "/accounts/:role"]}
                                          component={lazy(() => import("./pages/accounts/AccountPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/carrierRequests"
                                          component={lazy(() => import("./pages/CarrierRequestsPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/admin/notifications"
                                          component={lazy(() => import("./pages/CreateNotificationPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/accounts/:role/add"
                                          component={lazy(() => import("./pages/AddAccountPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={[
                                              "/accounts/:role/edit/:id",
                                              "/imo/:role/edit/:id",
                                              "/client/:role/edit/:id",
                                          ]}
                                          component={lazy(() => import("./pages/EditAccountPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/client",
                                "/client/individual",
                                "/client/groups",
                                "/client/client-list",
                                "/client/participants",
                                "/client/group-link",
                            ]} component={lazy(() => import("./pages/agent/ClientPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Agent]}
                                          path="/agent-groups"
                                          component={lazy(() => import(`./pages/agent/AgentGroupPage`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/client/list/:id"
                                          component={lazy(() => import("./pages/agent/ClientListPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Agent]}
                                          path="/agent-leads"
                                          component={lazy(() => import(`./pages/agent/LeadManagement`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Agent]}
                                          path="/client-enrollment-prep"
                                          component={lazy(() => import(`./pages/agent/ClientEnrollmentPrep`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Agent]}
                                          path="/agent-links"
                                          component={lazy(() => import(`./pages/agent/AgentLinks/MainPage`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Agent]}
                                          path="/agent-dashboard"
                                          component={lazy(() => import(`./pages/agent/dashboard/AgentDashboard`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/lead-management"
                                          component={lazy(() => import(`./pages/LeadManagement/index`))}
                                          permissions={[PermissionTypes.LEAD_MANAGEMENT]}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/agent-link-reporting"
                                          component={lazy(() => import(`./pages/admin/agentReporting/MainPage`))}
                                          permissions={[PermissionTypes.LINK_REPORTING]}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/marketing-emails"
                                          component={lazy(() => import(`./pages/LeadManagement/MarketingEmails`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/ilia-tickets"
                                          component={lazy(() => import(`./pages/ticket/TicketPage`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/approved-agents"
                                          component={lazy(() => import(`./pages/approvedAgents/ApprovedAgentsPage`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/client/add"
                                          component={lazy(() => import("./components/AddClient"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps}
                                          routeType={PARENT_POSTAL}
                                          path="/new-client/detail/:id"
                                          component={lazy(() => import("./pages/client/ClientProfile/ClientProfile"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} path="/new-admin/detail/:id"
                                          component={lazy(() => import("./pages/admin/AdminProfile/AdminProfile"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} path="/new-agent/detail/:id"
                                          component={lazy(() => import("./pages/agent/AgentProfile/AgentProfile"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} path="/new-imo/detail/:id"
                                          component={lazy(() => import("./pages/imo/ImoProfile/ImoProfile"))}
                            />

                            <PrivateRoute
                                    {...defaultProtectedRouteProps} exact={true}
                                    path={[
                                        "/agents/ILIATrainingPage",
                                        "/imos/ILIATrainingPage",
                                        "/admins/ILIATrainingPage",
                                    ]}
                                    component={lazy(() => import("./pages/IliaTraining"))}
                            />

                            <PrivateRoute
                                    {...defaultProtectedRouteProps} exact={true}
                                    path={[
                                        "/agents/training"
                                    ]}
                                    component={lazy(() => import("./pages/agent/AgentTraining"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-link/detail/:id"
                                          component={lazy(() => import("./pages/GroupLinkDetailPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-detail/:id"
                                          component={lazy(() => import("./pages/GroupDetailPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={allRoles}
                                          exact={true}
                                          path="/account"
                                          component={lazy(() => import("./pages/AccountPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path="/profile"
                                          component={lazy(() => import("./pages/ProfilePage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path="/carrier/profile"
                                          component={lazy(() => import("./pages/CarrierProfilePage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path="/bugs-feedback"
                                          component={lazy(() => import("./pages/BugsFeedback"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/accounts/:role/:id/:type/list",
                                "/accounts/:role/:id/:type/list/:agentName",
                            ]} component={lazy(() => import("./pages/accounts/AccountListManagementPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={
                                [
                                    "/accounts/:role/:id/:type/:subId/individual/list",
                                    "/accounts/:role/:id/:type/:subId/groups/list",
                                    "/accounts/:role/:id/:groupName/:type/list",
                                    "/accounts/:role/:id/:type/:subId/groups/:companyId/individual/list",
                                ]} component={lazy(() => import("./pages/accounts/AccountSubListManagementPage"))}
                            />


                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/imo/:role/:id/:type/list"
                                          component={lazy(() => import("./pages/imo/ImoListManagementPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/imos/details"
                                          component={lazy(() => import("./pages/imo/imoDetails"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/aggregator/details"
                                          component={lazy(() => import("./pages/aggregator/AggregatorDetails"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/imos/team-member/add/:imoId"
                                          component={lazy(() => import("./pages/imo/TeamMemberAdd"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/imo",
                                "/imo/agent",
                                "/imo/individual",
                                "/imo/groups",
                                "/imo/participants",
                                "/imo/client-list",
                                "/imo/imo_member",
                            ]} component={lazy(() => import("./pages/imo/ImoPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.IMO]}
                                          path="/imo-dashboard"
                                          component={lazy(() => import(`./pages/imo/dashboard/ImoDashboard`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Aggregator]}
                                          path="/aggregator-dashboard"
                                          component={lazy(() => import(`./pages/aggregator/dashboard/AggregatorDashboard`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/imo/agent/add"
                                          component={lazy(() => import("./pages/AddAgentPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/imo/agent/csvUpload"
                                          component={lazy(() => import("./pages/CsvUploadPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/dashboard"
                              isNormalDashBoard={true}
                              component={lazy(() => import(`./pages/client/ClientDashboard`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,                              
                            ]}
                                          exact={true}
                                          path={["/parentPortal", "/parentPortal/:role"]}
                                          routeType={PARENT_POSTAL}
                              component={lazy(() => import(`./pages/parentPortal/parentPortalMain`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps}
                                          allowedRoles={[AccountType.Individual]}
                                          path="/parentPortal/child/detail/:id"
                                          routeType={PARENT_POSTAL}
                                          component={lazy(() => import("./pages/parentPortal/ChildProfile"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/intro"
                                          component={lazy(() => import(`./pages/client/${slug}/ClientIntro`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/financialconcerns"
                                          component={lazy(() => import(`./pages/client/${slug}/FinancialConcerns`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/howitwork"
                                          component={lazy(() => import(`./pages/client/${slug}/HowItWork`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/faq"
                                          component={lazy(() => import(`./pages/client/${slug}/Faq`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/invite-kids"
                                          component={lazy(() => import(`./pages/client/Kids/KidsInvitationPage`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/leverage"
                                          component={lazy(() => import(`./pages/client/${slug}/Leverage`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/SplitDollar"
                                          component={lazy(() => import(`./pages/client/${slug}/SplitDollar`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/minimumrisk"
                                          component={lazy(() => import(`./pages/client/${slug}/MinimumRisk`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/participants"
                                          component={lazy(() => import(`./pages/client/${slug}/Participants`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/testimonials"
                                          component={lazy(() => import(`./pages/client/${slug}/Testimonial`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/plandetails"
                                          component={lazy(() => import(`./pages/client/${slug}/PlanDetails`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/product"
                                          component={lazy(() => import(`./pages/client/${slug}/Product`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/enrollnow"
                                          component={lazy(() => import(`./pages/client/${slug}/Enroll`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/participant"
                                          component={lazy(() => import("./pages/ParticipantManagementPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/participant/list/:id"
                                          component={lazy(() => import("./pages/ParticipantListManagementPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/new-enrollment"
                                          component={lazy(() => import("./pages/client/Enrollment/EnrollmentSteps"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/new-enrollment/thanks"
                                          component={lazy(() => import("./pages/client/Enrollment/EnrollmentHome/EnrollmentThanks"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/new-enrollment/start"
                                          component={lazy(() => import("./pages/client/Enrollment/EnrollmentSteps"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/grouplink"
                                          component={lazy(() => import("./pages/GroupLinkManagementPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/docusign"
                                          component={lazy(() => import(`./pages/client/${slug}/DocuSign`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/notqualified"
                                          component={lazy(() => import(`./pages/client/${slug}/NotQualified`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={[
                                              "/accounts/group-link/:id/:groupName/participants",
                                              "/client/group-link/:id/participants",
                                          ]}
                                          component={lazy(() => import("./pages/GroupLinkParticipants"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/ComingSoon"
                                          component={lazy(() => import("./pages/ComingSoon"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/accounts/add/group"
                                          component={lazy(() => import("./pages/AddGroup"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/accounts/add/clientlist"
                                          component={lazy(() => import("./pages/AddClientList"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/action-items"
                                          component={lazy(() => import("./pages/ActionItemsPage"))}
                                          permissions={[PermissionTypes.NIW_ACTION_ITEMS]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/tranche"}
                                          component={lazy(() => import("./pages/TrancheManagement"))}
                                          permissions={[PermissionTypes.TRANCHE_MANAGEMENT]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/estimator"}
                                          component={lazy(() => import("./pages/estimator"))}
                                          permissions={[PermissionTypes.ESTIMATOR_MANAGEMENT]}/>
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/document"}
                                          component={lazy(() => import("./pages/document"))}
                                          permissions={[PermissionTypes.ESTIMATOR_MANAGEMENT]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/strategies"
                                          component={lazy(() => import("./pages/StrategiesPage"))}
                                          permissions={[PermissionTypes.STRATEGY_MANAGEMENT]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/strategy/edit/:id"
                                          component={lazy(() => import("./pages/EditStrategyPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-dashboard"
                                          component={lazy(() => import("./pages/group/GroupDashboard"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-settings"
                                          component={lazy(() => import("./pages/group/GroupSettings"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-notes"
                                          component={lazy(() => import("./pages/group/GroupNotes"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-documents"
                                          component={lazy(() => import("./pages/group/GroupDocuments"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-action-items"
                                          component={lazy(() => import("./pages/group/GroupActionItems"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/group-participants"
                                          component={lazy(() => import("./pages/GroupParticipantsList"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={[
                                              "/accounts/:role/edit/:carrierId/products",
                                              "/accounts/:role/edit/:carrierId/offer_types",
                                          ]}
                                          component={lazy(() => import("./pages/CarrierSubCategoryPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/client/groups/:companyId/individual/:companyName",
                                "/client/client-list/:clientListId/participants/list/:clientListName",
                            ]} component={lazy(() => import("./pages/agent/AgentSubListManagementPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/imo/agent/:agentId/participants/:agentName",
                                "/imo/client-list/:clientListId/participants/:clientListName",
                                "/imo/groups/:companyId/participants/:companyName",
                            ]} component={lazy(() => import("./pages/imo/ImoSubListManagementPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/admins/activitylogs"
                                          component={lazy(() => import("./pages/AdminActivityLog"))}
                                          permissions={[PermissionTypes.ADMIN_ACTIVITY_LOG]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/client/estimation-new"
                                          component={lazy(() => import(`./pages/client/${slug}/EstimationPageNew`))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]} path="/client/estimationV1"
                                          component={lazy(() => import(`./pages/client/Estimation/V1/EstimationV1`))}/>
                            {/*<PrivateRoute {...defaultProtectedRouteProps} exact={true}*/}
                            {/*              allowedRoles={[AccountType.Individual, AccountType.Multilife]} path="/client/estimation/quick"*/}
                            {/*              component={lazy(() => import(`./pages/client/Estimation/V1/QuickMode`))}/>*/}
                            {/*<PrivateRoute {...defaultProtectedRouteProps} exact={true}*/}
                            {/*              allowedRoles={[AccountType.Individual, AccountType.Multilife]} path="/client/estimation/custom"*/}
                            {/*              component={lazy(() => import(`./pages/client/Estimation/V1/CustomDesign`))}/>*/}
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]} path="/client/estimation"
                                          component={lazy(() => import(`./pages/client/Estimation/V1/QuickCustomDesign`))}/>
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          path="/testimonials-aon"
                                          component={lazy(() => import(`./pages/client/${slug}/Lsp/Testimonial`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={[
                                              "/agents/marketing-material",
                                              "/aggregator/marketing-material",
                                              "/imos/marketing-material",
                                              "/admins/marketing-material",
                                          ]}
                                          allowedRoles={[
                                              AccountType.MasterAdmin,
                                              AccountType.Admin,
                                              AccountType.Agent,
                                              AccountType.Aggregator,
                                              AccountType.IMO,
                                          ]}
                                          component={lazy(() => import("./pages/MarketingMaterials"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[
                                              AccountType.MasterAdmin,
                                              AccountType.Admin,
                                              AccountType.Agent,
                                              AccountType.Aggregator,
                                              AccountType.AggregatorMember,
                                              AccountType.IMO,
                                              AccountType.Individual,
                                              AccountType.Multilife,
                                              AccountType.ImoMember,
                                          ]}
                                          path={[
                                              "/admins/news-social",
                                              "/agents/news-social",
                                              "/imos/news-social",
                                              "/news-social",
                                          ]}
                                          component={lazy(() => import("./pages/NewAndSocialPostPage"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/docu-sign-documents/list"}
                                          component={lazy(() => import("./pages/docuSignDocuments/List"))}
                                          allowedRoles={[AccountType.MasterAdmin]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/docu-sign-documents/add"}
                                          component={lazy(() => import("./pages/docuSignDocuments/Add"))}
                                          allowedRoles={[AccountType.MasterAdmin]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/docu-sign-documents/edit/:id"}
                                          component={lazy(() => import("./pages/docuSignDocuments/Edit"))}
                                          allowedRoles={[AccountType.MasterAdmin]}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/reset-not-signed-enrollments"}
                                          component={lazy(() => import("./pages/ResetNotSignedEnrollment"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/reset-enrollment/created-account/not-exist-in-enrollment-table"}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          component={lazy(() => import("./pages/ResetEnrollmentNotStartedEnrollment"))}
                            />

                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Trustee]}
                                          path="/accounts/clients/individuals"
                                          component={lazy(() => import(`./pages/trustee/ClientManagement`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Carrier]}
                                          path="/accounts/carrier/clients"
                                          component={lazy(() => import(`./pages/carrier/ClientManagement`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Carrier]}
                                          path="/carrier/analytics"
                                          component={lazy(() => import(`./pages/ComingSoon`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.Individual]}
                                          path="/enrollmentServicingComing"
                                          component={lazy(() => import(`./pages/client/Enrollment/enrollmentServicingComing`))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} allowedRoles={[
                                AccountType.Individual,
                                AccountType.Multilife,
                            ]}
                                          exact={true}
                                          path="/enrollment/start"
                                          component={lazy(() => import("./pages/client/EnrollmentStart"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/resend-invitation/to/users"}
                                          allowedRoles={[AccountType.MasterAdmin]}
                                          component={lazy(() => import("./pages/ResendPendingInvitations"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/ilia-notifications"
                                          component={lazy(() => import("./pages/IliaNotifications"))}
                                                                          />

                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/dashboard"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/ClientServiceDashbaord"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/payments"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/Payments"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/plandocuments"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/PlanDocuments"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/annualreview"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/AnnualReview"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/insured-details"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/InsuredDetails"))}/>

                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/review-details"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/ReviewPolicyDetails"))}/>

                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/edit/legal-name"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/EditLegalName"))}/>

                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/review-beneficiaries"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/ReviewBeneficiaryDetails"))}/>

                <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path={"/client/edit-personal-details"}
                                          allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                                          isServicing={true}
                                          component={lazy(() => import("./pages/clientServicing/EditPesonalDetails"))} />
                <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                              path={"/client/edit-beneficiary-details"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/EditBeneficiaryDetails"))} />

                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/claims"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/Claims"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/questions"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/Questions"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/referralfeedback"}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              isServicing={true}
                              component={lazy(() => import("./pages/clientServicing/ReferralsFeedback"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/contactus"}
                              isServicing={true}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              component={lazy(() => import("./pages/clientServicing/ContactUs"))}/>
                <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={"/client/action-items"}
                              isServicing={true}
                              allowedRoles={[AccountType.Individual, AccountType.Multilife]}
                              component={lazy(() => import("./pages/clientServicing/ActionItemsPage"))} />
                 <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/reports"
                                          component={lazy(() => import("./pages/BIReports"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={[AccountType.MasterAdmin, AccountType.Admin]}
                                          path="/duplicate/merge-inputs"
                                          component={lazy(() => import("./pages/DuplicateMergeInputs"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          path="/client-payments"
                                          component={lazy(() => import("./pages/agent/ClientPaymentMethod"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path="/tranche-dates"
                                          component={lazy(() => import("./pages/TrancheDates"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true}
                                          allowedRoles={allRoles}
                                          path="/import-ghost-client"
                                          component={lazy(() => import("./pages/ImportGhostClient"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps} exact={true} path={[
                                "/aggregator/imo",
                            ]} component={lazy(() => import("./pages/aggregator/ImoPage"))}
                            />
                            <PrivateRoute {...defaultProtectedRouteProps}
                                          path="/aggregator/detail/:id"
                                          component={lazy(() => import("./pages/aggregator/AggregatorProfile"))}/>
                            <Route component={NotFound} />
                        </Switch>
                    </main>
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
    );
}

export default withRoot(Routes);

