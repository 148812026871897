import React from "react";
import {Grid, Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";

function getYearFromDate(dateString: string) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
}
export default function PaymentHistoryDetailsData(props:any) {
    const {paymentHistoryDetails, showTrustee = true} = props;
    let totalAmount = 0;
    let ePayFee = 0
    let contributionAmount = 0;
    let trustFeePayment = 0;
    let policyYears = "";
    if (paymentHistoryDetails && paymentHistoryDetails.amount && paymentHistoryDetails.amount.length > 0) {
        const amountDetails = paymentHistoryDetails.amount;
        contributionAmount = amountDetails[0].amount;
        trustFeePayment = amountDetails[1] ? amountDetails[1].amount : 0;
        ePayFee = amountDetails[2] ? amountDetails[2].amount : 0;
        totalAmount = contributionAmount + trustFeePayment + ePayFee;
        // if show showTrustee is false then we add its ammount in the contrubution ammount
        if(showTrustee == false){
            contributionAmount +=  amountDetails[1].amount || 0 
            totalAmount = contributionAmount + ePayFee
        }
        let paymentStartYear = getYearFromDate(paymentHistoryDetails.date);
        policyYears = paymentStartYear + "-" + (paymentStartYear+1);
    }

    return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="caption" className="floatLeft w100 mb15">This payment’s itemized details are below.</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatLeft w100 mb10">Policy Year</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatRight mb10">{policyYears}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatLeft w100 mb10">Contribution Payment</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatRight mb10"><NumberFormat
                            value={contributionAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={"$"}/></Typography>
                </Grid>

                {showTrustee && <><Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatLeft w100 mb10">Trust Fee Payment</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatRight mb10"><NumberFormat
                            value={trustFeePayment}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={"$"}/></Typography>
                </Grid></>}

                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatLeft w100 mb10">ePay Processing Fee</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" className="floatRight mb10">
                        <NumberFormat
                            value={ePayFee}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={"$"}/></Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" component="strong" className="floatLeft w100 mb10">Total:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" component="strong" className="floatRight mb10"><NumberFormat
                            value={totalAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={"$"}/></Typography>
                </Grid>
                <Grid item xs={12} >
                    <span className="hLine"/>
                </Grid>
                {/* Will add later this section */}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <Typography variant="caption" className="floatLeft w100 mb10">Remaining Balance for 2023-2024</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <Typography variant="caption" className="floatRight mb10">$0</Typography>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <Typography variant="caption" className="floatLeft w100 mb10">2024 Contribution Due Date</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <Typography variant="caption" className="floatRight mb10">11/02/1985</Typography>*/}
                {/*</Grid>*/}
            </Grid>
    );
};