import React from "react";
import {
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from "@material-ui/core";
import pigblue from "../../images/pig-blue.svg";
import pigOrange from "../../images/pig-orange.svg";
import pigGreen from "../../images/pig-green.svg";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";
import ReactPlayer from "react-player";
import Assumptions from "../Assumptions";
import { LOGO_BASE_URL } from "../../../../../../constants";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import StrategyName from "../../../../../../components/client/StrategyName";
import TabNavigation from "../TabNavigation";
import {maskCurrency} from "../../../../../../utils/commonUtils";

function EstimationTabContentOne(props: any) {

    const {
        strategyName,
        strategySlug,
        setTabValue,
        trizenDemo,
        responseData,
        callingFrom,
        tag
    } = props;

    const [companyName, setCompanyName] = React.useState<string>("");
    const { user: { client, accessType }, user } = useSelector((state: RootState) => state.auth);
    const conTotalRef: any = React.useRef(null);
    const gifRef: any = React.useRef(null);
    const [gifScrollRight, setGifScrollRight] = React.useState(true);
    let videoName: string = "animation_how_contributions_work_no_employer.mp4";
    const { s3rootkey , stress, common, comparison, illustration } = responseData;
    let contributionamount: number = stress && stress.contributionamount || 0;
    let contributionamountFiveYears: number = (stress && stress.contributionamount) * 5 || 0;
    let employerContribution: number = 0;
    let employerFiveYearContribution: number = 0;
    let showEmployerContribution: boolean = false;
    if (common && common.contributionamountemployer) {
        videoName = "animation_how_contributions_work_with_employer.mp4";
        showEmployerContribution = true;
        employerContribution = common.contributionamountemployer;
        employerFiveYearContribution = employerContribution * 5;
        contributionamount = stress.contributionamount - employerContribution;
        contributionamountFiveYears = contributionamount*5;
    }
    const btnVideoUrl: string = `${LOGO_BASE_URL}estimation/${videoName}`;


    let lenderContributionUpTo: number = illustration && illustration.data && illustration.data.loanamounttotal || 0;

    let lenderContributionYear1: number = illustration && illustration.data && illustration.data.loanamountyear1 || 0;
    let lenderContributionYear6: number = illustration && illustration.data && illustration.data.loanamountyear6 || 0;

    let illustrationTotalIncome:number = illustration && illustration.totalincome || 0;
    let deathbenefitlessloanage90:number = illustration && illustration.data && illustration.data.deathbenefitlessloanage90 || 0;
    let comparisonTotalIncome:number = comparison && comparison.totalincome || 0;
    let deathbenefitage90:number = comparison && comparison.deathbenefitage90 || 0;


    const paymentFrequency = common && common.paymentfrequency || "Annual";
    let contributionAmount = common && common.contributionamount;

    //If Employer Remove that from total
    if (common && common.contributionamountemployer) {
        contributionAmount = contributionAmount - common.contributionamountemployer;
    }
    let contributionText: any = "Your Contribution";
    if (paymentFrequency === "Monthly") {
        contributionText = `Your Monthly Contribution of: ${maskCurrency((contributionAmount/12) + "")}`;
    }


    const scrollGif = () => {
        const lastPos = gifRef.current.scrollLeft;
        gifRef.current.scrollLeft = gifScrollRight ? gifRef.current.scrollLeft + 40 : gifRef.current.scrollLeft - 40;
        if (lastPos === gifRef.current.scrollLeft) {
            lastPos ? setGifScrollRight(false) : setGifScrollRight(true);
        }
    };

    const calculatePercentage = (illustrationTotalIncome, deathbenefitlessloanage90, comparisonTotalIncome, deathbenefitage90) => {
        return ((comparisonTotalIncome + deathbenefitage90) / ((illustrationTotalIncome + deathbenefitlessloanage90) - (comparisonTotalIncome + deathbenefitage90)) * 100)
    }

    const percentage = calculatePercentage(illustrationTotalIncome, deathbenefitlessloanage90, comparisonTotalIncome, deathbenefitage90) || 0
    return (
            <Grid item container justify="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" className="mt30">How contributions work and combined
                        totals.</Typography>
                    <div style={{ position: "relative" }}>
                        <IconButton className="scrollRightMini Three" aria-label="ChevronRightIcon"
                                    onClick={() => scrollGif()}>
                            {gifScrollRight ?
                                    <ChevronRightIcon fontSize="large"/> :
                                    <ChevronLeftIcon fontSize="large"/>
                            }
                        </IconButton>
                        <div className="tabsContentWrap mb40" ref={gifRef}>
                            <ReactPlayer
                                    className="contributionsVideo"
                                    controls={false}
                                    playing={true}
                                    loop={false}
                                    autoPlay={true}
                                    volume={0}
                                    muted={true}
                                    url={btnVideoUrl}
                                    playsinline={true}
                            />
                        </div>
                    </div>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Typography variant="body1" component="strong" className="mt40 mb20 floatLeft w100">How <StrategyName strategyName={strategyName}/> uses smart
                                leverage.</Typography>
                            <Typography
                                    variant="body2">You{(companyName && employerContribution > 0 && showEmployerContribution) ? ", " + `${companyName}` : ""}{(employerContribution > 0 && trizenDemo) ? ",your employer" : ""} and
                                the lender
                                contribute for years
                                1-5. Then the lender uses those contributions as the security
                                for all the additional funds added for years 6-10.
                                The excess leverage provides you the potential for up to 60-100% more protection and distributions than other non-leveraged
                                choices. </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography variant="body1" component="strong" className="mt40 mb20 floatLeft w100">Contribution totals over 10 year period.</Typography>
                            <div className="tabsContentWrap floatLeft w100" ref={conTotalRef}>
                                <List className="tabsContentList pt0">
                                    <ListItem>
                                        <ListItemAvatar>
                                            <img alt={"pig-orange"} src={pigOrange}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                                primary={contributionText}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="caption"
                                                                component="span"
                                                        >
                                                            (<NumberFormat value={contributionamount}
                                                                           displayType={"text"}
                                                                           decimalScale={0}
                                                                           thousandSeparator={true} prefix={"$"}/> *
                                                            years
                                                            1-5)<Tooltip arrow color="primary"
                                                                         title="Your annual contribution amount years 1-5.">
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography variant="subtitle1" className="mb0 valueText"><NumberFormat
                                                    value={contributionamountFiveYears}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                    prefix={"$"}/></Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {employerContribution > 0 &&
                                            (accessType === "client" ||
                                                    (showEmployerContribution && accessType !== "client" &&
                                                            (strategySlug !== "esd" || (strategySlug === "esd" && user.role === 6))))
                                            &&
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <img alt={"pigblue"} src={pigblue}/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary=" Employer Contribution"
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                        variant="caption"
                                                                        component="span"
                                                                >
                                                                    (<NumberFormat value={employerContribution}
                                                                                   displayType={"text"}
                                                                                   thousandSeparator={true}
                                                                                   decimalScale={0}
                                                                                   prefix={"$"}/> *
                                                                    years 1-5) <Tooltip arrow color="primary"
                                                                                        title="The employer annual contribution amount years 1-5.">
                                                                    <IconButton aria-label="Info">
                                                                        <InfoIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1"
                                                                className="mb0 valueText"><NumberFormat
                                                            value={employerFiveYearContribution}
                                                            displayType={"text"}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                            prefix={"$"}/></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                    }
                                    <ListItem>
                                        <ListItemAvatar>
                                            <img alt={"pigGreen"} src={pigGreen}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                                primary="Lender Contributes Up To"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="caption"
                                                                className="w100 floatLeft"
                                                        >

                                                            <>(<NumberFormat value={lenderContributionYear1}
                                                                             displayType={"text"}
                                                                             decimalScale={0}
                                                                             thousandSeparator={true}
                                                                             prefix={"$"}/> *
                                                                years
                                                                1-5)
                                                            </>

                                                        </Typography>
                                                        <Typography
                                                                variant="caption" className="w100 floatLeft"

                                                        >

                                                            <>(<NumberFormat value={lenderContributionYear6}
                                                                             displayType={"text"}
                                                                             thousandSeparator={true}
                                                                             decimalScale={0}
                                                                             prefix={"$"}/> *
                                                                years
                                                                6-10)
                                                            </>
                                                            <Tooltip arrow color="primary"
                                                                     title="The lender will provide up to 3 times the participant’s total contribution amount, determined by the client’s age and health, for up to 10 years.">
                                                                <IconButton aria-label="Info">
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography variant="subtitle1" className="mb0 valueText"><NumberFormat
                                                    value={lenderContributionUpTo}
                                                    displayType={"text"}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    prefix={"$"}/></Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="hLine"/>
                            <Typography variant="subtitle1" align="right" className="floatRight mobileLeft mb0"><strong
                                    className="floatLeft f14Mini">Total combined contributions</strong>
                                <Tooltip className="floatLeft ml35" arrow color="primary" title="Total contributions for all participants
                            including loaned contributions years 1-10. There are minimum contribution requirements
                             as well as the need for a participant trust to be established as part of the plan.
                             Please see i under “your contribution” for details.">
                                    <IconButton aria-label="Info">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip><span className="floatRight ml35"><NumberFormat
                                        value={contributionamountFiveYears + lenderContributionUpTo + (employerFiveYearContribution ? employerFiveYearContribution : 0)}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true} prefix={"$"}/> </span></Typography>
                            <span className="hLine"/>
                        </Grid>
                        <TabNavigation
                                next={{
                                    title: "Next: Benefits & Distributions",
                                    visibility: true,
                                    setTabValueNext: setTabValue,
                                    tabValNext: "two",
                                }}
                                prev={{ title: "", visibility: false, setTabValuePrev: setTabValue, tabValPrev: "" }}
                        />
                        <Assumptions key={s3rootkey} strategyName={strategyName} common={common} callingFrom={callingFrom} tag={tag}/>
                    </Grid>
                </Grid>
            </Grid>
    );
}

export default EstimationTabContentOne;