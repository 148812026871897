import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import url from "url";
import api, {Response} from "superagent";
import { Tooltip, Typography} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Edit} from "@material-ui/icons";
import {useActions} from "../../actions";
import * as Aws from "../../actions/aws";
import { getRandomString, isFunc, parseEmptyString } from "../../utils/commonUtils";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import CommonLoader from "../CommonLoader";
import Button from "@material-ui/core/Button";

export interface awsResp extends Response {
    req?: {
        url: string
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    dashed: {
        borderStyle: "dashed",
        color: "#cccccc",
        backgroundColor: "#eeeeee",
        // height: '50px'
    },
    mOne: {
        margin: theme.spacing(1),
    },
    mLeftOne: {
        marginLeft: theme.spacing(1),
    },
    mRightTwo: {
        marginRight: theme.spacing(2),
    },
    barColorPrimary: {
        backgroundColor: "#179917",
    },
    colorPrimary: {
        backgroundColor: "#cfcfcf",
    },
    root: {
        height: "10px",
        marginTop: "-4px !important",
    },
}));

export default function EnrollmentFileUploader({
                                                   exactFileName = '',
                                                   textWithIcon = '',
                                                   content,
                                                   subContent,
                                                   dirName,
                                                   dirType,
                                                   onFinish,
                                                   fileType,
                                                   width,
                                                   height,
                                                   getFileName,
                                                   getOrigionalFileName,
                                                   iconOnly = false,
                                                   isSecuredSignedUrl = true,
                                                   isCsv = false,
                                                   multiple = false,
                                                   multipleFileOnFinish,
                                                   uploadRef=null,
                                                   checkInvalidFile,
                                                   setOnProgress,
                                                   onProgress= false,
                                                   name=""
                                               }: any) {
    const [files, setFiles] = useState([]);
    const [validate, setValidate] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [isError, setIsError] = useState(false);
    const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);

    const classes = useStyles();
    const awsActions = useActions(Aws);
    const multipleFileNameArr:string[] = [];
    const multipleFileUrlArr:string[] = [];

    const {getRootProps, getInputProps} = useDropzone({
        accept: fileType || "image/*",
        onDrop: (acceptedFiles, rejectedFiles) => {
            if(typeof setOnProgress === 'function'){
                setOnProgress(true);
            }
            if(acceptedFiles && acceptedFiles.length){
                let ext: any = acceptedFiles[0].name.split('.').pop();
                if(ext.toLowerCase() === "jpg" || ext.toLowerCase() === "png" || ext.toLowerCase() === "pdf"){
                    setIsError(false);
                } else{
                    setIsError(true);
                    if (isFunc(setOnProgress)) {
                        setOnProgress(false);
                    }
                    return false;
                }
            }
            const allFilesCount = acceptedFiles.length + rejectedFiles.length;
            if (multiple) {
                if (allFilesCount == acceptedFiles.length) {
                    setIsError(false);
                } else {
                    setIsError(true);
                    if (isFunc(setOnProgress)) {
                        setOnProgress(false);
                    }
                }
            }
            const completeUpload = () => {
                return Promise.all(acceptedFiles.map(async (file) => {

                    const fileName: string = file.name;
                    const ext: string | undefined = fileName.split('.').pop();
                    const preview: string = URL.createObjectURL(file);
                    if (width && height && ext && ext !== "pdf") { //image uploader can also upload pdf
                        const i = new Image();
                        i.onload = () => {
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = async () => {
                                if (i.width > width || i.height > height) {
                                    setValidate(true);
                                    return;
                                } else {
                                    setValidate(false);
                                    setFiles(file as any);
                                }
                            };
                        };
                        i.src = preview;
                    } else {
                        if(file && file.type === "application/pdf"){
                            if(file.size >= 20000000){  // 5000000 BYTE === 5 MB
                                setFileSizeError(true);
                                return;
                            }
                        }
                        setFiles(file as any);
                    }
                    await handleSendToS3(file as any);
                    return Object.assign(file, {preview});
                }));
            }
            completeUpload().then(data => {
                if(multipleFileOnFinish && multiple){
                    multipleFileOnFinish(multipleFileUrlArr, multipleFileNameArr)
                }
            })
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > 0) {
                setIsError(true);
                if (isFunc(setOnProgress)) {
                    setOnProgress(false);
                }
            }
            if (typeof checkInvalidFile === 'function') {
                checkInvalidFile();
            }
        },
        multiple
    });

    interface fileProps {
        name: any,
        preview: any
    }

    const handleSendToS3 = async (file: any) => {
        setIsUpload(true);
        const fileName: string = file.name;
        const ext: string | undefined = fileName.split('.').pop();
        const queryObj = {
            objectName: (isSecuredSignedUrl && isCsv) ? getRandomString() + ".csv" : (exactFileName ? exactFileName + '.' + ext : file.name),
            contentType: file.type || "text/csv",
            dirName: dirName,
            //dirType: dirType,
        };
        const {payload: {signedUrl, filename}} = await awsActions.SignedUrlGet(queryObj, isSecuredSignedUrl);
        console.log("***filename**", filename , file);
        if( !signedUrl ){
            console.log('Signed Url Not returned');
            setIsUpload(false);
            setIsSomethingWentWrong(true);
            return false;
        }
        return new Promise((resolve, reject) => {
            api
                    .put(signedUrl)
                    .send(file)
                    .on("progress", (e) => {
                    })
                    .then((res: awsResp) => {
                        const parseUrl = parseEmptyString(res.req && res.req.url);

                        const parsedUrl = url.parse(parseUrl, true);
                        const fileUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;
                        setFileUrl(fileUrl);
                        if (onFinish) {
                            multipleFileNameArr.push(filename);
                            multipleFileUrlArr.push(fileUrl);
                            onFinish(fileUrl, filename);
                            setIsUpload(false);
                        }
                        if (getFileName) {
                            getFileName(dirName + "/" + filename);
                        }
                        if (getOrigionalFileName) {
                            getOrigionalFileName(filename);
                        }
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
        });
    };


    useEffect(() => () => {
        files.length > 0 && files.forEach((file: fileProps) => URL.revokeObjectURL(file.preview));
    }, [files.length]);

    return (
            <div {...getRootProps()} ref={uploadRef ? uploadRef : null}>
                {
                    (!iconOnly && fileUrl && !validate) && ((!width && !height) && <Edit className="floatLeft"/>)
                }
                <input id={name} name={name} {...getInputProps()} disabled={onProgress}/>

                {
                    iconOnly
                            ?
                            (textWithIcon && textWithIcon !='' ?
                                    (
                                    isUpload ?
                                        <CommonLoader margin={'0px 0px'}/>
                                        :
                                        <Button
                                                size="large"
                                                variant="outlined"
                                                color="primary"
                                                className="mt20 mb30"
                                        >
                                            {textWithIcon}
                                        </Button>
                                    )
                                :
                            <Tooltip title="Edit">

                                <Edit className={classes.mRightTwo}/>
                            </Tooltip>
                            )

                            : <React.Fragment>
                                {isUpload ?
                                        <CommonLoader margin={'0px 0px'}/>
                                        :
                                        <div className="fileUploadContent">
                                            <AddToPhotosIcon className="floatLeft mr30"/>
                                            <Typography variant="caption" align="left" className="floatLeft">
                                                {content} <br/>
                                                {subContent}
                                            </Typography>

                                        </div>
                                }
                                {
                                    validate &&
                                    <Typography variant="caption" className="w100 floatLeft f12" style={{color: "red"}}>Please check Width or
                                        Height of File</Typography>
                                }
                                {
                                    isError &&
                                    <Typography variant="caption" className="w100 floatLeft f12" style={{color: "red"}}>Invalid file type, please upload jpg, png or pdf</Typography>
                                }
                                {
                                    isSomethingWentWrong &&
                                    <Typography variant="caption" className="w100 floatLeft f12" style={{color: "red"}}>Something went wrong, please try later!</Typography>
                                }
                                {
                                    fileSizeError &&
                                    <Typography variant="caption" className="w100 floatLeft f12" style={{color: "red"}}>File size must must be less or equals to 5MB!</Typography>
                                }
                            </React.Fragment>
                }
            </div>
    );
}