import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { DashboardStyle as Styles } from "./dashboardStyle";
import QuickView from "./QuickView";
import PortalPages, { PortalPagesData } from "./PortalPages";
import * as AccountActions from "../../../actions/account";
import { useActions } from "../../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { PageTitle } from "../../../components/formInputs";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AgentLinksStats from "../AgentLinks/AgentLinksStats";
import DemoModeSelectionPopup from "../../../components/DemoMode/DemoModeSelectionPopup";
import ActiveCaseForCoverLetter from "./ActiveCaseForCoverLetter";
import UploadSpecificDocument from "../../client/ClientProfile/component/dialogs/UploadSpecificDocument";
import { joinAsFullName, sleep } from "../../../utils/commonUtils";
import { AgentType, GWT_GEN2_STRATEGY } from "../../../constants";


export const getName = (auth): string => auth && auth.user && auth.user && auth.user.firstName;

type CDType = {
    client: { id: number; userId: string; },
    strategy: { id: number };
    user: { enrollment: { id: string; }; firstName: string; id: string; lastName: string; },
};
const AgentDashboard = (props) => {
    const navigateTo = useHistory();
    const classes = Styles();
    const auth: any = useSelector((state: RootState) => state.auth);
    let agentId: number = auth && auth.user && auth.user.agent && auth.user.agent.id;
    let user: any = auth && auth.user;
    const agentUserId = auth && auth.user && auth.user.agent && auth.user.agent.userId;
    let name = getName(auth);
    const accountActions = useActions(AccountActions);
    const [totalInvites, setTotalInvites] = React.useState<number>(0);
    const [totalAccountCreated, setTotalAccountCreated] = React.useState<number>(0);
    const [enrolling, setEnrolling] = React.useState<number>(0);
    const [enrolled, setEnrolled] = React.useState<number>(0);
    const [activeUsers, setActiveUsers] = React.useState<number>(0);
    const [inActiveUsers, setInActiveUsers] = React.useState<number>(0);
    const [showDemoSelectionPopup, setShowDemoSelectionPopup] = React.useState<boolean>(false);
    const [coverLetterActiveCasePopUp, setCoverLetterActiveCasePopUp] = React.useState(false); // state used for active case popup
    const [coverLetterDialog, setCoverLetterDialog] = React.useState(false);
    const [clientDetail, setClientDetail] = React.useState<CDType>({} as CDType);
    const [clientName, setClientName] = React.useState<string>("");
    const isApprovedAgent = user && user.agent && user.agent.agentType === AgentType.Approved;

    const getAgentDetails = async () => {
        let data = await accountActions.getAgentDashboardDetails();
        if (data) {
            setTotalInvites(data && data.totalInvites);
            setTotalAccountCreated(data && data.accountCreated);
            setEnrolling(data && data.currentlyEnrolling);
            setEnrolled(data && data.enrolled);
            setActiveUsers(data && data.activeUsers);
            setInActiveUsers(data && data.inActiveUsers);
        }
    };

    const handleExport = accountActions.getAgentDashboardLists;

    const getClientsCoverLetterDetail = async (agentId: number) => {
        return accountActions.getAgentsClientCoverLetterDetail(agentId);
    };

    React.useEffect(() => {
        // get agent dashboard details
        getAgentDetails().then();

        if (agentId) {
            // wait one second
            sleep()
                    // fetch cover letter or gwt client
                    .then(() => getClientsCoverLetterDetail(agentId)
                            .then((res) => {
                                if (res && res.id) {
                                    const { strategy, user } = res;
                                    setCoverLetterActiveCasePopUp(true);
                                    const client = { userId: res.user.id, id: res.id };
                                    setClientDetail({ ...user, client, strategy });
                                    setClientName(joinAsFullName(res.user));
                                }
                            }),
                    );
        }
    }, [agentId]);

    const handleNavigation = async (url: string) => {
        props.history.push(url);
        await accountActions.refreshApplicationState();
    };

    const isGWTGen2 = clientDetail && clientDetail.strategy && clientDetail.strategy.id === GWT_GEN2_STRATEGY;

    const onUploadCoverLetter = () => {
        setCoverLetterDialog(true);
    };
    const onACCLAction = () => {
        if (isGWTGen2) {
            // redirect to client case details
            navigateTo.push(`/new-client/detail/${clientDetail.client.id}/case-details`);
            return;
        }

        onUploadCoverLetter();
    };

    return (
            <div className={classes.containerWidth1700}>
                <PageTitle title="Agent Dashboard" />
                <HeroBanner
                        name={name}
                        subheader={
                            `This is your personal dashboard for viewing and managing your ilia clients. 
                        You can get started by selecting an option below or from the 
                        main navigation.`}
                >
                    {!isApprovedAgent &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                            className="mt30 glow bgWhite floatLeft mlSm15"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => props.history.push("/client/add")}
                                    >
                                        Create New Invitation
                                    </Button>
                                    <Button
                                            className="mt30 mb30 glow bgWhite floatLeft ml35 mlSm15"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setShowDemoSelectionPopup(true)}
                                    >
                                        Client Presentation
                                    </Button>
                                </Grid>
                            </Grid>}
                </HeroBanner>
                {!isApprovedAgent &&
                        <>
                            <Grid container spacing={2} className="mt40">
                                <Grid item xs={12}>
                                    <Typography variant="caption" component="strong" align="left"
                                                className={classes.textLight + " " + classes.mb15 + " " + classes.floatLeft + " " + classes.w100}>
                                        PORTAL PAGES </Typography>
                                    <Box display="flex" flexWrap="wrap"
                                         className={classes.flexBox + " " + classes.floatLeft + " " + classes.w100}>
                                        <PortalPages pages={PortalPagesData} handleNavigation={handleNavigation} />
                                    </Box>
                                </Grid>
                            </Grid>
                            <QuickViewSection title="QUICK VIEW">
                                <ClientQuickView as={QuickView}
                                                 handleExport={handleExport}
                                                 agentUserId={agentUserId}
                                                 totalInvites={totalInvites}
                                                 totalAccountCreated={totalAccountCreated}
                                                 enrolling={enrolling}
                                                 enrolled={enrolled}
                                                 activeUsers={activeUsers}
                                                 inActiveUsers={inActiveUsers} />
                            </QuickViewSection>
                            <QuickViewSection title="QUICK VIEW">
                                <AgentLinksStats user={auth.user} />
                            </QuickViewSection>
                            {user && (user.role === 1 || user.role === 2 || user.role === 4) && user.accessType !== "client" &&
                                    <DemoModeSelectionPopup
                                            showDemoSelectionPopup={showDemoSelectionPopup}
                                            setShowDemoSelectionPopup={() => {
                                                setShowDemoSelectionPopup(false);
                                            }}
                                            role={user && user.role}
                                    />
                            }
                            <ActiveCaseForCoverLetter
                                    agentName={name}
                                    clientName={clientName}
                                    isGWTGen2={isGWTGen2}
                                    onAction={onACCLAction}
                                    open={coverLetterActiveCasePopUp}
                                    setCoverLetterActiveCasePopUp={setCoverLetterActiveCasePopUp}
                            />
                            {coverLetterDialog &&
                                    <UploadSpecificDocument
                                            clientDetail={clientDetail}
                                            open={coverLetterDialog}
                                            handleClose={async () => {
                                                setCoverLetterDialog(false);
                                            }}
                                            uploadCoverLetter={true}
                                            setCoverLetterReq={() => {
                                            }}
                                            coverLetterUploadedFromActionItem={true}
                                            setCoverLetterActiveCasePopUp={setCoverLetterActiveCasePopUp}
                                    />
                            }
                        </>
                }
            </div>
    );
};

type HeroBannerProps = {
    name: string;
    subheader: string;
    children?: React.ReactNode
}

export const ClientQuickView = ({
                                    handleExport,
                                    totalInvites,
                                    totalAccountCreated,
                                    enrolling,
                                    enrolled,
                                    activeUsers,
                                    inActiveUsers,
                                    imoUserId = null,
                                    agentUserId = null,
                                    as: QuickView,
                                }) => {
    const classes = Styles();

    return (
            <>
                <QuickView
                        handleExport={handleExport}
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        isHeading={false}
                        heading={"All"}
                        data={[
                            {
                                tooltipText: "Number includes all individual invites, group invites, and client lists.",
                                title: { name: "Total Invites", value: totalInvites },
                                className: classes.btnBlue,
                                kpiModal: "TOTAL_INVITES",
                                kpiModalTitle: "Total Invites",
                            },
                            {
                                tooltipText: null,
                                title: { name: "Total Accounts Created", value: totalAccountCreated },
                                className: classes.btnGreen,
                                kpiModal: "TOTAL_ACCOUNT_CREATED",
                                kpiModalTitle: "Total Accounts Created",
                            },
                        ]}
                        csvKey={"ALL"}
                />

                <QuickView
                        handleExport={handleExport}
                        isHeading={true}
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        heading={"Currently Enrolling"}
                        data={[
                            {
                                tooltipText: null,
                                title: { name: "Client currently in enrollment", value: enrolling },
                                className: classes.btnGreen,
                                kpiModal: "ENROLLING",
                                kpiModalTitle: "Currently Enrolling",
                            },
                        ]}
                        csvKey={"ENROLLING"}
                />

                <QuickView
                        handleExport={handleExport}
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        isHeading={true}
                        heading={"Enrolled"}
                        data={[
                            {
                                tooltipText: null,
                                title: { name: "Client that have already enrolled", value: enrolled },
                                className: classes.btnGreen,
                                kpiModal: "ENROLLED",
                                kpiModalTitle: "Enrolled",
                            },
                        ]}
                        csvKey={"ENROLLED"}
                />

                <QuickView
                        handleExport={handleExport}
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        isHeading={true}
                        heading={"Currently Active"}
                        data={[
                            {
                                tooltipText: null,
                                title: {
                                    name: "Has an account and active within 30 days.",
                                    value: activeUsers,
                                },
                                className: classes.btnGreen,
                                kpiModal: "CURRENTLY_ACTIVE",
                                kpiModalTitle: "Currently Active",
                            },
                        ]}
                        csvKey={"CURRENTLY_ACTIVE"}
                />

                <QuickView
                        handleExport={handleExport}
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        isHeading={true}
                        heading={"Currently Inactive"}
                        data={[
                            {
                                tooltipText: null,
                                title: {
                                    name: "Has account but no activity within 30 days.",
                                    value: inActiveUsers,
                                },
                                className: classes.btnRed,
                                kpiModal: "CURRENTLY_IN_ACTIVE",
                                kpiModalTitle: "Currently Inactive",
                            },
                        ]}
                        csvKey={"CURRENTLY_IN_ACTIVE"}
                        selectable
                />
            </>
    );
};

export const HeroBanner = ({ name, subheader, children }: HeroBannerProps) => {
    const classes = Styles();

    return (
            <div className={classes.agentHero}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7} lg={7}>
                        <Typography variant="h2" align="left"
                                    className={classes.colorWhite + " " + classes.title}>Welcome&nbsp; {name} </Typography>
                        <Typography variant="subtitle1"
                                    className={classes.colorWhite + " " + classes.mb0 + " " + classes.floatLeft}>
                            {subheader}
                        </Typography>
                    </Grid>
                    {children}
                </Grid>
            </div>
    );
};

export const QuickViewSection = ({ title, children }) => {
    const classes = Styles();
    const [expandQuickView, setExpandQuickView] = React.useState(true);

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.expandDiv + " mt40 expandDiv"}>
                            <Typography variant="caption" component="strong" align="left"
                                        className={classes.textLight + " " + classes.mb15 + " " + classes.floatLeft}
                                        onClick={() => setExpandQuickView(!expandQuickView)}>
                                {title}
                            </Typography>
                            {expandQuickView ?
                                    <ExpandLessIcon className="cursor floatLeft" color="primary"
                                                    onClick={() => setExpandQuickView(false)} /> :
                                    <ExpandMoreIcon className="cursor floatLeft" color="primary"
                                                    onClick={() => setExpandQuickView(true)} />
                            }
                        </div>
                        {expandQuickView &&
                                <Box display="flex" flexWrap="wrap"
                                     className={classes.flexBox + " " + classes.floatLeft + " " + classes.w100}>
                                    {children}
                                </Box>
                        }
                    </Grid>
                </Grid>
            </>
    );
};


export default AgentDashboard;