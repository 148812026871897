import {Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import {Formik} from "formik";
import React from "react";
import * as Yup from "yup";

import {AutoComplete, PhoneNumberField, SelectField, TextField} from "./formikFormInputs";
import {DialogTitle} from "./ConfirmEnrollment";
import {ImoForm} from "../pages/agent/AgentProfile/AgentProfileTab";
import {Button, Label} from "./formInputs";
import {stateDropDown} from "../constants";
import {Carrier} from "../model/carrier";
import {useActions} from "../actions";
import * as CarrierAction from "../actions/carrier";
import * as PublicActions from "../actions/public";
import {FormInputs} from "../pages/approvedAgents/ApprovedAgentsPage";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import differenceBy from "lodash/differenceBy";
import {pez} from "../utils/commonUtils";

export type DialogImoType = {
    title: string;
    buttonLabel: string;
}

type DialogImoProps = {
    handleClose: () => void;
    imos: any[];
    initialImo: ImoForm;
    onSubmit: (values: any) => void;
    type: DialogImoType;
    showForAdmin: boolean;
}

export const prefixAgentNumber = "agent_carrier_";

export const DialogImo = ({ handleClose, imos, initialImo, onSubmit, type, showForAdmin }: DialogImoProps) => {
    const carrierAction = useActions(CarrierAction);
    const publicActions = useActions(PublicActions);
    const [searchingImos, setSearchingImos] = React.useState(false);
    const [editModeImos, setEditMode] = React.useState(!!(initialImo.imo.key && initialImo.imo.title));
    const [carriers, setCarriers] = React.useState<any[]>([]);
    const [imoList, setImoList] = React.useState<any[]>([]);

    const onCancel = () => {
        setEditMode(false);
        handleClose();
    };
    const getCarriers = async () => {
        return carrierAction.getCarrierByUserId();
    };

    const setInitialCarriers = async () => {
        if (initialImo.imo.key) {
            const userImo = await publicActions.getImoById(initialImo.imo.key);
            const carriers = toCarriersState(userImo);
            setCarriers(carriers);
            updateAgentCarrierFields(carriers, initialImo.carriers);
        } else {
            const carriers = await getCarriers();
            setCarriers(carriers);
        }
    };

    React.useEffect(() => {
        setInitialCarriers();
    }, [initialImo]);


    const existingCarriers = uniqBy(
            flatten(imos.map(x => x.carriers))
                    .map(({ id, carrierName }) => ({ id, name: carrierName }))
            , "id",
    );

    const getPossibleCarriers = () => {
        let toRemove = existingCarriers;

        let imoCarriers: any[] = initialImo.carriers;
        if (imoCarriers) {
            toRemove = existingCarriers.filter(x => !imoCarriers.includes(x.id));
        }

        return differenceBy(carriers, toRemove, "id");
    };


    const carrierList = getPossibleCarriers().map(c => {
        return {
            key: c.id,
            value: c.value,
            name: c.name,
        };
    });

    const searchImos = async (name: string) => {
        setSearchingImos(true);
        const imos = await publicActions.getImoList(name);
        const list = imos
                .map((imo: any) => ({ key: imo.id, title: imo.groupName }))
                .filter((imo: any) => !existImoInAgentImos(imo));

        setImoList(list);
        setSearchingImos(false);
    };

    const toCarriers = (carriers: any[], carrierIds: number[]) =>
            carriers.filter(carrier => carrier.id ? carrierIds.includes(carrier.id) : false);

    const submit = (values: any, { setSubmitting }: any) => {
        const { imo: imoInfo, ...rest } = values;

        const agentCarrierNumbers = Object.keys(rest)
                .filter(key => key.startsWith(prefixAgentNumber))
                .map((key) => ({
                    carrierId: key.replace(prefixAgentNumber, ""),
                    phoneNumber: rest[key],
                }));


        const imo = {
            ...rest,
            carriers: formatCarriers(rest.carriers, agentCarrierNumbers),
            id: imoInfo.key,
            name: imoInfo.title,
            agentCarrierNumbers,
        };

        onSubmit(imo);
        setSubmitting(false);
        onCancel();
    };


    const formatCarriers = (carrierIds: number[], agentCarrierNumbers: { carrierId: string, phoneNumber: string }[]) => {
        const _carriers = toCarriers(carriers, carrierIds);
        return _carriers.map(carrier => {
            const c = agentCarrierNumbers.find(x => +x.carrierId === carrier.id);
            return ({
                carrierName: carrier.name,
                ...carrier,
                code: c && c.phoneNumber,
                phoneNumber: c && c.phoneNumber,
            });
        });
    };

    const [agentNumberFields, setAgentNumberFields] = React.useState<any>([]);

    const updateAgentCarrierFields = (carriers: Carrier[], carrierIds: number[]) => {
        const carriersSelected = toCarriers(carriers, carrierIds);
        setAgentNumberFields(
                carriersSelected.map(x => (
                        {
                            component: TextField,
                            disabled: false,
                            id: prefixAgentNumber + x.id,
                            label: <Label label={x.name + " Agent Number"} />,
                            name: prefixAgentNumber + x.id,
                            size: 6,
                        }
                )),
        );
    };


    const toCarriersState = (userImo: any): Carrier[] =>
            userImo.imo.carriers.map((x: any) => ({ id: x.carrierId, name: x.carrierName }));

    const setImoDetails = async (imoId: number, setFieldValue: any) => {
        if (!imoId) {
            updateAgentCarrierFields(carriers, []);
            return;
        }

        setEditMode(true);
        const userImo = await publicActions.getImoById(imoId);
        setShowStateAndRegionFields(false);
        setFieldValue("phone", userImo.imo.officeNumber);
        setFieldValue("contactFirstName", pez(userImo.firstName));
        setFieldValue("contactLastName", pez(userImo.lastName));
        setFieldValue("imoEmail", pez(userImo.imo.imoEmail));
        setCarriers(toCarriersState(userImo));
        updateAgentCarrierFields(carriers, []);
        setFieldValue("imo", { key: userImo.imo.id, title: userImo.imo.groupName });
    };

    const onSelectState = (state: string, setFieldValue) => {
        const stateOption = stateDropDown.find(x => x.value === state);
        setFieldValue("region", stateOption && stateOption.region);
    };

    const [showStateAndRegionFields, setShowStateAndRegionFields] = React.useState(true);
    const stateAndRegionFields = [
        {
            component: SelectField,
            disabled: editModeImos,
            label: <Label label={"IMO State"} required/>,
            name: "state",
            onSelectChange: onSelectState,
            options: stateDropDown.map(x => ({ ...x, key: x.value })),
            size: 6,
        },
        // {
        //     component: SelectField,
        //     disabled: true,
        //     label: <Label label={"Region"} required/>,
        //     name: "region",
        //     onSelectChange: noop,
        //     options: imoRegionDropDown.map(x => ({ ...x, key: x.value })),
        //     size: 6,
        // },
    ];

    const imoDetailFields = [
        {
            component: TextField,
            disabled: editModeImos,
            label: <Label label={"IMO Contact First Name"} required/>,
            name: "contactFirstName",
            size: 6,
        },
        {
            component: TextField,
            disabled: editModeImos,
            label: <Label label={"IMO Contact Last Name"} required/>,
            name: "contactLastName",
            size: 6,
        },
        {
            component: TextField,
            disabled: editModeImos,
            label: <Label label={"IMO Email"} required/>,
            name: "imoEmail",
            size: 6,
        },
        {
            component: PhoneNumberField,
            disabled: editModeImos,
            id: "phone",
            label: <Label label={"IMO Phone"} required/>,
            name: "phone",
            size: 6,
        }
    ];

    const imoFields = [
        {
            cb: async (text: string, setFieldValue: any, reason: string) => {
                setImoList([initialImo.imo]);
                setShowStateAndRegionFields(true);
                switch (reason) {
                    case "clear":
                        setEditMode(false);
                        setImoList([initialImo.imo]);
                        break;
                    case "input":
                        setFieldValue("imo", { key: null, title: text });
                        if (text && !searchingImos) {
                            await searchImos(text);
                        }
                        break;
                }
            },
            clearText: "Clear Selected IMO & its details",
            component: AutoComplete,
            contentList: imoList,
            freeSolo: !showForAdmin,
            id: "imo",
            label: <Label label={"Enter IMO"} required />,
            loading: searchingImos,
            name: "imo",
            onSelectChange: setImoDetails,
            size: 6,
        },
        ...(showStateAndRegionFields && !showForAdmin ? stateAndRegionFields : []),
        ...(showForAdmin ? [] : imoDetailFields),
        {
            component: SelectField,
            label: <Label label={"Select Carrier(s)"} required />,
            multiple: true,
            name: "carriers",
            onSelectChange: (carrierIds: number[]) => updateAgentCarrierFields(carriers, carrierIds),
            options: carrierList,
            renderValue: (carrierIds: number[]) => {
                const carriersSelected = toCarriers(carriers, carrierIds);
                return carriersSelected.map(x => x.carrierName).join(", ");
            },
            size: 6,
        },
    ];

    const buttonCommonProps: any = {
        size: "medium",
        variant: "contained",
    };

    const existImoInAgentImos = (imo: any) => {
        return imos.map(x => x.name).includes(imo.title);
    };

    return (
            <Dialog open fullWidth={true} maxWidth="md">
                <DialogTitle onClose={onCancel} id="dialog-imo">
                    {type.title}
                </DialogTitle>
                <Formik
                        initialValues={initialImo}
                        onSubmit={submit}
                        validationSchema={Yup.object({
                            imo: Yup.object({ key: Yup.number().nullable(), title: Yup.string() })
                                    .nullable()
                                    .test(
                                            "imoRequired",
                                            "IMO is mandatory",
                                            (value: any) => value && (typeof value === "string" || value.title))
                                    .test(
                                            "imoNotExist",
                                            "Already an IMO of this Agent",
                                            (value: any) => value && (value.title === initialImo.imo.title || !existImoInAgentImos(value)),
                                    ),
                            ...(showStateAndRegionFields && !showForAdmin ? {
                                state: Yup.string().required().label("State"),
                                region: Yup.string().required().label("Region"),
                            } : {}),
                            ...(showForAdmin? {}:
                                {
                                    phone: Yup.string().required().label("Phone"),
                                    contactFirstName: Yup.string().required().label("First Name"),
                                    contactLastName: Yup.string().required().label("Last Name"),
                                    imoEmail: Yup.string().email().required().label("IMO Email"),        
                                }
                            ),
                            carriers: Yup.array(Yup.string().required()).required().min(1).label("Carriers"),
                        })}
                >
                    {({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <DialogContent dividers>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body1">
                                                Add an IMO and carrier below.
                                                {!showForAdmin && " New IMO's will be verified by NIW."}
                                            </Typography>
                                        </Grid>
                                        <FormInputs
                                                fields={imoFields}
                                                {...{ errors, values, touched, handleChange, handleBlur }}
                                        />
                                        <FormInputs
                                                fields={agentNumberFields}
                                                {...{ errors, values, touched, handleChange, handleBlur }}
                                        />
                                    </Grid>
                                    <Button
                                            {...buttonCommonProps}
                                            className="floatLeft mt20"
                                            label="Cancel"
                                            onClick={onCancel}
                                            type="button"
                                    />
                                    <Button
                                            {...buttonCommonProps}
                                            className="floatRight mt20"
                                            label={type.buttonLabel}
                                            type="submit"
                                            color="primary"
                                    />
                                </DialogContent>
                            </form>
                    )}
                </Formik>
            </Dialog>
    );
};