import React, { useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

export default function AgentCaseAlertMessage() {
    const detail: any = useSelector((state: RootState) => state.account);
    const description: any = detail && detail.agent && detail.agent.agentCaseAlerts && detail.agent.agentCaseAlerts.length > 0 ? detail.agent.agentCaseAlerts[0].description : null;

    return (
            description ?
                    <Grid item xs={12}>
                        <div className="messageBoxWarning">
                            <WarningIcon className="mr10" /> <Typography variant="caption"><strong>Case
                            Alert:</strong> {description}
                        </Typography>
                        </div>
                    </Grid> : null);
}