import {makeStyles} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";

export const ClientServicingCss = makeStyles((theme: Theme) =>
    createStyles({
        mt15: {
            marginTop: 15,
        },
        mt40: {
            marginTop: 40,
        },
        mt10: {
            marginTop: 10,
        },
        minHeight170: {
            minHeight: 170,
        },
        containerWidth1700: {
            maxWidth: 1700,
        },
        colorWhite: {
            color: '#ffffff'
        },
        cardRootHighlight: {
            background: "linear-gradient(330.33deg, #3DDB93 0%, #1E88E5 100%)",
            boxShadow: "0 0 16px 0 rgba(0,0,0,0.18)",
            color: "#FFFFFF",
            display: "flow-root !important",
        },
        content: {
            display: "flex",
            width: "100%",
            alignItems: 'center'
        },
        imgSec: {
            paddingRight: 15,
        },
        textSec: {
            padding: "0",
        },
        title: {
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                height: 4,
                width: 73,
                top: -10,
                borderRadius: 4,
                background: 'linear-gradient(90deg, #1E88E5 0%, #3DDB93 32.38%, #F50057 69.36%, #FFB05F 100%)',
            }
        },
        agentHero: {
            background: "url(\"../images/landing_page_v2/Shape.png\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            minHeight: 300,
            backgroundPosition: "bottom right",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px 15px 15px 40px",
            borderRadius: 8,
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down(992)]: {
                padding: "15px",
            }
        },
        marketingHero: {
            background: "url(\"../images/agent/bg-header-cta-with-rings.jpg\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            minHeight: 300,
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px 15px 15px 40px",
            borderRadius: 8,
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down(992)]: {
                padding: "15px",
            }
        },
        minidpNone: {
            [theme.breakpoints.down(1400)]: {
                width: '100%'
            },
            [theme.breakpoints.down(1180)]: {
                display: 'none'
            },
        },
        textLight: {
            color: '#9E9E9E',
        },

        cardRoot: {
            display: 'flex',
        },
        radius8: {
            borderRadius: 8
        },
        minHeight150: {
            [theme.breakpoints.down(1440)]: {
                minHeight: 150
            }
        },
        cardShadow: {
            boxShadow: '0 0 16px 0 rgba(0,0,0,0.18)',
        },
        cardShadowSec: {
            boxShadow: '0 0 8px 0 rgba(0,0,0,0.21)',
        },
        minHeight: {
            minHeight: 240,
        },
        minHeight140: {
            minHeight: 140,
        },
        minHeight148: {
            minHeight: 148,
        },
        textLinkNone: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        cover: {
            width: 151,
        },
        w100: {
            width: '100%'
        },
        annualContributionsBreakdown: {
            margin: "20px 0",
        },
        textCenter: {
            textAlign: 'center'
        },
        floatLeft: {
            float: 'left'
        },
        mt25: {
            marginTop: 25
        },
        mb0: {
            marginBottom: 0
        },
        mb15: {
            marginBottom: 15
        },
        disPlay: {
            display: 'block'
        },
        borderLeft1: {
            borderLeft: "1px solid #cfcfcf",
            padding: "0 0 0 20px !important",
            [theme.breakpoints.down(1200)]: {
                borderLeft: "1px solid transparent",
            }
        },
        btnSection: {
            padding: '60px 15px',
            [theme.breakpoints.down(767)]: {
                padding: 15,
                float: 'right',
                textAlign: 'right',
                width: 200
            }
        },
        btnBlue: {
            background: '#3671ED',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            fontSize: 20,
            '&:hover': {
                background: '#3671ED',
            },
            '&:focus': {
                background: '#3671ED',
            }
        },
        btnRed: {
            background: '#FF1744',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            fontSize: 20,
            '&:hover': {
                background: '#FF1744',
            },
            '&:focus': {
                background: '#FF1744',
            }
        },
        btnGreen: {
            background: '#3DDB93',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            fontSize: 20,
            '&:hover': {
                background: '#3DDB93',
            },
            '&:focus': {
                background: '#3DDB93',
            }
        },
        btnGrey: {
            background: '#677184',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            fontSize: 20,
            '&:hover': {
                background: '#677184',
            },
            '&:focus': {
                background: '#677184',
            }
        },
        flexBox: {},
        boxItem: {
            maxWidth: 320,
            marginLeft: 0,
            marginRight: 10,
            [theme.breakpoints.down(767)]: {
                maxWidth: '100%',
                margin: 6,
                width: '100%'
            }

        },
        cardGreenGradient: {
            borderRadius: 8,
            background: "url(\"../images/clientServicing/images/headingtext.png\"),linear-gradient(52.13deg, #3DDB93 0%, #0071D5 100%) no-repeat",
            boxShadow: "0 8px 30px 0 rgba(0,0,0,0.16)",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom right',
            minHeight: 560,
        },
        cardBlueGradient: {
            borderRadius: 8,
            background: "url(\"../images/clientServicing/images/Group11.png\"),linear-gradient(131.65deg, #12253D 0%, #2196F3 100%)",
            boxShadow: "0 8px 30px 0 rgba(0,0,0,0.16)",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom right',
            minHeight: 604,
            [theme.breakpoints.down(992)]: {
                minHeight: 640,
            },
            [theme.breakpoints.down(769)]: {
                minHeight: 840,
            },
        },
        cardBlueGradientBig: {
            borderRadius: 8,
            background: "url(\"../images/clientServicing/images/w7.png\"),linear-gradient(131.65deg, #12253D 0%, #2196F3 100%)",
            boxShadow: "0 8px 30px 0 rgba(0,0,0,0.16)",
            minHeight: 500,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom right',
        },
        cardMini: {
            display: 'flex',
            boxShadow: "0 8px 30px 0 rgba(0,0,0,0.16)",
            background: '#ffffff',
            borderRadius: 8,
            minWidth: 300,
            padding: 15,
            marginTop: 10,
        },
        ulList: {
            padding: 0,
        },
        liList: {
            padding: 0,
            fontWeight: 'bold',
            alignItems: 'flex-start'
        },
        borderT1: {
            borderTop: "1px solid #cfcfcf"
        },
        w70: {
            width: '70%',
            [theme.breakpoints.down(867)]: {
                width: '65%',
            },
        },
        servicingCard: {
            maxWidth: 250,
            position: 'fixed',
            right: 20,
            bottom: 20,
        },
        servicingCardContent:{
          position:'relative'
        },
        cancelIcon:{
            position:'absolute',
            right:0,
            top:0,
        }
    })
);