import * as React from "react";
import { Checkbox, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { RootState } from "../../reducers";
import {connect, useSelector} from "react-redux";
import IndividualTableRow from "../tableRow/IndividualTableRow";
import {DeleteModelWithNotes, PageTitle} from "../formInputs";
import * as AccountActions from "../../actions/account";
import { useActions } from "../../actions";
import { PARENT_POSTAL, ROLES } from "../../constants";
const headRowsAccountList: AccountHeadRow[] = [
    { id: "sNo", label: "S.No" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "trancheNo", label: "Tranche #" },
    { id: "email", label: "Email" },
    { id: "client.agent.user.name", label: "Agent" },
    { id: "client.imo.groupName", label: "IMO" },
    { id: "client.imo.region", label: "Region" },
    { id: "client.strategy.name", label: "ilia Product Type" },
    { id: "carrier.carrierName", label: "Carrier" },
    { id: "inviteStatus", label: "Status" },
    { id: "createdAt", label: "Date Created" },
    { id: "lastLogin", label: "Last Login" },
];

const headRowsClientManagement = (isCheckboxVisible: boolean, callingFrom?: string):  AccountHeadRow[] => {
    const a:  AccountHeadRow[] = isCheckboxVisible?
        []: [{ id: "sNo", label: "S.No" },]
    const b:  AccountHeadRow[] = isCheckboxVisible || callingFrom == PARENT_POSTAL ?
        [{ id: "participantType", label: "Participant Type"}]: []

    return [
        ...a,
        { id: "firstName", label: "First Name" },
        { id: "lastName", label: "Last Name" },
        { id: "email", label: "Email" },
        ...b,
        { id: "strategyName", label: "ilia Product Type" },
        { id: "carrier.carrierName", label: "Carrier" },
        { id: "inviteStatus", label: "Status" },
    ];
}

const headRowsAgentIndividualList: AccountHeadRow[] = [
    { id: "sNo", label: "S.No" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "client.agent.user.name", label: "Agent" },
    { id: "client.strategy.name", label: "ilia Product Type" },
    { id: "inviteStatus", label: "Status" },
    { id: "createdAt", label: "Date Created" },
    { id: "lastLogin", label: "Last Login" },
];

function IndividualTable(props: any) {
    const { accountList, order, orderBy, handleRequestSort, callingFrom, handleClickOpen, handleClickReInvite, basePath, handleRefresh, listType, page, rowsPerPage, setSelected, customPageTitle } = props;
    const user: any = useSelector((state: RootState) => state.auth.user);

    const isCheckboxVisible = !!setSelected
    const selected: any[] = props.selected || []

    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    const [isDeleteModel, setIsDeleteModel] = React.useState<string|number>("");
    const [message, setMessage] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const accountActions = useActions(AccountActions);
    const pathName = props.history.location.pathname;
    const [notesValue, setNotesValue] = React.useState("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [userType, setUserType] = React.useState("USER");
    let sNo = page * rowsPerPage;
    const deletedUser = (id: string|number, type:string) => {
        let invitationStatus: number = 0;
        let userDetails:any = [];
        setUserType(type);
        userDetails = accountList.rows.filter((val: any) => (id === val.userId));
        if(userDetails.length == 0){
            userDetails = accountList.rows.filter((val: any) => (id === val.id));
        }
        if(userDetails.length > 0){
            invitationStatus = userDetails[0]["user.inviteStatus"];
            if(invitationStatus >= 4){
                setMessage("This user has started or completed their enrollment do you still want to delete their account? If yes it will be permanently deleted from ilia ?")
            } else {
                setMessage("Are you sure you want to delete this user permanently from ilia ?")
            }
            setTitle(userDetails[0]["firstName"] + " " +userDetails[0]["lastName"])
        }
        setIsDeleteModel(id)
    };

    const deleteParticipants = async () => {
        setButtonLoader(true);
        await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        refreshTable();
    };
    const setNotes = (value) => {
        setNotesValue(value)
    }

    const refreshTable = () =>{
        handleRefresh();
    }

    let rowCount = (accountList && accountList.rows)? accountList.rows.length: 0;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(accountList.rows);
            return;
        }
        setSelected([]);
    };

    const handleSelectOneClick = (account) => {
        if(isSelected(account)) {
            setSelected(selected.filter(x => x !== account))
        } else {
            setSelected([...selected, account])
        }
    }

    const isSelected = row => selected.indexOf(row) !== -1;

    return (
            <React.Fragment>
                {
                    customPageTitle &&
                    <PageTitle title={`${customPageTitle}`}/>
                }
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={
                            callingFrom === "accountList"
                            ? pathName !== "/imo/individual"
                                ? headRowsAccountList
                                : headRowsAgentIndividualList
                            : headRowsClientManagement(isCheckboxVisible, callingFrom)
                        } //no need to show groupName, region for imo loggedin in case of individual list i.e pathName check and header row is headRowsAgentIndividualList
                        includeAction={(user && user.role === 7) ? false : true}
                >
                    {
                        isCheckboxVisible &&
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={selected.length > 0 && selected.length < rowCount}
                                checked={rowCount > 0 && selected.length === rowCount}
                                onChange={handleSelectAllClick}
                                inputProps={{
                                'aria-label': 'select all',
                                }}
                            />
                        </TableCell>
                    }
                </EnhancedTableHead>
                <TableBody>
                    {
                        (!accountList.rows || accountList.rows.length === 0)
                        && <TableRow>
                            <TableCell align="left" colSpan={(callingFrom === "accountList") ? 9 : 7}>
                                <Typography
                                        align="left">No {listType === "main" ? "Individual Accounts" : "Participants"}</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {accountList.rows && accountList.rows.map((account: any, index: number) => {
                        const isItemSelected = isSelected(account);

                        return (
                                <IndividualTableRow key={index} account={account} setInviteTokenId={setInviteTokenId}
                                                    inviteTokenId={inviteTokenId} callingFrom={callingFrom}
                                                    pathName={pathName}
                                                    handleClickOpen={handleClickOpen} basePath={basePath}
                                                    history={props.history} handleClickReInvite={handleClickReInvite}
                                                    handleRefresh={handleRefresh} listType={listType}
                                                    deletedUser={deletedUser}
                                                    render={(isCheckboxVisible || callingFrom == PARENT_POSTAL) && <TableCell padding="default">{ROLES[account.role]}</TableCell>}
                                                    >
                                    {
                                        isCheckboxVisible?
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onChange={() => handleSelectOneClick(account)}
                                                inputProps={{
                                                'aria-labelledby': "individual",
                                                }}
                                            />
                                        </TableCell>
                                        :
                                        <TableCell>{++sNo}</TableCell>
                                    }
                                </IndividualTableRow> 
                        );
                    })
                    }
                </TableBody>
                {
                    isDeleteModel ?
                    (
                        <DeleteModelWithNotes
                            open={true}
                            title={title}
                            content={message}
                            handleClose={() => {setIsDeleteModel(""); setNotesValue("")}}
                            handleYes={deleteParticipants}
                            setNotes={setNotes}
                            notes={notesValue}
                            buttonLoader={buttonLoader}
                        />
                    ):null
                }
            </React.Fragment>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(IndividualTable);
