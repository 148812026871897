import {ActionType} from "../model";

export const createEnrollment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.post('enrollment', payload, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Enrollment Initiated.",
				statusCode: 200
			}
		});
		return dispatch({
			type: ActionType.CREATE_ENROLLMENT,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getEnvelope = (enrollmentId?: string, pageUrl?: string, recipientId?: number ) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get('enrollment/build/envelope', {}, {enrollmentId, pageUrl, recipientId});
		return dispatch({
			type: ActionType.BUILD_ENVELOPE,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};


export const downloadEnvelope = (enrollmentId?: string, recipientId?: number ) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const result = await api.get('enrollment/download/envelope', {}, {enrollmentId, recipientId});
        return dispatch({
            type: ActionType.DOWNLOAD_ENVELOPE,
            payload: result
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getEnrollment = () => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get('enrollment');
		return dispatch({
			type: ActionType.GET_ENROLLMENT,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const updateEnrollment = (payload: any, showMessage:boolean = true) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
	    let message = "";
        if(payload.questions) {
            message = "Account Updated Successfully."
            delete payload.questions;
        }
		const result = await api.put('enrollment', payload);
        if(showMessage) {
            dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
            dispatch({
                type: ActionType.UPDATE_ENROLLMENT,
                payload: result
            });
        }
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const updateEnrollmentActionItem = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
		const result = await api.put('enrollment/agent/action-item', payload);
		dispatch({type: ActionType.ALERT, payload: {message: "Approved Successfully.", statusCode: 200}})
		return dispatch({
			type: ActionType.UPDATE_ENROLLMENT_ACTION_ITEM,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const updateEnrollmentProposalActionItem = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.put('enrollment/agent/proposal-action-item', payload);
        dispatch({type: ActionType.ALERT, payload: {message: "Approved Successfully.", statusCode: 200}})
		return dispatch({
			type: ActionType.UPDATE_ENROLLMENT_ACTION_ITEM,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const updateEnrollmentDraft = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const fullState = getState();
		console.log("SAA", fullState);
		const formValues = fullState.form.EnrollmentForm.values;
		const result = await api.put('enrollment', {...formValues, ...payload});
		return dispatch({
			type: ActionType.UPDATE_ENROLLMENT,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getIdentityVerification = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get('enrollment/identity/verification');
		return dispatch({
			type: ActionType.IDENTITY_VERIFICATION,
			payload: result
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const resetEnrollment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('enrollment/reset', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Enrollment Reset.",
                statusCode: 200
            }
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const resetEnrollmentForoldUser = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('enrollment/reset/oldUser', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Enrollment Reset.",
                statusCode: 200
            }
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const enrollmentRequest = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const result = await api.post('enrollment/enrollmentRequest', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Enrollment Requested.",
                statusCode: 200
            }
        });
        return result;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const updateEnrollmentStatus = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const result = await api.put('enrollment/status', payload);
        if(payload.enrollmentStatus == 0 && payload.enrollmentSubStatus == 0) { // case when enrollment initiated by client after niw_admin, agent answered their questionnaire
            dispatch({
                type: ActionType.ALERT,
                payload: {
                    message: "Enrollment Initiated.",
                    statusCode: 200
                }
            });
        }
        return dispatch({
            type: ActionType.UPDATE_ENROLLMENT,
            payload: result
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const createEnrollmentNotes = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('enrollment/enrollment-notes', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Enrollment Notes Saved.",
                statusCode: 200
            }
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const listCreatedAccountNotExistInEnrollmentUsers = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const result = await api.get('enrollment/list-not-created-enrollment-users');
        return  result;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const listNotSignedEnrollmentUsers = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const result = await api.get('enrollment/list-not-signed-enrollment-users');
        return  result;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const sendMailToNotSignedEnrollmentUser = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('enrollment/send-email-not-signed-enrollment-users', payload, {});
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const updateloggedInUserId = (payload) => async(dispatch: Function, getState: Function, api: any) => {
    await api.put('enrollment/update/completed-by-action-item', payload)
}

export const requestClientDocuments = (payload) => async (dispatch: Function, getState: Function, api: any) => {
    if (!payload.userId || !payload.clientId) {
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: 'Something Went Wrong',
                statusCode: 422
            }
        });
    }
    try {
        await api.post('enrollment/request-client-documents', payload);
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Document Request Sent.",
                statusCode: 200
            }
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const updateEnrollmentProcessingNotes = (payload: any, id, msg: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('enrollment/processing-notes', payload, {id});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: `Enrollment ${msg} Notes Updated.`,
                statusCode: 200
            }
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};
/*  Policy status changed By Which Admin, get his/her name by client Id
    Policy Status - Policy issued (Override)
*/
export const policyStatusChangedByUserName = (clientId: number ) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const result = await api.get('enrollment/policy-status-changed-by', {}, {clientId});
        return result;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};