import { CardContent, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/account";
import * as Admin_Actions from "../../../actions/admin";
import CustomPopup from "../../../components/CustomPopup";
import { Dialog, GenericDialogProps as DialogProps } from "../../../components/dialog";
import { AccountType, AgentType, ImoClass } from "../../../constants";
import { Account } from "../../../model/account";
import { CardAdminActions } from "../../client/ClientProfile/component/AdminActions";
import { getDialogContent } from "../../EditAccountPage";
import { ReduxCardAdminActions } from "../../imo/TeamMemberAdd";
import { ConfirmationDialog } from "../../ticket/TicketPage";
import { OnBoardingToServicing, OnBoardingTypeEnum } from "../../../components/Admin";
import { BN, BS } from "../../../model/misc";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import AgentCaseAlert from "./CaseAlert/AgentCaseAlert";
import * as AgentAccount from "../../../actions/approvedAgents";

type AdminActionsProps = {
    account: Account;
    userId: string;
    role: number;
}

export const AdminActions = ({ account, userId, role }: AdminActionsProps) => {
    const [action, setAction] = React.useState('');
    const [openConfirmPopup, setOpenConfirmPopup] = React.useState<boolean>(false);
    const [notes, setNotes] = React.useState("");
    const [openConfirmPreferred, setOpenConfirmPreferred] = React.useState<boolean>(false);
    const [confirmOnboarding, setConfirmOnboarding] = React.useState<BS>(false);
    const [onboardingAppPref, setOnboardingAppPref] = React.useState<BN>(false);
    const accountActions = useActions(Actions);
    const adminActions = useActions(Admin_Actions);
    const agentAccount = useActions(AgentAccount);
    const agentDetail: any = useSelector((state: RootState) => state.account);
    const agentId: number = (agentDetail && agentDetail.agent && agentDetail.agent.id) || null;

    const handleChange = async (event) => {
        const menu = event.target.value;
        setAction(menu);

        // Enable/disable_account
        if(menu === 1) {
            setOpenConfirmPopup(true);
        }

        // Make_Preferred
        if(menu === 2) {
            setOpenConfirmPreferred(true);
        }

        // Onboarding_to_Servicing
        if (menu === 3 || menu === 4) {
            switch (menu) {
                case 3:
                    setOnboardingAppPref(OnBoardingTypeEnum.Approved);
                    break;
                case 4:
                    setOnboardingAppPref(OnBoardingTypeEnum.Preferred);
                    break;
            }
            setConfirmOnboarding(account.id as string);
        }
    };

    const makePreferred = async () => {
        await accountActions.makeAgentPreferred(account.id);
        refresh();
        setOpenConfirmPreferred(false);
    }

    const handleNotes = (val: string) => {
        setNotes(val);
    };

    const handleConfirm = async () => {
        setOpenConfirmPopup(false);
        await accountActions.updateUserBlockStatus(account.id, notes);
        refresh();
        setNotes("");
        setAction("");
    };

    const refresh = () => {
        accountActions.getAccount(userId, role);
    }

    const onBoarding = async () => {
        await adminActions.confirmOnBoarding(account.id as string, AccountType.Agent, onboardingAppPref);
        await refresh();
    };
    const isBlocked = account.inviteStatus === -2;
    const isGhostAccount = account && account.agent && account.agent.agentType === AgentType.Ghost;
    const isAgent:any = account && account.agent && account.agent.id;

    const handleUpdateAlert = async () => {
        accountActions.getAccount(userId, role);
    };

    return (
            <>
        <CardAdminActions>
            <CardContent className="floatLeft w100">
                <FormControl className="mb10" variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Select an Action</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={action}
                        onChange={handleChange}
                        label="Select an Action"
                    >
                        <MenuItem value={1}>
                            {
                                isBlocked?
                                "Enable account":
                                "Disable account"
                            }
                        </MenuItem>
                        {
                            account && account.agent && account.agent.agentType === 0 &&
                            <MenuItem value={2}>
                                Make Preferred
                            </MenuItem>
                        }
                        {
                            isGhostAccount && // agentType 2 = ghost
                            <MenuItem value={3}>
                                Invite to Approved (Onboarding to Servicing)
                            </MenuItem>
                        }
                        {
                            isGhostAccount && // agentType 2 = ghost
                            <MenuItem value={4}>
                                Invite to Preferred (Onboarding to Servicing)
                            </MenuItem>
                        }
                    </Select>
                </FormControl>
                <AgentCaseAlert agentId={agentId} handleUpdateAlert={handleUpdateAlert}/>
            </CardContent>
            <CustomPopup
                open={openConfirmPopup}
                handleConfirm={() => handleConfirm()}
                handleClosePopup={() => setOpenConfirmPopup(false)}
                content={() => getDialogContent(isBlocked)}
                addNotes={true}
                handleNotes={handleNotes}
                notes={notes}
            />
            {
                openConfirmPreferred &&
                <ConfirmationDialog
                    onCancel={() => setOpenConfirmPreferred(false)}
                    onOk={makePreferred}
                    text={`Are you sure want to change make this agent preferred`}
                    title="Make Preferred confirmation"
                />
            }

            <OnBoardingToServicing
                    confirmOnboarding={confirmOnboarding}
                    onboardingAppPref={onboardingAppPref}
                    onConfirm={onBoarding}
                    role={AccountType.Agent}
                    setConfirmOnboarding={setConfirmOnboarding}
            />
        </CardAdminActions>
                    </>
    );
};

export const ImoAdminActions = ({ account, userId, role, onAddTeamMember }) => {
    const [action, setAction] = React.useState('');
    const [openConfirmPopup, setOpenConfirmPopup] = React.useState<boolean>(false);
    const [notes, setNotes] = React.useState("");
    const accountActions = useActions(Actions);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [newImoType, setNewImoType] = React.useState<null | ImoClass>(null);
    const adminActions = useActions(Admin_Actions);
    const [confirmOnboarding, setConfirmOnboarding] = React.useState<BS>(false);
    const [onboardingAppPref, setOnboardingAppPref] = React.useState<BN>(false);


    const handleChange = async (event) => {
        const menu = event.target.value;
        setAction(menu);

        // Enable/disable_account
        if(menu === 1) {
            setOpenConfirmPopup(true);
        }

        // Add_IMO_Team_Member
        if(menu === 2) {
            setIsDialogOpen(true);
        }

        // Change_to_Approved
        if(menu === 3) {
            setNewImoType(ImoClass.APPROVED);
        }

        // Inv_to_Preferred
        if(menu === 4) {
            setNewImoType(ImoClass.PREFERRED);
        }

        // Onboarding_to_Servicing
        if (menu === 5 || menu === 6) {
            switch (menu) {
                case 5:
                    setOnboardingAppPref(OnBoardingTypeEnum.Approved);
                    break;
                case 6:
                    setOnboardingAppPref(OnBoardingTypeEnum.Preferred);
                    break;
            }
            setConfirmOnboarding(account.id);
        }
    };

    const changeImoType = async () => {
        await accountActions.changeImoClass(account.id, newImoType);
        setNewImoType(null);
        refresh();
    }

    const refresh = () => {
        accountActions.getAccount(userId, role);
    }

    const handleNotes = (val: string) => {
        setNotes(val);
    };

    const handleConfirm = async () => {
        setOpenConfirmPopup(false);
        await accountActions.updateUserBlockStatus(account.id, notes);
        accountActions.getAccount(userId, role);
        setNotes("");
        setAction("");
    };

    const isBlocked = account.inviteStatus === -2;
    const isGhostAccount = account && account.imo && account.imo.imoClass === ImoClass.GHOST;

    const onBoarding = async () => {
        await adminActions.confirmOnBoarding(account.id, AccountType.IMO, onboardingAppPref);
        await refresh();
    };

    const onAddImoTeam = () => {
        setIsDialogOpen(false);
        return onAddTeamMember();
    };
    const addIMOTeamDialogProps: DialogProps = {
        "aria-describedby": "add-new-imo-team-member",
        "aria-labelledby": "add-new-imo-team-member-dialog",
        className: "customModal",
        content: !!userId ? (
                <ReduxCardAdminActions imoId={userId} onSubmitFinish={onAddImoTeam} hasTitle={true} />
        ) : "",
        fullWidth: true,
        maxWidth: "sm",
        onClose: () => setIsDialogOpen(false),
        open: isDialogOpen,
        title: "Add IMO Team Member",
        titleVariant: "subtitle2",
    };

    return (
        <CardAdminActions>
            <CardContent className="floatLeft w100">
                <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Select an Action</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={action}
                        onChange={handleChange}
                        label="Select an Action"
                    >
                        <MenuItem value={1}>
                            {
                                isBlocked?
                                "Enable account":
                                "Disable account"
                            }
                        </MenuItem>
                        <MenuItem value={2}>
                            Add IMO Team Member
                        </MenuItem>
                        {
                            account && account.imo && account.imo.imoClass !== "m-approved" &&
                            account.imo.imoClass !== ImoClass.GHOST &&
                            <MenuItem value={3}>
                                Change to Approved
                            </MenuItem>
                        }
                        {
                            account && account.imo && account.imo.imoClass !== "m-preferred" &&
                            account.imo.imoClass !== ImoClass.GHOST &&
                            <MenuItem value={4}>
                                Invite to Preferred
                            </MenuItem>
                        }
                        {
                            // imoClass m ghost = ghost
                            isGhostAccount &&
                            <MenuItem value={5}>
                                Invite to Approved (Onboarding to Servicing)
                            </MenuItem>
                        }
                        {
                            // imoClass m ghost = ghost
                            isGhostAccount &&
                            <MenuItem value={6}>
                                Invite to Preferred (Onboarding to Servicing)
                            </MenuItem>
                        }
                    </Select>
                </FormControl>
            </CardContent>
            <CustomPopup
                open={openConfirmPopup}
                handleConfirm={() => handleConfirm()}
                handleClosePopup={() => setOpenConfirmPopup(false)}
                content={() => getDialogContent(isBlocked)}
                addNotes={true}
                handleNotes={handleNotes}
                notes={notes}
            />
            {
                newImoType &&
                <ConfirmationDialog
                    onCancel={() => setNewImoType(null)}
                    onOk={changeImoType}
                    text={`Are you sure want to change this IMO type to ${newImoType === ImoClass.APPROVED? "an approved": "a preferred"} IMO?`}
                    title="Change IMO type confirmation"
                />
            }
            {
                    isDialogOpen &&
                    <Dialog {...addIMOTeamDialogProps} />
            }

            <OnBoardingToServicing
                    confirmOnboarding={confirmOnboarding}
                    onboardingAppPref={onboardingAppPref}
                    onConfirm={onBoarding}
                    role={AccountType.IMO}
                    setConfirmOnboarding={setConfirmOnboarding}
             />
        </CardAdminActions>
    );
};
