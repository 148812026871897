import React from "react";
import Button from "@material-ui/core/Button";
import {Grid } from "@material-ui/core";
import {
    DatePicker,
    ErrorCommon,
    NumberTextField,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../components/formikFormInputs"
import { Label } from "../../../components/formInputs"
import { Formik } from "formik";
import { useActions } from "../../../actions";
import * as PaymentActions from "../../../actions/payment";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers"
import * as Yup from "yup";
import moment from "moment/moment";
import numeral from "numeral";
import {usePolicyContext} from "./PolicyContext";
import PaymentFileUploader from "./PaymentFileUploader";
import PaymentDocument from "./PaymentDocument";

const uploadingFileType: string = "image/jpg, image/jpeg, image/png, application/pdf";

export const paymentMethodsAdd: object[] = [
    {
        id: "Check",
        value: "Check",
        name: "Personal Check",
    }, {
        id: "Wire",
        value: "Wire",
        name: "Wire Transfer",
    }
];

const ManualPaymentSchema = Yup.object().shape({
    paymentType: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    dateProcessed: Yup.string().required("Required").nullable(),
    notes: Yup.string().required("Required"),
    files: Yup.array()
});
export default function ManualPaymentForm(props: any) {
    const {policy, updatePolicy} = usePolicyContext();
    const {createManualPayment} = useActions(PaymentActions);
    const {
        handleClose,
    } = props;

    const formVal = {
        paymentType: "",
        amount: "",
        dateProcessed: "",
        notes: "",
        paymentDocumentPath: ""
    };
    const { user } = useSelector((state: RootState) => state.auth);
    const clientDetail: any = useSelector((state: RootState) => state.clientAccount);
    const clientId: number = clientDetail && clientDetail.client && clientDetail.client.id;
    const [initialValues, setInitialValues] = React.useState(formVal);
    const [dateInvalid, setDateInvalid] = React.useState<boolean>(false);

    const [fileSecurePath, setFileSecurePath] = React.useState<string>("");
    const [paymentType, setPaymentType] = React.useState<string>("");
    const [paymentMethod, setPaymentMethod] = React.useState<any>(paymentMethodsAdd);
    const [fileType, setFileType] = React.useState<string | null>(null);
    const role = clientDetail && clientDetail.role;

    const submitFormik = async (values: any, actions: any) => {
        let policyNumber = policy.policyNumber;
        const processedBy: string = user.firstName + " " + user.lastName;
        const paymentPayload = {
            policyNumber: policyNumber,
            clientPayment: {
                date: moment(values.dateProcessed).format("YYYY-MM-DD"),
                type: values.paymentType,
                details: values.notes,
                processedBy: processedBy,
                amount: [{
                    amount: numeral(values.amount).value(),
                    type: "Premium"
                }],
                files: [values.paymentDocumentPath]
            }
        };
        const policyDetails = await createManualPayment(paymentPayload);
        actions.setSubmitting(false);
        updatePolicy(policyDetails);
        actions.resetForm();
        handleClose();
    };


    const fieldObj = [
        {
            name: "paymentType",
            label: <Label label={"Payment Method"} required={true} />,
            isRequired: true,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: paymentMethod,
            onSelectChange: (val: any) => {
                setPaymentType(val);
            },
            size: {
                xs: 12,
            },
        },
        {
            id: "amount",
            name: "amount",
            label: <Label label={"Amount"} required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
            },
        },
        {
            name: "dateProcessed",
            label: <Label label={"Date Processed"} required={true} />,
            isRequired: true,
            placeholder: "Date Processed",
            id: "dateProcessed",
            component: DatePicker,
            className: "fullWidthcustomFields",
            disableFuture: true,
            errCb: (err: any) => {
                if (err) {
                    setDateInvalid(true);
                } else {
                    setDateInvalid(false);
                }
            },
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
            },
        },
        {
            id: "notes",
            name: "notes",
            label: <Label label={"Notes"} required={true} />,
            isRequired: false,
            placeholder: "Add Notes ...",
            component: TextField,
            className: "fullWidthcustomFields",
            multiline: true,
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 6,
            },
        },
    ];

    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={ManualPaymentSchema}
                validateOnChange={true}
                onSubmit={submitFormik}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting
                  },
                ) => (<form onSubmit={handleSubmit}>
                    <Grid container spacing={3} className="mt30 mb30">

                        {fieldObj.map((field, index) => {
                            return (
                                <Grid item xs={field.size.xs === 6 ? 6 : 12}
                                      md={field.size.md === 6 ? 6 : 12}
                                      key={index} className="mb5">
                                    <TextFieldCommon
                                        {...field}
                                        values={values}
                                        onChange={handleChange}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                            );
                        })
                        }
                        <Grid item xs={12} md={12}>
                            <PaymentDocument fieldName={"paymentDocumentPath"} />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="ml20 floatRight"
                        size="small"
                        disabled={isSubmitting}
                    >
                        Save
                    </Button>
                </form>)}
            </Formik>

        </div>
    );
}
